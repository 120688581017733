// The main component with commum configuration
// for storybook and the App itself

// Fake-api worker

// pinia e router
import { createPinia } from 'pinia'

// tippy
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

// QUASAR UI
import { Dialog, Notify, Quasar } from 'quasar'
import quasarLang from 'quasar/lang/pt-BR'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'

// styles
import '@/assets/base.css'
import '@/assets/app.scss'
// NOTIFICATION
// import Notifications from '@kyvg/vue3-notification'

// CONFIRM
import VueConfirmPlugin from 'v3confirm'

// worker
import { worker } from '@/api-mocks/browser'
worker.start({
  onUnhandledRequest: 'bypass',
})

// ALL ELEMENTS
// let allElements = import.meta.glob(['@/elements/*.vue','@/components/forms/*.vue'],{eager:true}])
// let allComponents = import.meta.globEager('@/components/forms/*.vue')

export default function (app) {
  app.use(createPinia())
  // app.use(router)

  app.use(VueTippy,
    {
      directive: 'tooltip', // => v-tooltip
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
      },
    },
  )

  app.use(Quasar, {
    plugins: {
      Dialog,
      Notify,
    }, // import Quasar plugins and add here
    lang: quasarLang,
    config: {
      notify: {
        position: 'top', // top=left
      },
    },
  })

  // app.use(Notifications)

  app.use(VueConfirmPlugin, {
    root: '#confirm',
    yesText: 'Sim',
    noText: 'Não',
  })

  // Object.entries({...allElements,...allComponents}).forEach(([path, definition]) => {
  //   // Get name of component, based on filename
  //   // "./components/Fruits.vue" will become "Fruits"
  //   const componentName = path.split('/').pop().replace(/\.\w+$/, '')
  //   // Register component on this Vue instance
  //   app.component(componentName, definition.default)
  // })

  return app
}

