import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: '{idclientevallen}/produtos',
  displayName: {
    singular: 'Produto',
    plural: 'Produtos',
  },
  idKey: 'id',
  icon: 'science',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'ds_produto',
      label: 'Produto',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_produto',
      label: 'Sigla',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_tp_embalagem',
      label: 'Embalagem',
      type: 'model',
      modelName: 'embalagem',
      required: true,
    },

    {
      key: 'qt_volume',
      label: 'Volume',
      type: 'number',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_unidade_medida',
      label: 'uni. medida',
      type: 'model',
      modelName: 'unidadesmedida',
      required: true,
    },
    {
      key: 'qt_minima_estoque',
      label: 'Estoque mínimo',
      type: 'number',
    },

  ],
} as Model
