const PessoaJuruidica: ModelT = {
  fake: false,
  endpoint: '/sync/{idclientevallen}/produtomovimentacao',
  displayName: {
    singular: 'Item Movimentação',
    plural: 'Itens da Movimentação',
  },
  idKey: 'id',
  icon: 'business',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: false,
    },
    {
      key: 'co_produto',
      label: 'Produto',
      type: 'model',
      modelName: 'produtos',
      required: true,
    },
    {
      key: 'co_fabricante',
      label: 'Fabricante',
      type: 'model',
      modelName: 'fabricantes',
      required: true,
    },
    {
      key: 'qt_produto',
      label: 'Quantidade',
      type: 'number',
      required: true,
    },
    {
      key: 'vl_unitario',
      label: 'Valor Unitário',
      type: 'number',
      required: true,
    },
    {
      key: 'dt_validade',
      label: 'Data de Validade',
      type: 'date',
      required: true,
    },
    {
      key: 'nu_lote',
      label: 'Lote',
      type: 'text',
      required: true,
    },

  ],

  //   table: {
  //     cols: [{ key: 'st_status', label: 'Status', renderFn: item => item.st_status ? 'Ativo' : 'Inativo' }, 'tp_estabelecimento', 'no_unidade', 'co_cnpj', 'no_contato', 'ds_email', 'nu_telefone'],
  //   },
  //   list: {
  //     cols: ['no_unidade', 'co_cnpj', 'ds_razao_social', 'sg_unidade'],
  //   },

}

export default PessoaJuruidica

