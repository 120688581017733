import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: '{idclientevallen}/fabricantes',
  displayName: {
    singular: 'Fabricante',
    plural: 'Fabricantes',
  },
  idKey: 'id',
  icon: 'fork_right',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'no_fabricante',
      label: 'Nome',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'no_fantasia',
      label: 'Nome Fantasia',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_cnpj',
      label: 'CNPJ',
      type: 'text',
      mask: '00.000.000/0000-00',
      required: true,
      displayLabel: true,
      search: true,
    },

  ],
} as Model
