import { h, mergeProps, nextTick, onMounted, ref, toRef, watch } from 'vue'

import { useIMask } from 'vue-imask'
import fieldTemplate from './field-template.vue'

export const propsDef = {
  label: {
    type: String,
  },
  name: {
    type: String,
    required: true,
  },
  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  modelValue: {
    type: [String, Number, Date],
  },
  value: String,
  unmasked: String,
  typed: String,
  /** https://imask.js.org/ */
  mask: [String, Object],
  type: {
    default: 'text',
  },
  valueUnmasked: {
    type: Boolean,
    default: true,
  },
  valueNullIfEmpty: {
    type: Boolean,
    default: true,
  },
  cellStyle: {
    type: Boolean,
    default: false,
  },
}

export const emitDef = [
  'update:modelValue',
  'update:masked',
  'update:value',
  'update:unmasked',
  'update:typed',
  'accept',
  'accept:value',
  'accept:masked',
  'accept:unmasked',
  'accept:typed',
  'complete',
  'complete:value',
  'complete:masked',
  'complete:unmasked',
  'complete:typed',
  'focus',
  'blur',
]

const VALUE_PROPS = ['typed', 'unmasked', 'value', 'modelValue']

export function useFieldInput({ props, emit, attrs = {}, customProps = {} }) {
  const maskInput = props.mask?.constructor?.name === 'Object' ? props.mask : { mask: props.mask }
  const { el, masked, unmasked, typed } = useIMask(
    { overwrite: true, eager: true, lazy: true, ...maskInput },
    {
      emit,
      onAccept: () => {
        // emit more events
        let v = masked.value
        let unmaskedValue = unmasked.value
        if (props.valueNullIfEmpty) {
          unmaskedValue = unmaskedValue === '' ? null : unmaskedValue
          v = v === '' ? null : v
        }
        emit('accept:value', v)
        emit('update:value', v)
        emit('update:masked', v)
        if (props.valueUnmasked)
          emit('update:modelValue', unmaskedValue)

        else
          emit('update:modelValue', v)

        emit('update:unmasked', unmaskedValue)
        emit('update:typed', typed.value)
      },
      onComplete: () => {
        emit('complete:value', masked.value === '')
      },
    },
  )

  const pvalue = toRef(props, 'value')
  const pmodelValue = toRef(props, 'modelValue')
  const punmasked = toRef(props, 'unmasked')
  const ptyped = toRef(props, 'typed')

  typed.value = pmodelValue.value || pvalue.value || ''
  // unmasked.value = punmasked.value;
  // typed.value = ptyped.value;

  watch(pvalue, v => (masked.value = v))
  watch(punmasked, v => (unmasked.value = v))
  watch(ptyped, v => (typed.value = v))
  // let firstCall = true
  watch(pmodelValue, (v, oldv) => {
    // let vfinal = firstCall ? oldv : v
    if (props.mask)
      typed.value = v

    // firstCall = false
  })

  const onInput = (event) => {
    if (!props.mask) {
      let v = event.target.value
      if (props.valueNullIfEmpty)
        v = v === '' ? null : v

      emit('update:modelValue', v)
      emit('update:value', v)
    }
  }

  let firstRender = true
  const rederInputElement = () => {
    const inputData = {
      type: props.type,
      required: props.required,
      name: props.name,
      role: 'input-elm',
      onFocus: e => emit('focus', e),
      onBlur: e => emit('blur', e),
    }
    if (props.mask) {
      inputData.type = 'text'
      inputData.ref = el
      inputData.class = 'masked'
      inputData.value = firstRender ? props.modelValue : masked.value
      firstRender = false
    }
    else {
      inputData.class = 'not-masked'
      inputData.onInput = onInput
      inputData.value = props.modelValue
    }
    if (props.type === 'number') {
      inputData.min = props?.min
      inputData.max = props?.max
    }
    return h('input', inputData)
  }

  const fieldComponent = h(
    fieldTemplate,
    {
      required: props.required,
      hint: props.hint,
      label: props.label,
      labelInside: props.labelInside,
      error: props.error,
      cellStyle: props.cellStyle,
    },
    { default: rederInputElement },

  )

  return { fieldComponent, el, pmodelValue, masked, unmasked, typed }
}
// Ref template
// <template>
// <field-template :required="required" :error="error" :hint="hint">
//   <template #label>
//     <slot name="label"><span v-if="label" v-html="label"></span></slot>
//   </template>
//   <template #hint>
//     <slot name="hint"><span v-if="hint" v-html="hint"></span></slot>
//   </template>
//   <input
//     v-if="mask"
//     ref="el"
//     :type="type"
//     :required="required"
//     :name="name"
//     class="masked"
//     v-bind="{...$attrs}"
//     />
//   <input
//     v-if="!mask"
//     :type="type"
//     :required="required"
//     class="not-masked"
//     :name="name"
//     :value="props.modelValue"
//     @input="onInput"
//     v-bind="{...$attrs}"
//     />
//   </field-template>
// </template>
