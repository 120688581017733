<script setup>
import { uiStore, useAuthStore } from '@/stores'

const auth = useAuthStore()
const ui = uiStore()

const menuList = [
  {
    icon: 'dashboard',
    label: 'Inicio',
    to: '/',
    separator: true,
    permission: null,
  },
  {
    icon: 'group',
    label: 'Atendimentos',
    to: '/atendimento',
    separator: true,
    permission: 'ATENDIMENTO:LISTAR',
  },
  {
    icon: 'group',
    label: 'Orcamentos',
    to: '/atendimento',
    separator: true,
    permission: 'ATENDIMENTO:LISTAR',
  },
  {
    icon: 'group',
    label: 'Usuários',
    to: '/usuario',
    separator: false,
    permission: 'USUARIO:LISTAR',
  },
  {
    icon: 'business',
    label: 'Empresas',
    to: '/pessoajuridica',
    separator: false,
    permission: 'PESSOAJURIDICA:CRIAR',
  },
  {
    icon: 'engineering',
    label: 'Profissionais',
    to: '/profissional',
    separator: true,
    permission: 'PROFISSIONAL:LISTAR',
  },
  {
    separator: true,
    icon: 'bloodtype',
    label: 'Procedimentos',
    to: '/procedimentos',
    permission: null,
  },
  {
    separator: true,
    icon: 'request_quote',
    label: 'Tabela de Preços',
    to: '/tabelaprecos',
    permission: null,
  },
  {
    separator: false,
    icon: 'science',
    label: 'Produtos',
    to: '/produtos',
    permission: null,
  },
  {
    icon: 'inventory_2',
    label: 'Embalagens',
    to: '/embalagem',
    separator: true,
  },
  {
    icon: 'inventory_2',
    label: 'Fabricantes',
    to: '/fabricantes',
    separator: true,
  },
  {
    icon: 'fork_right',
    label: 'Movimentações',
    to: '/movimentacao',
    separator: false,
  },
  {
    icon: 'fork_right',
    label: 'Tipo-Movimentações',
    to: '/tipo-movimentacao',
    separator: true,
  },
  {
    icon: 'attach_money',
    label: 'Contas',
    to: '/conta',
    separator: false,
  },
  {
    icon: 'add_shopping_cart',
    label: 'Categoria de Contas',
    to: '/categoria-conta',
    separator: false,
  },
  {
    icon: 'credit_card',
    label: 'Formas de pagamento',
    to: '/forma-pagamento',
    separator: false,
  },
  // {
  //   icon: 'feedback',
  //   label: 'Send Feedback',
  //   separator: false,
  // },

  {
    icon: 'help',
    iconColor: 'primary',
    label: 'Help',
    separator: false,
  },
]
</script>

<template>
  <q-drawer
    v-model="ui.drawer"
    :width="170"
    :breakpoint="500"
    bordered
    class="bg-sidebar"
  >
    <q-scroll-area class="fit">
      <q-list>
        <template v-for="(menuItem, index) in menuList" :key="index">
          <q-item
            v-if="!menuItem.permission || auth.hasPermission(menuItem.permission)"
            v-ripple
            clickable
            :active="menuItem.label === 'Outbox'"
            :to="menuItem.to"
          >
            <q-item-section side>
              <q-icon class="text-action-10 brand-icon" :name="menuItem.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-white" caption>
                {{ menuItem.label }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-separator v-if="menuItem.separator" :key="`sep${index}`" />
        </template>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<style lang="scss">
    .bg-sidebar {
      color: white;
      background: linear-gradient(90deg, rgba(28, 166, 162, 1), 95%, rgb(20, 110, 106, 0.4)) padding-box,
                  linear-gradient(80deg, rgba(68, 47, 148, 0.84) 10.84%, rgba(28, 166, 162, 0.84) 100%) #0b5447 border-box;
      border-right: 4px solid transparent;
    }
</style>
