import UF from '../constants/uf'
import type { Model } from '@/models/modelsDef'

const ProfissionalModel: Model = {
  fake: false,
  displayName: {
    singular: 'Profissional',
    plural: 'Profissionais',
  },
  icon: 'engineering',
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
    },
    {
      key: 'no_nome',
      label: 'Nome',
      type: 'text',
      mask: null,
      required: true,
      displayLabel: true,
      search: true,
      sortable: true,
    },
    {
      key: 'sg_sexo',
      label: 'Sexo',
      type: 'radio',
      options: [{ M: 'Masculino' }, { F: 'Feminino' }, { NE: 'Não Especificado' }],
      required: true,
    },
    {
      key: 'sg_conselho',
      label: 'Sigla Conselho',
      type: 'text',
      required: true,
      hint: 'Conselho de classe do profissional requisitante',
      displayLabel: true,
      search: true,
      size: 18,
    },
    {
      key: 'co_conselho',
      label: 'Codigo Conselho',
      type: 'text',
      required: true,
      displayLabel: true,
      search: true,
      size: 24,
    },
    {
      key: 'sg_uf_conselho',
      label: 'UF Conselho',
      type: 'select',
      options: UF,
      required: true,
      group: 'rg',
      size: 4,
    },
  ],
  table: {
    ignore: [],
  },
}

export default ProfissionalModel
