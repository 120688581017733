import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: '{idclientevallen}/embalagem',
  displayName: {
    singular: 'Embalagem',
    plural: 'Embalagens',
  },
  idKey: 'id',
  icon: 'inventory_2',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
      // props: {
      //   trueValue: 'ativo',
      //   falseValue: 'inativo',
      // },
    },
    {
      key: 'ds_embalagem',
      label: 'Embalagem',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_embalagem',
      label: 'Sigla',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
  ],
} as Model
