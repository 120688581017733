<script setup>
import { computed, isProxy, isRef, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { getModelIdType } from '@/models/useModelComposable'

import ModelForm from '@/models/modelForm.vue'

const props = defineProps(['modelName', 'modelId', 'modal'])
const emit = defineEmits(['saved', 'submit', 'closed', 'cancel'])
const modelsFormImported = import.meta.glob('../models/**/*.form.vue', { eager: true })
const ModelsFormTemplates = {}

const _modelId = computed(() => {
  if (getModelIdType(props.modelName) === 'number')
    return Number(props.modelId)
  else
    return props.modelId
})

for (const path in modelsFormImported) {
  const modelName = path.match(/(\w+).form.vue*$/)?.[1]

  if (typeof modelName === 'string')
    ModelsFormTemplates[modelName] = modelsFormImported[path].default
}

function saved(element) {
  emit('saved', element)
}
function closed() {
  emit('closed')
}
</script>

<template>
  <component
    :is="ModelsFormTemplates?.[modelName] ? ModelsFormTemplates[modelName] : ModelForm "
    :model-name="props.modelName"
    :model-id="props.modelId"
    :modal="props.modal"
    @closed="closed"
    @saved="saved"
  />
</template>

<style lang="scss">

</style>
