<script setup>
import { mergeProps, useAttrs } from 'vue'
import { emitDef, propsDef, useFieldInput } from './useFieldInput'
import fieldTemplate from './field-template.vue'
const props = defineProps({
  ...propsDef,
  scale: {
    default: 5,
  },
  radix: {
    default: ',',
  },
  thousandsSeparator: {
    default: ',',
  },
  min: {
    default: undefined,
  },
  max: {
    default: undefined,
  },
})

const emit = defineEmits([...emitDef])

// se o value não é um numero, eu atualizo o prop passado convertendo ele em numero
// isso é uril em formulario que o numero vem como string, assim forco atualizar para numero sempre
if (props.modelValue) {
  emit('update:modelValue', Number(props.modelValue))
  emit('update:value', Number(props.modelValue))
}

const onInput = (event) => {
  if (!props.mask) {
    emit('update:modelValue', Number(event.target.value))
    emit('update:value', Number(event.target.value))
  }
}
</script>

<template>
  <field-template
    :required="required"
    :error="error"
    :label="label"
    :hint="hint"
  >
    <input
      type="number"
      :required="required"
      :name="name"
      :value="props.modelValue && Number(props.modelValue)"
      v-bind="{ ...$attrs }"
      :min="props.min"
      :max="props.max"
      @input="onInput"
    />
  </field-template>
</template>
