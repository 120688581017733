export default {
  fake: false,
  endpoint: 'sync/{idclientevallen}/perfis',
  displayName: {
    singular: 'Perfil de usuários',
    plural: 'Perfis de usuários',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'no_perfil',
      label: 'Nome',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_perfil',
      label: 'Sigla',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
      hint: 'Mnemônico que representa o perfil criado',
    },
    {
      key: 'recursos',
      label: 'Recursos',
      type: 'model',
      multiple: true,
      hidden: true,
    },
  ],
  table: {
    ignore: ['recursos'],
  },
} as ModelT
