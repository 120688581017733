export default {
  fake: false,
  endpoint: 'async/{idclientevallen}/atendimentos',
  //   endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Atendimento',
    plural: 'Atendimentos',
  },
  idKey: 'id',
  editLink: 'atendimentoform',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: true,
    },
    {
      key: 'co_cliente',
      label: 'Usuário',
      type: 'model',
      hidden: false,
      modelName: 'usuario',
      displayLabel: true,
    },
    {
      key: 'co_conveniado',
      label: 'Empresa',
      type: 'model',
      modelName: 'pessoajuridica',
      displayLabel: true,
    },
    {
      key: 'co_tabela',
      label: 'Tabela de Preço',
      type: 'model',
      modelName: 'tabelaprecos',
      displayLabel: true,
    },
    {
      key: 'co_matricula',
      label: 'Matricula',
      type: 'text',
      displayLabel: true,
      search: true,
    },
    {
      key: 'vl_desconto',
      label: 'Desconto',
      type: 'text',
      displayLabel: true,
    },
    {
      key: 'st_atendimento',
      label: 'Atendimento',
      type: 'text',
      displayLabel: true,
    },
    {
      key: 'itensAtendimento',
      label: 'Itens do atendimento',
      type: 'model',

    },

  ],
  table: {
    // defaultFilters: { st_atendimento: 'ORCAMENTO' },
    cols: [
      { key: 'id', label: 'id', renderFn: e => e.id },
      { key: 'dt_atendimento', label: 'Data Atendimento', renderFn: e => new Date(e?.dt_atendimento).toLocaleDateString('pt-BR') },
      { key: 'co_matricula', label: 'Matricula', renderFn: e => e?.co_matricula },
      { key: 'co_cliente', renderFn: e => e?.cliente?.no_nome },
      { key: 'co_cpf', label: 'CPF', renderFn: e => e?.cliente?.co_cpf },
      { key: 'tipo', label: 'Tipo', renderFn: e => e?.st_atendimento },
      { key: 'st_urgente', label: 'Urgente', renderFn: e => e?.st_urgente ? 'Urgente' : '' },
      { key: 'vlTotal', label: 'Valor Total', renderFn: e => e?.vlTotal },

    ],
  },
} satisfies ModelT

