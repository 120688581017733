/* eslint-disable no-console */
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api'

import router from '@/router'

export interface LoginUser {
  status: string
  token: string
  info: Info
}

export interface Info {
  id: number
  nome: string
  login: string
  email: string
  papel: Papel[]
}

export interface Papel {
  id: number
  co_perfil: number
  co_usuario: number
  co_clientevallen: number
  ClienteVallen: ClienteVallen
  perfil: Perfil
}

export interface ClienteVallen {
  id: number
  no_unidade: string
  sg_unidade: string
  ds_razao_social: string
  co_cnpj: string
  co_ie: null
  co_cnes: null
  co_registro_ans: null
  tp_natureza_juridica: string
  tp_estabelecimento: string
}

export interface Perfil {
  id: number
  no_perfil: string
  sg_perfil: string
  co_clientevallen: number
  recursos: RecursoElement[]
}

export interface RecursoElement {
  id: number
  co_perfil: number
  co_recursofuncionalidade: number
  recurso: RecursoRecurso
}

export interface RecursoRecurso {
  id: number
  no_funcionalidade: string
  no_recurso: string
  sg_funcionalidade_recurso: string
  ds_recurso_funcionalidade: string
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null as unknown as LoginUser | null,
    idclientevallen: !isNaN(localStorage.getItem('idclientevallen')) ? Number(localStorage.getItem('idclientevallen')) : null,
    returnUrl: null,
  }),

  actions: {
    async login(username: string, password: string) {
      let response
      try {
        response = await api.post<LoginUser>('/login', { usuario: username, senha: password })
        const token = response.data.token
        const user = response.data.info
        this.user = user
        this.setCurrentClientVallen(this.user?.papel?.[0]?.ClienteVallen)
        localStorage.setItem('userToken', token)
        localStorage.setItem('user', JSON.stringify(user))

        router.push(this.returnUrl || '/')
        return true
      }
      catch (e) {
        console.log(e.response?.data)
        return e.response?.data
      }
    },
    async loginUserPassword(username: string, password: string) {
      let response
      try {
        response = await api.post<LoginUser>('/auth/login-admin', { usuario: username, senha: password })
        const token = response.data.token
        const user = response.data.info
        this.user = user
        this.setCurrentClientVallen(this.user?.papel?.[0]?.ClienteVallen)
        localStorage.setItem('userToken', token)
        localStorage.setItem('user', JSON.stringify(user))

        router.push(this.returnUrl || '/')
        return true
      }
      catch (e) {
        console.log(e.response?.data)
        return e.response?.data
      }
    },

    logout() {
      this.user = null
      localStorage.removeItem('user')
      localStorage.removeItem('userToken')
      router.push('/login')
    },

    async getMe() {
      const id = this.user?.id
      if (!id)
        return false
      try {
        await api.get(`/usuario/${id}`)
        return true
      }
      catch (e) {
        this.logout()
        return false
      }
    },

    async recuperarSenha(username) {
      try {
        await api.post('/usuario/recuperar', { credencial: username })
        return true
      }
      catch (e) {
        console.log(e.response.data)
        return e.response.data
      }
    },

    setCurrentClientVallen(clientVallen: ClienteVallen) {
      this.idclientevallen = clientVallen?.id
      this.idclientevallen = !isNaN(this.idclientevallen) ? Number(this.idclientevallen) : null
      localStorage.setItem('idclientevallen', this.idclientevallen)
    },

  },
  getters: {
    hasClientVallen(state) {
      return !!state?.user?.papel?.[0]?.ClienteVallen
    },
    getVallenClientsForUser(state): Array<ClienteVallen | null> {
      return state?.user?.papel?.map(papel => papel.ClienteVallen)
    },
    getCurrentClientVallen(): ClienteVallen | null {
      const papel = this.getCurrentPapel
      return papel?.ClienteVallen
    },
    getCurrentPapel(state) {
      const papel = state?.user?.papel?.find((papel: Papel) => papel.ClienteVallen.id === this.idclientevallen)
      return papel
    },
    hasPermission() {
      return (sg_funcionalidade_recurso: string) => {
        const papel = this.getCurrentPapel
        if (!papel)
          return false
        const exist = papel?.perfil?.recursos?.find(recurso => recurso.recurso.sg_funcionalidade_recurso === sg_funcionalidade_recurso.toUpperCase())
        return exist
      }
    },
  },
})
