<script>
import InlineSvg from 'vue-inline-svg'

// UTILS
/**
 * @param val
 */
function convertOnlyNumberToPixel(val) {
  if (isNaN(Number(val)) === false)
    val += 'px'

  return val
}

// Your component
export default {
  components: {
    InlineSvg,
  },

  props: {
    /**
    can be a url of a external svg or asset, or only name o svg file in /public/icons
     */
    icon: {
      type: String,
      require: true,
      default: 'none',
    },
    /**
    the width of icon
     *
    @values 1em , 10px, auto
     */
    width: {
      type: [String, Number],
      default: '1em',
    },
    /**
    the height of icon
    ex: 1em , 10px, auto
     */
    height: {
      type: [String, Number],
      default: 'auto',
    },
    /**
    the size of icon, a shortcur for width an height
    1em , 10px, auto
     */
    size: {
      type: [String, Number],
      default: null,
    },
    /**
    set a icon size, how much i want to scale the svg itself, not the icon space
    0 a 5
     */
    scale: {
      type: [Number, String],
      default: 1,
    },
    /**
    color of icon
     */
    color: {
      type: String,
      default: 'currentColor',
    },
    /**
     * define if have a circle under it
     */
    circle: {
      type: Boolean,
      default: false,
    },
    /**
     * define if the circle color
     */
    circleColor: {
      type: String,
      default: 'black',
    },
    /**
    How much area the circle have relative to the icon
     */
    circlePadding: {
      type: String,
      default: '5px',
    },
  },

  computed: {
    cIcon() {
      let iconPath = this?.icon ?? ''
      // only name, will try files in /static/icon
      if (iconPath.match(/^[a-zA-Z_-]*$/))
        iconPath = `/icons/${iconPath}.svg`

      return iconPath
    },

    cWidth() {
      let width = this.width
      if (this.size)
        width = convertOnlyNumberToPixel(this.size)
      return convertOnlyNumberToPixel(width)
    },

    cHeight() {
      const height = this.height
      // if (this.size) height = convertOnlyNumberToPixel(this.size)
      return convertOnlyNumberToPixel(height)
    },

  },

  methods: {
    /**
    Remove definede sizes of svg
     *
     * @param svg
     */
    transformSVG(svg) {
      let svgString = ''
      if (svg?.outerHTML)
        svgString = svg.outerHTML

      const newInnerSvg = this.renameStyle(svgString)
      svg.innerHTML = newInnerSvg
      return svg
    },

    // add pid attr, for css
    addPid(content) {
      const shapeReg = /<(path|rect|circle|polygon|line|polyline|ellipse)\s/gi
      let id = 0
      content = content.replace(shapeReg, (match) => {
        return `${match}pid="${id++}" `
      })

      return content
    },

    // rename fill and stroke. (It can restroe in vue-svgicon)
    renameStyle(content) {
      const styleShaeReg = /<(path|rect|circle|polygon|line|polyline|g|ellipse).+>/gi
      const styleReg = /fill=\"|stroke="/gi
      content = content.replace(styleShaeReg, (shape) => {
        return shape.replace(styleReg, (styleName) => {
          return `_${styleName}`
        })
      })

      return content
    },

  },
}
</script>

<template>
  <div
    class="svg-icon"
    :style="{
      width: circle ? 'auto' : cWidth,
      height: circle ? 'auto' : cWidth,
    }"
  >
    <div
      class="svg-icon__circle"
      :style="{
        boxSizing: 'content-box',
        backgroundColor: circle ? circleColor : 'transparent',
        padding: circle ? circlePadding : 0,
        width: circle ? cWidth : 'initial',
        height: circle ? cWidth : 'initial',
      }"
    >
      <div
        class="svg-icon-scale"
        :style="{ transform: `scale(${Number(scale)})` }"
      >
        <InlineSvg
          :style="{ color }"
          :src="cIcon"
          :transform-source="transformSVG"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">    .svg-icon  {
      display: inline-block;
      position: relative;
      width: 16px;
      height: 16px;

      vertical-align: middle;
      margin-bottom: 0.2em;& svg {
        width: 100% !important;
        height: auto;
        max-height: 100%;


        fill: none;
        stroke: none;
        fill: currentColor;
      }
    }.svg-icon__circle {

      border-radius: 100%;
    }.svg-icon-scale {
      transform-origin: center center;
    }.svg-fill  {
      fill: currentColor;
      stroke: none;
    }.svg-up  {
      transform: rotate(0deg);
    }.svg-right  {
      transform: rotate(90deg);
    }.svg-down  {
      transform: rotate(180deg);
    }.svg-left  {
      transform: rotate(-90deg);
    }
</style>
