/**
 *  Objective: Be the source of truth for items(and reactived), so
 *  change in one place will of view will update it in anothe place
 *   TODO: Maybe (des)normalize items from  multiple datasources?
 *   TODO: Filter items loaded. Or always load it from datasource
 *   TODO: A center for offline data
 */

import { defineStore } from 'pinia'
import filter from 'lodash/filter'
import Fuse from 'fuse.js'
import { DATASOURCES, MODELS, getModelIdKey } from './models'
import { isArray, isNumber, isObject } from '@/utils/getType'

import type { DataSourceReturnT, DataSourceT, findOptionsT } from '@/datasource/DataSourceClass'

export interface ModelStateT {
  items: Map<string | number, any>
  itemsByID: { [itemId: string | number]: object }
  loading: boolean
  error: boolean
  total: number
  page: number
}

export const modelsStore = defineStore({

  id: 'models',

  state: () => {
    const _models: { [index: string]: ModelStateT } = {}
    Object.keys(MODELS).forEach(modelKey =>
      _models[modelKey] = new Map(),
    )
    return {
      models: { ..._models },
      normalized: {},
      loadingStatus: {},
      modelItems: {},
      modelStatus: {},
    }
  },

  getters: {
    getModels: (state) => {
      return state.models
    },
    getItem: state => (modelName: string, itemId: string | number) => {
      const items = state.models?.[modelName]
      const item = items?.get(itemId) || null
      return item
    },
    getItems: state => (modelName: string, filterLodashFormat: object | null = null) => {
      const itemsVals = state.models?.[modelName]?.values()
      if (!itemsVals)
        return []
      const items = Array.from(itemsVals)
      if (!filterLodashFormat)
        return items
      const filteredItem = filter(items, filterLodashFormat)
      return filteredItem
    },
    getItemsById: state => (modelName: string, ids: Array<string | number> | string | number = []) => {
      const itemsVals = state.models?.[modelName]
      if (!itemsVals)
        return []
      const items = Array.from(itemsVals)
      const idsNew = isArray(ids) ? ids : [ids]
      const itemWithId = items.filter((item) => {
        return idsNew.includes(item[0])
      })
      return itemWithId.map(item => item[1])
    },
    // getAllItems: state => (modelName: string, filterWord?: string) => {
    //   const itemsVals = state.models?.[modelName]?.values()
    //   if (!itemsVals)
    //     return []
    //   const items = Array.from(itemsVals)
    //   if (filterWord?.trim()) {
    //     const finalWord = filterWord.trim()
    //     // TODO - Criar objeto fuse fora para performance, aqui so usar o search
    //     // 2. Set up the Fuse instance
    //     const fuse = new Fuse(items, {
    //       keys: MODELS[modelName]._searchField as string[],
    //       threshold: 0.2,
    //       includeScore: true,
    //       includeMatches: true,

    //     })
    //     const searchResult = fuse.search(finalWord)
    //     if (!searchResult)
    //       return []
    //     return searchResult.filter(searchItem => searchItem?.score < 0.3).map(searchItem => searchItem.item)
    //   }
    //   return items
    // },

  },

  actions: {

    addModelItems(modelName: string, items: any) {
      const _modelKey = MODELS?.[modelName]?.getIdKey() ?? 'id'
      const map = this.models[modelName]
      if (!Array.isArray(items))
        items = [items]
      items.forEach((item) => {
        const key = item[_modelKey]
        map.set(key, item)
      })
    },

    removeModelItems(modelName: string, items: any) {
      const _modelKey = MODELS?.[modelName]?.getIdKey() ?? 'id'
      const map = this.models[modelName]
      if (!Array.isArray(items))
        items = [items]
      items.forEach((item) => {
        const key = isObject(item) ? item[_modelKey] : item
        map.delete(key)
      })
    },

    modelLoading(modelName: string, status: boolean) {
      // this.models[modelName].loading = status
    },

    async loadModel(modelName: string, id?: string | number | null, FilterOptions?: findOptionsT) {
      const dataSource = getDataSource(modelName)
      this.modelLoading(modelName, true)
      let responseApi

      if (id)
        responseApi = await dataSource.get(modelName, id, FilterOptions) // await api.request<ItemAPIReturnT>(createRequestConfigForModel({ modelName, action: 'item', id, data: filters, page, limit, sort }))
      else
        responseApi = await dataSource.find(modelName, FilterOptions)

      this.modelLoading(modelName, false)

      if (responseApi.items)
        this.addModelItems(modelName, responseApi.items)

      // TODO Importante - substitui os items pelos items salvos no state
      return responseApi
    },

    async saveModel(modelName: string, item: object, id?: string | number | null, filter): Promise<SaveItemReturnT> {
      const model = MODELS?.[modelName]
      const dataSource = getDataSource(modelName)
      const _id = id || model.getId(item)
      this.modelLoading(modelName, true)
      let responseApi
      debugger
      if (_id)
        responseApi = await dataSource.update(modelName, item, _id, filter) // await api.request<ItemAPIReturnT>(createRequestConfigForModel({ modelName, action: 'item', id, data: filters, page, limit, sort }))
      else
        responseApi = await dataSource.create(modelName, item, filter)

      this.modelLoading(modelName, false)

      if (responseApi.items)
        this.addModelItems(modelName, responseApi.items)

      return responseApi

      // const _modelKey = getModelIdKey(modelName)

      // const dataFinal = JSON.parse(JSON.stringify(data))
      // // check if we have id
      // if (dataFinal.hasOwnProperty(_modelKey)) {
      //   const idInData = dataFinal[_modelKey]
      //   delete dataFinal[_modelKey]
      //   if (!modelID && idInData && idInData !== 'new')
      //     modelID = idInData
      // }
      // const newModel = !modelID
      // let responseApi
      // const response: SaveItemReturnT = {
      //   hasError: false,
      //   item: null,
      //   originalItem: dataFinal,
      //   message: null,
      //   fieldErros: null,
      // }

      // this.modelLoading(modelName, true)
      // try {
      //   if (newModel) {
      //     // responseApi = await api.post<SaveAPIReturnT>(`/${url}`, dataFinal)
      //     responseApi = await api.request<ItemAPIReturnT>(createRequestConfigForModel({ modelName, action: 'save', data: dataFinal }))
      //   }
      //   else {
      //     // no put precisamos incluir o ID do modelo no put
      //     // dataFinal[_modelKey] = modelID
      //     // delete dataFinal[_modelKey]
      //     // responseApi = await api.put<SaveAPIReturnT>(`/${url}/${modelID}`, dataFinal)
      //     responseApi = await api.request<ItemAPIReturnT>(createRequestConfigForModel({ modelName, action: 'update', id: modelID, data: dataFinal }))
      //   }
      // }
      // catch (e) {
      //   responseApi = e.response
      //   response.hasError = true
      // }

      // // se não tem put, tento patch - padronizar no backend
      // if (responseApi.status === 404 && !newModel) {
      //   // no put precisamos incluir o ID do modelo no put
      //   try {
      //     delete dataFinal[_modelKey]
      //     responseApi = await api.patch<SaveAPIReturnT>(`/${modelName}/${modelID}`, dataFinal)
      //     response.hasError = false
      //     // todo - verificar se é necessario reinjetar a key
      //     dataFinal[_modelKey] = modelID
      //   }
      //   catch (e) {
      //     responseApi = e.response
      //     response.hasError = true
      //   }
      // }

      // this.modelLoading(modelName, false)
      // const responseData = responseApi?.data
      // if (responseData) {
      //   const items = getItemsFromServerResponse(responseData)
      //   if (items) {
      //     this.addModelItems(modelName, items)
      //     response.item = items?.[0]
      //   }
      //   response.data = responseData
      //   response.message = responseData?.mensagem ?? responseData?.message
      //   const fieldErros: { [index: string]: string } = {}
      //   if (responseData?.retorno) {
      //     responseData.retorno.forEach((retornoErro) => {
      //       const fieldName = retornoErro.item[0] as string
      //       const errorMessage = retornoErro.mensagem
      //       fieldErros[fieldName] = errorMessage
      //     })
      //     response.fieldErros = fieldErros
      //   }
      //   if (responseData?.descricao) {
      //     responseData.descricao.forEach((descricaoErro) => {
      //       const fieldName = descricaoErro.atributo
      //       const errorMessage = descricaoErro.descricao
      //       fieldErros[fieldName] = errorMessage
      //     })
      //     response.fieldErros = fieldErros
      //   }
      // }

      // return response
    },

    async  deleteModel(modelName, id, options) {
      const model = MODELS?.[modelName]
      const dataSource = getDataSource(modelName)
      const _id = id
      this.modelLoading(modelName, true)
      let responseApi

      if (_id)
        responseApi = await dataSource.remove(modelName, _id, options) // await api.request<ItemAPIReturnT>(createRequestConfigForModel({ modelName, action: 'item', id, data: filters, page, limit, sort }))

      if (responseApi.hasError == false)
        this.removeModelItems(modelName, _id)

      return responseApi
    },

  },
})

function getDataSource(modelName: string): DataSourceT {
  const theModel: ModelClassT = MODELS?.[modelName] as ModelsT
  if (theModel?.datasource) {
    return theModel?.datasource
  }
  else {
    const defaultDataSource = import.meta.env?.VITE_API_DEFAULT_DATA_SOURCE || 'vallen'
    return DATASOURCES[defaultDataSource] as DataSourceT
  }
}

// interface createRequestOptions {
//   modelName: string
//   action: 'list' | 'item' | 'save' | 'update' | 'delete'
//   id?: string | number
//   data?: any
//   filters?: FilterOptionsT
//   page?: number
//   limit?: number
//   sort?: sortOptions
// }
// /**
//  * Gera a configuração do Axios para fazer a requisição conforme a açao solicitada e a configuração do modelo e o dado
//  * @param {string} modelName
//  * @param {'list'|'item'|'save'|'delete'} action
//  * @param {any} options:{id?:string|number;data?:any}
//  * @returns {any}
//  */
// function createRequestConfigForModel(options: createRequestOptions) {
//   const {
//     modelName,
//     action,
//     id,
//     data,
//     page = 1,
//     limit = 40,
//     sort,
//     filters,
//   } = options

//   const modelInfo = MODELS?.[modelName]
//   const _modelKey = getModelIdKey(modelName)
//   const url = modelInfo?.endpoint ?? modelName
//   const endpointOptions = modelInfo.endpointOptions
//   const _endpointOptions = {
//     list: {
//       url: endpointOptions?.list?.url ?? (`${url}/listar`),
//       method: endpointOptions?.list?.method ?? 'post',
//     },
//     item: {
//       url: endpointOptions?.item?.url ?? `${url}/{id}`,
//       method: endpointOptions?.item?.method ?? 'get',
//     },
//     save: {
//       url: endpointOptions?.save?.url ?? `${url}`,
//       method: endpointOptions?.save?.method ?? 'post',
//     },
//     delete: {
//       url: endpointOptions?.delete?.url ?? `${url}/{id}`,
//       method: endpointOptions?.delete?.method ?? 'delete',
//     },
//     update: {
//       url: endpointOptions?.update?.url ?? `${url}/{id}`,
//       method: endpointOptions?.update?.method ?? 'put',
//     },
//   }
//   const config: AxiosRequestConfig = {
//     url: _endpointOptions?.[action]?.url,
//     method: _endpointOptions?.[action]?.method,
//   }

//   if (data) {
//     config.data = structuredClone(data)
//     Object.entries(config.data).forEach((entrie) => {
//       const key = entrie[0]
//       const value = entrie[1]
//       let partToReplace = `{${key}}`
//       if (_modelKey === key)
//         partToReplace = '{id}'

//       if (config.url && config.url.lastIndexOf(partToReplace) > -1) {
//         config.url = config.url.replace(partToReplace, value as string)
//         delete config.data[key]
//       }
//     })

//     // lidar com filtros
//     if (action === 'list') {
//       const filterForAPI: ModelFilterT = {}

//       if (filters)
//         filterForAPI.filtros = convertFilterToAPI(filters)
//       debugger;
//       if (sort) {
//         filterForAPI.ordenar = convertSortToAPI(sort)
//       }
//       // else {
//       //   const firstOrderField = MODELS?.[modelName]?._orderFields?.[0] as string
//       //   if (firstOrderField)
//       //     filterForAPI.ordenar = [{ atributo: firstOrderField, ordenarPor: 'asc' }]
//       // }

//       if (page)
//         filterForAPI.page = page

//       if (limit)
//         filterForAPI.limit = limit

//       config.data = { ...config.data, ...filterForAPI }
//     }
//   }

//   if (id && config.url)
//     config.url = config.url.replace('{id}', id as string)

//   return config
// }

// /**
//  * Pego os items que retornaram. Como no formato da vallen varia conforme o modelo, utilizao isso para extrair todas as keys conhecidas/padrao, e retorno a que sobra
//  * @param {ItemAPIReturnT|ListaAPIReturnT} responseData
//  * @returns {any}
//  */
// function getItemsFromServerResponse(responseData: ItemAPIReturnT | ListaAPIReturnT) {
//   const keysToIgnore = ['status', 'mensagem', 'message', 'registros']
//   const objectKeys = Object.keys(responseData)
//   const remainKeys = objectKeys.filter(objectKey => !keysToIgnore.includes(objectKey))

//   const items = responseData[remainKeys[0]]
//   if (!items)
//     return null
//   return Array.isArray(items) && klona(items) || klona([items])
// }

// /**
//  * Converte do formato de sort para a assinatura da vallen
//  * @param {sortOptions} sortIn
//  * @returns {any}
//  */
// function convertSortToAPI(sortOptions: sortOptions[]): sortVallenAPI {
//   const sortConverted: sortVallenAPI = []
//   sortOptions.forEach((item:sortOptions) => {
//     sortConverted.push({ atributo: item.key, ordenarPor: item.order })
//   },
//   )
//   return sortConverted
// }

// /** Convert filtro em formato lodash para assinatura da API da vallen
//  *
//  * {attribute: valor, attribute2: valor}
//  * {attribute: ['eq',valor], attribute2: ['like',valor]}
//  */
// function convertFilterToAPI(filterIn: FilterOptionsT): { or?: Array<FilterAPI>; and?: Array<FilterAPI> } {
//   let and: Array<FilterAPI>
//   let or: Array<FilterAPI>

//   function processFilterItem(item: Array<FilterAPI> | FilterAsArray | FilterAsObject): Array<FilterAPI> {
//     let returnFilter: FilterAPI[] = []
//     if (isObject(item)) {
//       Object.entries(item).forEach((i) => {
//         const k = i[0]
//         const v = i[1]
//         const value = isArray(v) ? v[1] : v
//         const operador = isArray(v) ? v[0] : 'eq'
//         const obj: FilterAPI = { atributo: k, value, operador }
//         returnFilter.push(obj)
//       })
//     }
//     else if (isArray(item)) {
//       returnFilter = item as unknown as Array<FilterAPI>
//     }
//     return returnFilter
//   }

//   if (filterIn?.and)
//     and = processFilterItem(filterIn.and)
//   if (filterIn?.or)
//     or = processFilterItem(filterIn.or)
//   if (!filterIn?.and && !filterIn?.or)
//     and = processFilterItem(filterIn)

//   // FIXME - Temporario pq a API nao aceita os numeros nos filtros, so string
//   if (and)
//     and.forEach(i => i.value = isNumber(i.value) ? i.value.toString() : i.value)
//   if (or)
//     or.forEach(i => i.value = isNumber(i.value) ? i.value.toString() : i.value)

//   return { and, or }
// }

// type statusT = 'Sucesso' | 'Falha' | 'Erro'

// export interface ItemAPIReturnT {
//   status: statusT
//   mensagem: string
//   [modelName: number ]: {}
// }

// export interface ListaAPIReturnT {
//   status: statusT
//   mensagem: string
//   registros: number
//   [modelName: number ]: {}

// }

// interface retornoErroT {
//   item: [ fieldName:string ]
//   mensagem: string
//   /** tipo pattern */
//   name: string | 'pattern'
//   argument: string
//   stack: string

// }

// interface descricaoErroT {
//   atributo: string
//   descricao: string
// }
// export interface SaveAPIReturnT {
//   status: statusT
//   mensagem: string
//   retorno?: retornoErroT[]
//   descricao?: descricaoErroT[]
//   [modelName: number]: {}
// }

// export interface LoadItemReturnT {
//   data?: ItemAPIReturnT | ListaAPIReturnT
//   hasError: boolean
//   message: string | null
//   items: object[] | null
//   item: object | null
// }

// export interface SaveItemReturnT {
//   data?: SaveAPIReturnT
//   hasError: boolean
//   message: string | null
//   item: object | null
//   originalItem: object | null
//   fieldErros: { [index: string]: string } | null
// }

// export interface FilterAPI {
//   atributo: string
//   value: string | number | boolean
//   operador: 'eq' | 'gt' | 'gte' | 'lt' | 'lte' | 'like' | 'between' | 'in'
// }

// export type sortVallenAPI = Array<{
//   atributo: string; ordenarPor: 'asc' | 'desc'
// }>

// export interface ModelFilterT {
//   filtros?: FilterOptionsT
//   ordenar?: sortVallenAPI
//   limit?: number
//   page?: number
// }
