import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: '{idclientevallen}/tipomovimentacao',
  displayName: {
    singular: 'Tipo de Movimentação',
    plural: 'Tipos de Movimentação',
  },
  idKey: 'id',
  icon: 'fork_right',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
      // props: {
      //   trueValue: 'ativo',
      //   falseValue: 'inativo',
      // },
    },
    {
      key: 'ds_movimentacao',
      label: 'Movimentação',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_movimentacao',
      label: 'Sigla',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'tp_operacao',
      label: 'Tipo',
      type: 'radio',
      required: true,
      search: true,
      default: 'ENTRADA',
      options: ['ENTRADA', 'SAIDA'],
    },
  ],
} as Model
