<script setup lang="ts">
import { onMounted, reactive, ref, toRef, watch } from 'vue'
import type { Ref } from 'vue'
import debounce from 'lodash/debounce'

import fieldSelect from './field-select.vue'
import fieldText from './field-text.vue'
import merge from '@/utils/merge'
import icon from '@/elements/icon.vue'

import listaUfs from '@/models/constants/uf'

const props = defineProps({
  label: String,
  name: String,
  hint: String,
  required: {
    type: Boolean,
    default: false,
  },
  error: String,
  modelValue: [Object, Array],
  value: String,
  multiple: Boolean,
  min: {
    default: 1,
  },
  max: {
    default: 10,
  },
})

const emit = defineEmits(['update:modelValue', 'change', 'complete'])

const ENDERECO_TEMPLATE = {
  co_seq_endereco: null,
  tp_endereco: 'C',
  co_cep: '',
  no_logradouro: '',
  no_bairro: '',
  no_numero: '',
  ds_complemento: '',
  no_municipio: '',
  sg_uf: '',
}
type Endereco = typeof ENDERECO_TEMPLATE

const tipoOptions = [{ C: 'Comercial' }, { R: 'Residencial' }, { O: 'Outros' }]

const _enderecos = ref<Endereco[]>([{ ...ENDERECO_TEMPLATE }])

const updateFromModelValue = function (newValue) {
  _enderecos.value.splice(0)
  newValue = Array.isArray(newValue) ? newValue : [newValue]
  for (let i = 0; i < newValue.length; i++) {
    let newEndereco = { ...ENDERECO_TEMPLATE }
    newEndereco = merge(newEndereco, newValue[i])
    _enderecos.value.push({ ...newEndereco })
  }
}

const addNewEndereco = function () {
  if (props.multiple && props.max > _enderecos.value.length)
    _enderecos.value.push({ ...ENDERECO_TEMPLATE })
}

const removeEndereco = function (enderecoIndex) {
  _enderecos.value.splice(enderecoIndex, 1)
}

let updateEmited = false
let propsModelValueChanged = false

watch(() => props.modelValue, (newValue: any, oldVal) => {
  if (updateEmited === true) {
    updateEmited = false
  }
  else {
    updateFromModelValue(newValue)
    propsModelValueChanged = true
  }
},
)

watch(_enderecos.value, debounce((newValue: Endereco[], oldVal) => {
  if (propsModelValueChanged === true) {
    // se o endereco mudou por conta do props, faco nada
    propsModelValueChanged = false
  }
  else {
    // // remove the id if is a new address
    let returnVal: Endereco | Endereco[] = [...newValue].map((v) => {
      const val = { ...v }
      if (!val?.co_seq_endereco)
        delete val?.co_seq_endereco
      return val
    })
    if (props.multiple === false)
      returnVal = returnVal[0]

    emit('update:modelValue', returnVal)
    emit('change', returnVal)
    updateEmited = true
  }
}, 400))

updateFromModelValue(props.modelValue)

async function buscaCEP(endereco: Endereco, event?: MouseEvent | undefined) {
  let butaoHtml, currentButaoText
  if (event) {
    butaoHtml = event.target
    currentButaoText = butaoHtml.textContent
  }
  if (endereco.co_cep) {
    const cep = endereco.co_cep.replace(/\D/g, '')
    if (cep.length == 8) {
      if (butaoHtml)
        butaoHtml.textContent = 'Buscando...'
      const res = await window.fetch(`https://viacep.com.br/ws/${cep}/json/`)
      const returnData = await res.json()
      if (!returnData.erro) {
        endereco.no_logradouro = returnData.logradouro
        endereco.no_bairro = returnData.bairro
        endereco.no_municipio = returnData.localidade
        endereco.sg_uf = returnData.uf
        endereco.ds_complemento = returnData.complemento
      }
      if (butaoHtml)
        butaoHtml.textContent = currentButaoText
    }
  }
}
</script>

<template>
  <div>
    <template v-for="(endereco, enderecoIndex) in _enderecos">
      <div v-if="true" class="fields-as-form pb-1 mb-1 endereco-wrapper">
        <div class="cep-wrap">
          <field-text
            v-model="endereco.co_cep"
            label="CEP"
            name="co_cep"
            :required="required"
            mask="00000-000"
            @input="(e) => buscaCEP(endereco)"
          />
          <button id="busca-cep" type="button" @click="e => buscaCEP(endereco, e)">
            Buscar
          </button>
        </div>
        <field-select
          v-model="endereco.tp_endereco"
          label="Tipo"
          name="tp_endereco"
          :required="required"
          :options="tipoOptions"
        />
        <field-text
          v-model="endereco.no_logradouro"
          name="no_logradouro"
          label="Logradouro"
          :required="required"
        />
        <field-text
          v-model="endereco.no_bairro"
          name="no_bairro"
          label="Bairro"
          :required="required"
        />
        <div class="flex-cols">
          <field-text
            v-model="endereco.no_numero"
            name="no_numero"
            label="Número"
            :required="required"
          />
          <field-text v-model="endereco.ds_complemento" name="ds_complemento" label="Complemento" />
        </div>
        <div class="flex-cols">
          <field-text
            v-model="endereco.no_municipio"
            name="no_municipio"
            label="Município"
            :required="required"
          />
          <field-select
            v-model="endereco.sg_uf"
            name="sg_uf"
            :options="listaUfs"
            class="w-10"
            label="UF"
            :required="required"
          />
        </div>
        <div class="text-right">
          <button
            v-if="multiple"
            class="btn px-0 mx-0 py-0 my-0 remove-endereco"
            type="button"
            @click="removeEndereco(enderecoIndex)"
          >
            <icon icon="trash" scale="1.6" />
          </button>
        </div>
      </div>
    </template>
    <div class="text-right">
      <button
        v-if="multiple"
        class="btn btn-small mt-2 add-endereco bg-gray"
        type="button"
        @click="addNewEndereco"
      >
        <b>+ Adicionar Endereço</b>
      </button>
    </div>

  <!--

    <div class="form-field">
      <div class="form-label">CEP <span class="req">*</span></div>
      <div class="form-input flex">
        <input type="text" class="w-10/12" v-model="model.co_cep" required />
        <button id="busca-cep" @click="buscaCEP" type="button">
          Buscar <span class="spinner" v-if="data.loadingCep">...</span>
        </button>
      </div>
    </div>

    <div class="form-field">
      <div class="form-label">Logradouro<span class="req">*</span></div>
      <div class="form-input">
        <input type="text" v-model="model.no_logradouro" required />
      </div>
    </div>

    <div class="form-field">
      <div class="form-label">Bairro<span class="req">*</span></div>
      <div class="form-input">
        <input type="text" v-model="model.no_bairro" required />
      </div>
    </div>

    <div class="form-field">
      <div class="form-label">Número</div>
      <div class="form-input">
        <input type="text" v-model="model.no_numero" />
      </div>
    </div>

    <div class="form-field">
      <div class="form-label">Complemento</div>
      <div class="form-input">
        <input type="text" v-model="model.ds_complemento" />
      </div>
    </div>

    <div class="flex gap-4">
      <div class="form-field w-8/12">
        <div class="form-label">Município<span class="req">*</span></div>
        <div class="form-input">
          <input type="text" v-model="model.no_municipio" required />
        </div>
      </div>

      <div class="form-field w-24">
        <div class="form-label">UF <span class="req">*</span></div>
        <div class="form-input">
          <select v-model="model.sg_uf">
            <option v-for="uf in listaUfs" :value="uf" :key="uf">
              {{ uf }}
            </option>
          </select>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
  </div>
</template>

<style lang="scss" scoped>
    button {
      background-color: #33333330;
      color: black;
    }.add-endereco {
      background: var(--color-primary);
    }.remove-endereco {
      background: rgba(255, 0, 0, 0) !important;
      color: rgba(134, 15, 15, 0.7) !important;
      margin: 0.5em 0 0 0;
      padding: 0;&:hover {
        color: rgba(255, 0, 0, 1) !important;
      }
    }.cep-wrap {
      position: relative;#busca-cep {
        position: absolute;
        right: 10px;
        top: 3px;
        color: black;
        background-color: #33333330;
        padding: 0.4em 0.7em;
        border-radius: 0.5em;
        font-size: 10px;
        opacity: 0.6;
      }
    }
</style>
