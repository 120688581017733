<script setup>
import { computed, onMounted, ref, toRef, watch } from 'vue'
import { watchTriggerable } from '@vueuse/core'
import { Model, loadModelItems, useSaveModelItem } from '@/models/useModelComposable'
import api from '@/api'
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue', 'change'])
const error = ref(null)
const recursos = ref([])
onMounted(async () => {
  try {
    const responseApi = await api.get('/sync/recursos')
    recursos.value = responseApi.data?.recursos
  }
  catch (e) {
    console.log(e)
    error.value.e = 'Erro a carregar a lista de recursos'
  }
})

const modelValueToRef = toRef(props, 'modelValue')
const _vmodel = ref([])

watchTriggerable(modelValueToRef, (val, oldval) => {
  if (JSON.stringify(val) !== JSON.stringify(oldval)) {
    _vmodel.value.splice(0)
    if (Array.isArray(val)) {
      val.forEach((v) => {
        const id = v?.recurso?.id ?? v?.co_recursofuncionalidade
        if (id)
          _vmodel.value.push(id)
      })
    }
  }
}).trigger()

watch(_vmodel, (val) => {
  let valueFinal = null
  if (Array.isArray(val)) {
    valueFinal = []
    val.forEach((v) => {
      valueFinal.push({ co_recursofuncionalidade: v })
    })
  }
  emit('update:modelValue', valueFinal)
  emit('change', valueFinal)
})

// const modelFinal = computed()
</script>

<template>
  <div>
    <div v-for="recurso in recursos" class=" ml-0 ">
      <label class="flex gap-3 items-start bg-slate-50 py-2 px-2 pointer mb-1.5">
        <input
          v-model="_vmodel"
          :value="recurso.id"
          type="checkbox"
          class="mt-1"
        >
        <div>
          <h6 class="text-sm pt-0 mt-0">
            <b>{{ recurso.sg_funcionalidade_recurso }}</b>
          </h6>
          <div><small> {{ recurso.ds_recurso_funcionalidade }} </small></div>
        </div>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
