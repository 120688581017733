<script>
import Icon from '@/elements/icon.vue'
export default {
  components: { Icon },
  props: {
    /**
    show alert in warn color
    */
    warn: {
      type: Boolean,
      default: true,
    },
    /**
    show alert in error color
    */
    error: {
      type: Boolean,
      default: false,
    },
    /**
    show alert in info color
    */
    info: {
      type: Boolean,
      default: false,
    },
    /**
    show alert in success color
    */
    success: {
      type: Boolean,
      default: false,
    },
    /**
    show a default icon for each type. if prefere, can use the icon slot for custom icons
    */
    // TODO
    showIcon: {
      type: Boolean,
      default: false,
    },
    /**
    if want to use a custom color for the alert
    */
    // TODO
    color: {
      type: String,
    },
    /**
    if want to show a close buttom
    */
    // TODO
    closeButton: {
      type: Boolean,
    },
  },

  computed: {
    // return icon for the type of alert, fooling the order of priority
    myIcon() {
      if (this.success)
        return 'success'
      if (this.error)
        return 'error'
      if (this.warn)
        return 'warn'
      return ''
    },
  },
}
</script>

<template>
  <div class="alert" :class="{ 'alert--warn': warn, 'alert--info': info, 'alert--error': error, 'alert--success': success }">
    <div
      v-if="showIcon || $slots.icon"
      class="alert__icon"
    >
      <Icon v-if="showIcon" :icon="myIcon" />
      <slot v-else name="icon"></slot>
    </div>
    <div class="alert__text">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
    :root {
      --alert-border-radius: 0px;
      --alert-border-width: 0 0 0 4px;
      --alert-padding: 1em 1em 1em 2em;
      --alert--warn-color: orange;
      --alert--error-color: red;
      --alert--info-color: #4fc6f3;
      --alert--success-color: #35c48b;
      --alert--background-opacity: 0.2;
    }.alert {
      display: flex;
      position: relative;
      padding: var(--alert-padding);
      color: var(--alert--warn-color);
      border-radius: var(--alert-border-radius, 4px);
      background: white;
      border-width: var(--alert-border-width);
      border-style: solid;
      border-color: currentColor;&:after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: currentColor;
        opacity: var(--alert--background-opacity, 0.2);
      }
    }.alert__icon {
      max-width: 2em;
      margin-left: 1em;
      margin-right: 1em;
    }.alert__text {
      grow: 1;
      color: black;
    }.alert--warn {
      color: var(--alert--warn-color);
    }.alert--error {
      color: var(--alert--error-color);
    }.alert--info {
      color: var(--alert--info-color);
    }.alert--success {
      color: var(--alert--success-color);
    }
</style>
