import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { klona } from 'klona/json'
import router from '@/router'

type statusT = 'Sucesso' | 'Falha' | 'Erro'
export interface ItemAPIReturnT {
  status: statusT
  mensagem: string
  [modelName: number ]: {}
}

export interface ListaAPIReturnT {
  status: statusT
  mensagem: string
  registros: number
  [modelName: number ]: {}

}

axios.defaults.baseURL = import.meta.env?.VITE_API_BACKEND_URL ?? 'http://localhost'
const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem('userToken')
  const idclientevallen = localStorage.getItem('idclientevallen')

  req.url = req.url?.replaceAll('{idclientevallen}', idclientevallen)

  if (token)
    req.headers.authorization = `Bearer ${token}`

  return req
})

axiosApiInstance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, (error) => {
  console.log(error)
  if (error?.response?.status === 401) {
    router.push('/login')
    notify({
      title: 'Sem autorização',
      type: 'error',
    })
  }
  return Promise.reject(error)
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
})

export default axiosApiInstance

export function getItemsFromServerResponse(responseData: ItemAPIReturnT | ListaAPIReturnT) {
  const keysToIgnore = ['status', 'mensagem', 'message', 'registros']
  const objectKeys = Object.keys(responseData)
  const remainKeys = objectKeys.filter(objectKey => !keysToIgnore.includes(objectKey))

  const items = responseData[remainKeys[0]]
  if (!items)
    return null
  return Array.isArray(items) && klona(items) || klona([items])
}
