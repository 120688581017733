import { computed } from 'vue'
import generateOptionFormat from '@/utils/generateOptionFormat'

export function useOptions(optionsProp) {
  const computeOptions = computed(() => {
    const options = generateOptionFormat(optionsProp)
    return options
  })
  return computeOptions
}
