<script setup lang="ts">
import type { PropType } from 'vue'
import { isString } from '@vueuse/shared'
import { mergeProps, onRenderTracked, onRenderTriggered, ref, useAttrs } from 'vue'
import { useDropZone } from '@vueuse/core'
import { emitDef, propsDef, useFieldInput } from './useFieldInput'
import fieldTemplate from './field-template.vue'
const props = defineProps({
  ...propsDef,
  min: {
    default: undefined,
  },
  max: {
    default: undefined,
  },
  /** max size in kb */
  maxSize: {
    type: Number,
    default: 3000,
  },
  thumbnailMaxWidth: {
    type: Number,
    default: 200,
  },
  emitThumbnailOrFile: {
    type: String as PropType<'thumbnail' | 'file'>,
    default: 'thumbnail',
  },
  acceptFilesType: {
    type: [Array, String] as PropType<string | string[]>,
    default: () => ['image/jpeg', 'image/png'],
  },
})

const emit = defineEmits([...emitDef])

//  Vars Refs  ---------------------------------

const internalError = ref(null)
/** ref to input element */
const fileInput = ref(null)
const dropZoneRef = ref<HTMLDivElement>()
/** current FILE selected */
const currentFile = ref(null)
/** thumbnail representation of file (jpg,png) */
const fileBase64Thumb = ref(null)
/** current Value */
const _value = toRef(props, 'modelValue')

//  Events  ---------------------------------

const onFileSelected = (e) => {
  internalError.value = null
  const file = e.target.files[0]
  e.target.blur()
  if (file)
    currentFile.value = file
  else
    currentFile.value = null
}

function onDrop(files: File[] | null) {
  internalError.value = null
  const file = files?.[0]
  if (file) {
    if (checkFileAccepts(file)) { currentFile.value = file }
    else {
      currentFile.value = null
      internalError.value = `Tipo de arquivo não aceito. Aceitos: ${props.acceptFilesType}`
    }
  }
  else { currentFile.value = null }
}

const onRemove = (e) => {
  internalError.value = null
  emit('update:modelValue', null)
}

const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

// Create thumbnail from file when it changes
watchEffect(() => {
  if (currentFile.value) {
    if (currentFile.value.size > props.maxSize * 1024) {
      internalError.value = `Tamanho do arquivo precisa ser menor que ${props.maxSize}kb`
      currentFile.value = null
      fileInput.value.value = null
      return
    }
    if (currentFile.value.type.startsWith('image/')) {
      createThumbnail(currentFile.value)
        .then((thumbnailBase64) => {
          fileBase64Thumb.value = thumbnailBase64
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
})

// Emit file or thumbnail when it changes
watchEffect(() => {
  if (currentFile.value) {
    if (props.emitThumbnailOrFile === 'thumbnail')
      emit('update:modelValue', fileBase64Thumb.value)

    else
      emit('update:modelValue', currentFile.value)
  }
})

//  COMPUTED INFOS  ---------------------------------

const accepetExtensionsDisplay = computed(() => {
  const acceptFilesTypeArray = Array.isArray(props.acceptFilesType) ? props.acceptFilesType : [props.acceptFilesType]
  return acceptFilesTypeArray.reduce((acc, curr) => {
    const ext = curr.split('/')[1]
    return `${acc + ext},`.toLocaleUpperCase()
  }, '')
})

watchEffect(() => {
  // check if value is data64 image compatible, and set a var with it
  if (isString(_value.value) && _value?.value?.startsWith('data:image/'))
    fileBase64Thumb.value = _value.value
})

//  Utility functions  ---------------------------------

function createThumbnail(file) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()

    reader.onload = (e) => {
      img.src = e.target.result
      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const thumbnailWidth = props.thumbnailMaxWidth
        const scaleFactor = thumbnailWidth / img.width
        const thumbnailHeight = img.height * scaleFactor

        canvas.width = thumbnailWidth
        canvas.height = thumbnailHeight

        ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight)

        // Get base64 encoded thumbnail
        const thumbnailBase64 = canvas.toDataURL()
        resolve(thumbnailBase64)
      }
    }
    img.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

function checkFileAccepts(file) {
  const acceptFilesTypeArray = Array.isArray(props.acceptFilesType) ? props.acceptFilesType : [props.acceptFilesType]
  return acceptFilesTypeArray.includes(file.type)
}

function formatFileSize(bytes) {
  if (bytes === 0)
    return '0 Bytes'

  const units = ['KB', 'MB', 'GB', 'TB']
  const index = Math.floor(Math.log(bytes) / Math.log(1024))

  return `${(bytes / 1024 ** index).toFixed(2)} ${units[index]}`
}
</script>

<template>
  <field-template
    :required="props.required"
    :error="props.error || internalError"
    :hint="props.hint"
  >
    <template #label>
      <slot name="label">
        <span v-if="props.label" v-html="props.label"></span>
      </slot>
    </template>
    <template #hint>
      <slot name="hint">
        <span v-if="props.hint" v-html="props.hint"></span>
      </slot>
    </template>
    <div class="flex gap-5">
      <div class="col-span-full">
        <div
          ref="dropZoneRef"
          class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
          :class="{ 'bg-action': isOverDropZone, 'opacity-60': isOverDropZone }"
        >
          <div class="text-center">
            <div v-if="_value" class="flex flex-col gap-2 items-center">
              <img
                v-if="fileBase64Thumb"
                :src="fileBase64Thumb"
                class=" w-36 inline-block"
              />
              <div
                v-else
                class="flex flex-col gap-2 items-center"
              >
                <svg
                  width="29"
                  height="36"
                  viewBox="0 0 29 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M17.9167 0H3.58333C1.6125 0 0.0179171 1.6125 0.0179171 3.58333L0 32.25C0 34.2208 1.59458 35.8333 3.56542 35.8333H25.0833C27.0542 35.8333 28.6667 34.2208 28.6667 32.25V10.75L17.9167 0ZM3.58333 32.25V3.58333H16.125V12.5417H25.0833V32.25H3.58333Z" fill="#D1D5DB" />
                </svg>
                <template v-if="currentFile">
                  <span class="text-sm">{{ currentFile?.name }}</span>
                  <span class="text-sm">{{ formatFileSize(currentFile.size / 1000) }}</span>
                </template>
                <template v-else="_value.indexOf('http') === 0">
                  <div class="flex gap-2 items-center color-action">
                    <q-icon name="link" /><a :href="_value" target="_blank"> Vizualizar</a>
                  </div>
                </template>
              </div>
              <div style="position:absolute; right:0em" @click="onRemove">
                <q-icon name="delete" color="red"></q-icon>
              </div>
            </div>
            <svg
              v-else
              class="mx-auto h-12 w-12 text-gray-300"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />

            </svg>
            <div class="mt-4 flex text-sm leading-6 text-gray-600">
              <label for="file-upload" class="relative cursor-pointer rounded-md  font-bold color-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                <span>Enviar um arquivo</span>
                <input
                  id="file-upload"
                  ref="fileInput"
                  style="display: none;"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  :accept="props.acceptFilesType"
                  @change="onFileSelected"
                >
              </label>
              <p class="pl-1">
                ou arraste e solte
              </p>
            </div>
            <p class="text-xs leading-5 text-gray-600">
              {{ accepetExtensionsDisplay }} máximo {{ formatFileSize(props.maxSize) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </field-template>
</template>
