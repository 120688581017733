<script setup>
import { uiStore, useAuthStore } from '@/stores'

const auth = useAuthStore()
const ui = uiStore()

const logout = async () => {
  await auth.logout()
}

const onClienteVallenSelected = (clientVallen) => {
  auth.setCurrentClientVallen(clientVallen)
  window.location.reload()
}
</script>

<template>
  <q-header>
    <q-toolbar class="bg-toolbar">
      <q-btn
        flat
        round
        dense
        icon="menu"
        @click="ui.drawer = !ui.drawer"
      />
      <q-toolbar-title>
        <div class="w-44">
          <router-link to="/">
            <img class="w-full" src="/images/vallen-logo.svg" alt="" />
          </router-link>
        </div>
      </q-toolbar-title>
      <q-tabs shrink breakpoint="400" outside-arrows>
        <q-route-tab
          v-if="auth.hasPermission('ORCAMENTO:CRIAR')"
          to="/atendimento"
          exact
        >
          <q-item>
            <q-item-section side>
              <icon class="brand-icon q-icon brand-primary" icon="/icon/atendimento.svg"></icon>
            </q-item-section>
            <q-item-section class=" brand-primary ">
              <span class="text-xs">Novo Atendimento</span>
            </q-item-section>
          </q-item>
        </q-route-tab>
        <q-route-tab
          to="/atendimento"
          exact
        >
        </q-route-tab>
      </q-tabs>
      <q-space />
      <q-btn-dropdown
        outline
        size="11px"
        color="secondary"
        :ripple="false"
      >
        <template #label>
          <span>
            <q-icon name="person" size="1em" />  {{ auth.user?.nome }}
          </span>&ensp;|&ensp;
          <span v-if="auth.hasClientVallen">
            <q-icon name="domain" size="0.8em" />  {{ auth.getCurrentClientVallen?.sg_unidade }}
          </span>
        </template>
        <q-list>
          <template v-if="auth.hasClientVallen">
            <q-item-label header>
              Empresas
            </q-item-label>
            <q-item
              v-for="client in auth.getVallenClientsForUser"
              :key="client.id"
              v-close-popup
              clickable
              :active="client?.id == auth.getCurrentClientVallen?.id"
              active-class="bg-teal-1 text-grey-8"
              @click="onClienteVallenSelected(client)"
            >
              <q-item-section>
                <q-item-label class="ellipsis w-42">
                  <q-icon name="domain" size="0.8em" /> {{ client.no_unidade }}
                </q-item-label>
                <q-item-label caption>
                  {{ client.sg_unidade }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-separator />
          </template>

          <!-- <q-item v-close-popup clickable @click="onItemClick">
            <q-item-section>
              <q-item-label>Informações</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item v-close-popup clickable @click="logout">
            <q-item-section>
              <q-item-label>Sair</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon size="xs" name="logout" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>
</template>

<style lang="scss">
    .bg-toolbar {
      width: 100%;
      height: 65px;
      background: #1B1D4D;
    }
</style>
