import UF from '../constants/uf'

export default {
  fake: false,
  displayName: {
    singular: 'Usuário',
    plural: 'Usuários',
  },
  icon: 'group',
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
    },
    {
      key: 'no_nome',
      label: 'Nome',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
      sortable: true,
    },
    {
      key: 'sg_sexo',
      label: 'Sexo',
      type: 'radio',
      options: [{ M: 'Masculino' }, { F: 'Feminino' }, { NE: 'Não Especificado' }],
      required: true,
    },
    {
      key: 'dt_nascimento',
      label: 'Data de Nascimento',
      type: 'date',
      required: true,
      size: 10,
    },
    {
      key: 'co_cpf',
      label: 'CPF',
      type: 'text',
      required: true,
      mask: '000.000.000-00',
      search: true,
      displayLabel: true,
      size: 25,
    },
    {
      key: 'co_rg',
      label: 'RG nº',
      type: 'text',
      required: true,
      group: 'rg',
      search: true,
      displayLabel: true,
      size: 15,
    },
    {
      key: 'no_orgaoexpedidor',
      label: 'Órgão Exp.',
      type: 'text',
      required: true,
      group: 'rg',
      size: 10,
      displayLabel: true,
    },
    {
      key: 'sg_ufexpedidor',
      label: 'UF Exp.',
      type: 'select',
      options: UF as Array<string>,
      required: true,
      group: 'rg',
      size: 5,
    },
    {
      key: 'ds_email',
      label: 'Email',
      type: 'email',
      required: true,
      search: false,
      displayLabel: true,
    },
    {
      key: 'nu_telefone1',
      label: 'Telefone',
      type: 'text',
      mask: '(00) 00000-000000',
      required: true,
    },
    {
      key: 'nu_telefone2',
      label: 'Telefone 2',
      type: 'text',
      mask: '(00) 00000-000000',
      required: false,
    },
    {
      key: 'no_responsavel',
      label: 'Nome',
      type: 'text',
      required: false,
    },
    {
      key: 'co_cpfresponsavel',
      label: 'CPF',
      type: 'text',
      required: false,
    },
    {
      key: 'ds_emailresp',
      label: 'Email',
      type: 'email',
      required: false,
    },
    {
      key: 'enderecos',
      label: 'Endereços',
      type: 'endereco',
      required: false,
      multiple: true,
    },
  ],
  table: {
    cols: ['id', 'no_nome', 'sg_sexo', 'nu_telefone1', 'nu_telefone2'],
  },
  beforeSave: (item) => {
    notify()
  },
  afterSave: (item, ctx) => {
    if (item?.idSistema) {
      ctx.$q.notify({
        html: true,
        timeout: 3 * 60 * 1000,
        message: `<h6 class='mb-2'>Dados do login do usuário</h6>
                <div>usuário:<b>${item.idSistema}</b></div>
                <div>senha:<b>${item.senha}</b></div>`,
        color: 'green',
        icon: 'face',
        position: 'bottom',
        avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
        actions: [
          {
            label: 'Copiar',
            icon: 'copy_all',
            color: 'yellow',
            handler: async () => {
              await navigator.clipboard.writeText(`
                usuário: ${item.idSistema}
                senha:${item.senha}
                `)
              ctx.$q.notify({ message: 'Dados copiados!', position: 'bottom' })
            },
          },
          { label: 'Fechar', color: 'white', handler: () => { /* ... */ } },
        ],
      })
    }
  },
} satisfies ModelT
