import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { MODELS, getModelIdKey } from '@/models/models'
import { useAuthStore } from '@/stores'
import HomeView from '@/views/HomeView.vue'
import LoginPage from '@/views/LoginPage.vue'
import ModelView from '@/views/ModelView.vue'
import VallenHeader from '@/components/VallenHeader.vue'
import VallenSidebar from '@/components/VallenSidebar.vue'

const modelRoutes = MODELS
  ? (Object.keys(MODELS).map((modelKey) => {
      return {
        path: `/${modelKey}/:id?`,
        name: modelKey,
        components: {
          default: () => import('@/views/ModelView.vue'),
          top: VallenHeader,
          sidebar: VallenSidebar,
        },
        meta: { requiresAuth: true },
      }
    }))
  : []

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: HomeView,
      top: VallenHeader,
      sidebar: VallenSidebar,
    },
    meta: { requiresAuth: true },
  },
  ...modelRoutes,
  {
    path: '/model/:modelname/:id?',
    name: 'model',
    components: {
      default: () => import('@/views/ModelView.vue'),
      top: VallenHeader,
      sidebar: VallenSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import('@/views/LoginPage.vue'),
    },
    meta: { requiresAuth: false },
  },
  {
    path: '/atendimentoform/:id?',
    name: 'atendimentoform',
    components: {
      default: () => import('@/views/AtendimentoView.vue'),
      top: VallenHeader,
      sidebar: VallenSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/test',
    name: 'test',
    components: {
      default: () => import('@/views/TestView.vue'),
      // top: VallenHeader,
    },
    meta: { requiresAuth: true },
  },
]

// console.log(routes)
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const auth = useAuthStore()
  if (to.meta.requiresAuth) {
    const have_acess = await auth.getMe()
    if (!auth.user) {
      auth.returnUrl = to.fullPath
      return '/login'
    }
  }
})

export default router
