import get from 'lodash/get'
import type { ModelCollection, ModelT, ModelTypes, TypeTeste, sortOptions } from './modelsDef'
import type DataSource from './../datasource/DataSourceClass'
import { isFunction } from '@/utils/getType'
import { uiStore, useAuthStore } from '@/stores'
const DATASOURCE_DEFAULT = import.meta.env?.VITE_API_DEFAULT_DATA_SOURCE || 'vallen'
export const modelsImported: any = import.meta.glob('@/**/*.model.ts', { eager: true })

/** All datasources */
export const DATASOURCES: { [sourceName: string]: DataSourceT } = {}
/** All Models */
export const MODELS: { [modelName: string]: ModelClass } = {}
export const TESTE = 1
// Class Format
export class ModelClass {
  modelName
  modelIdKey
  config: ModelT
  /** All models fields */
  fields: FieldT[] = [{ key: 'id', label: 'Id', type: 'number' }]
  allFieldsName: Array<string>
  /** Fields thant can be search */
  searchFields: FieldT[]
  /** Fields thant can be ordered */
  orderFields: FieldT[]
  /** Default fields to be show in tables and lists */
  displayFields?: FieldT[]
  dataSource: DataSourceT
  allDataSources: typeof DATASOURCES
  endpoint: string
  endpointOptions: ModelTypes.endpoint

  constructor(modelName: string, ModelConfig: ModelT, dataSources: typeof DATASOURCES) {
    this.modelName = modelName
    this.config = ModelConfig
    this.fields = ModelConfig.fields
    this.modelIdKey = this.getIdKey()
    this.searchFields = this.fields.filter(field => field?.search === true)
    this.displayFields = this.fields.filter(field => field?.displayLabel === true)

    this.orderFields = this.fields.filter(field => field?.sortable === true)
    this.allFieldsName = this.fields.map(field => field.key)
    if (!this.searchFields)
      this.searchFields = this.fields
    if (!this.displayFields)
      this.displayFields = this.fields

    this.allDataSources = dataSources
    const dataSourceName = this.config?.dataSource ?? DATASOURCE_DEFAULT
    this.dataSource = this.allDataSources?.[dataSourceName]

    this.endpoint = this.config?.endpoint ?? this.modelName
    this.endpointOptions = this.config.endpointOptions
    // Add the model to dataSource.models it belongs
    this.dataSource.addModel(modelName, this)
  }

  getName() {
    return this.modelName
  }

  getDisplayName(plural = false) {
    const singularName = this.config.displayName?.singular ?? this.modelName
    if (plural)
      return this.config.displayName?.plural ?? singularName

    return singularName
  }

  getIcon() {
    return this.config?.icon
  }

  getId(item: any) {
    const key = this.getIdKey()
    if (item)
      return (item?.[key] ?? item?._id) ?? item.id
  }

  getFields(): FieldT[] {
    return this.fields
  }

  getFieldsToDisplay(): FieldT[] {
    if (this.displayFields)
      return this.displayFields

    return this.getFields()
  }

  /** return the item value, or the component??? */
  getFieldValueForItem(item: any, field: FieldT) {
    if (isFunction(field.renderFn))
      return field.renderFn(item)
    if (field.displayValue) {
      const displayValue = field.displayValue
      if (isFunction(displayValue))
        return displayValue(item)
      return get(item, displayValue)
    }

    return item?.[field.key]
  }

  getFormLink(item: any) {
    return `/${this.config?.editLink ?? this.modelName}/${this.getId(item)}`
  }

  getIdKey() {
    return this.config?.idKey ?? this.fields[0]?.key
  }

  getDataSource(): DataSourceT {
    return this.dataSource
  }

  getOrderOptions(): Array<sortOptions> {
    const fields = this.orderFields
    if (!fields)
      return [{ key: this.getIdKey(), order: 'asc' }]

    return fields.map((theField) => {
      return { key: theField.key, order: theField?.sortOrder ?? 'asc' }
    })
  }

  getDefaultFilterOptions(target: 'table' | 'form' | 'list' | 'view' | 'display' = 'table'): FilterOptionsT {
    const config = this.config
    const filter = config?.[target]?.defaultFilters ?? null
    return filter
  }

  getFieldsForView(target: 'table' | 'form' | 'list' | 'view' | 'display' = 'table'): FieldT[] {
    const allFields = this.fields

    if (!allFields)
      return

    const config = this.config
    const fieldsToIgnore = config?.[target]?.ignore ?? []
    let targetCols = config?.[target]?.cols ?? this.displayFields ?? []

    if (target === 'form')
      targetCols = []

    let fields: FieldT[] = []

    // if have custom cols setup
    if (Array.isArray(targetCols) && targetCols.length > 0) {
      // se especificamos os fields em uma table ou list, queremos exibir este de qualeur maneira???
      const customFieldsTemplate = { hidden: false, ignore: false }
      targetCols.forEach((col) => {
        let colSetup
        if (typeof col == 'string') {
          colSetup = allFields.find(field => field.key === col)
        }
        else {
          colSetup = allFields.find(field => field.key === col.key)
          if (!colSetup)
            colSetup = {}
          colSetup = { ...colSetup, ...col }
        }
        if (colSetup)
          fields.push({ ...colSetup, ...customFieldsTemplate })
      })
    }
    else {
      fields = allFields
    }
    const fieldsObj: FieldT[] = []
    fields.forEach((field) => {
      const key = field?.key ?? field?.label
      const label = field?.label ?? field?.name

      const ignore = fieldsToIgnore.includes(key) ? true : field?.ignore === true
      const hidden = field?.hidden === true
      const fieldType = field?.type ?? 'text'

      const fieldObj: FieldT = {
        ...field,
        type: fieldType,
        key,
        label,
        hidden,
        ignore,
      }
      fieldObj.props = {
        ...field,
        name: field.key,
        label,
        renderFn: field?.customValue ? field?.customValue : item => item[field.key],
        component: field?.component,
      }
      if (!ignore)
        fieldsObj.push(fieldObj)
    })

    return fieldsObj
  }

  async afterSave(item, ctx: { $q; $router; modelStore }) {
    if (isFunction(this.config?.afterSave))
      return this.config.afterSave(item, ctx)
  }

  async find(ModelObjectOrURL: ModelOrURLT, findOptions: findOptionsT) {

  }

  async get(modelObjectOrNameOrURL: ModelOrURLT, id: string | number, findOptions: findOptionsT) {

  }

  async create() {

  }

  async update() {

  }

  async remove() {

  }

  canEdit() {
    const auth = useAuthStore()
    // TODO IMPORTANTE - REMOVE LINHA
    return true
    return auth.hasPermission(`${this.modelName}:CRIAR`)
  }

  canRemove() {
    const auth = useAuthStore()
    return auth.hasPermission(`${this.modelName}:CRIAR`)
  }

  canView() {
    const auth = useAuthStore()
    return auth.hasPermission(`${this.modelName}:LISTAR`)
  }
}

//  Load all dataSources  ---------------------------------

const dataSourceFiles = import.meta.glob('@/**/*.datasource.*s', { eager: true, import: 'default' }) as any
for (const path in dataSourceFiles) {
  const dataSourceName: string = path.match(/(\w+).datasource.ts*$/)?.[1] as string
  if (typeof dataSourceName === 'string') {
    const DsClass = dataSourceFiles[path]
    DATASOURCES[dataSourceName] = new DsClass()
  }
}

//  Load all models  ---------------------------------

for (const path in modelsImported) {
  let modelName: string = path.match(/([\w-_]+).model.ts*$/)?.[1] as string
  if (typeof modelName === 'string') {
    const modelConfig: ModelT = modelsImported[path].default
    modelName = modelConfig.name || modelName
    modelConfig.name = modelName
    MODELS[modelName] = new ModelClass(modelName, modelConfig, DATASOURCES)
  }
}

//  Processe fields info
// ------------------------

// Object.entries(Models).forEach((entrie) => {
//   const modelName = entrie[0]
//   const model = entrie[1]
//   model._searchField = model.fields.filter(field => field?.search === true).map(field => field.key)
//   model._displayFields = model.fields.filter(field => field?.displayLabel === true).map(field => field.key)
//   model._orderFields = model.fields.filter(field => field?.sortable === true).map(field => field.key)
//   model._allFieldsName = model.fields.map(field => field.key)
//   if (!model._searchField)
//     model._searchField = model._allFieldsName
//   if (!model._displayFields)
//     model._displayFields = model._allFieldsName
// })

// expose the models as default and const

export type ModelsT = typeof MODELS
export type ModelClassT = ModelClass
export default MODELS

declare global{
  export type ModelClassT = ModelClass
  export type ModelsT = typeof MODELS
}

/**
 * Get All Model Fields
 *
 * @param {string} modelName
 * @return {*}  {Fields[]}
 */
export const getModelFields = (modelName: string): FieldT[] => {
  return MODELS[modelName].fields
}

/**
 * Get a Model Field
 * @param {string} modelName
 * @param {string} fieldKey
 * @returns {any}
 */
export const getFieldByKey = (modelName: string, fieldKey: string): FieldT | undefined => {
  const fields = getModelFields(modelName)
  return fields.find(field => field.key === fieldKey)
}

/**
 * get model id used for key
 *
 * @param {string} modelName
 * @return {string}
 */
export const getModelIdKey = (modelName: string): string => {
  return MODELS[modelName]?.idKey
}

/**
 * Get the id Value for a model item
 *
 * @param {string} modelName
 * @return {string}
 */
export const getModelId = (modelName: string, modelObject: any): string => {
  const keyname = getModelIdKey(modelName)
  return modelObject?.[keyname]
}

export const getModelOrderOptions = (modelName: string): Array<sortOptions> => {
  const fields = MODELS?.[modelName]?._orderFields
  if (!fields)
    return [{ key: getModelIdKey(modelName), order: 'asc' }]

  return fields.map((fieldKey) => {
    const theField = getFieldByKey(modelName, fieldKey)
    return { key: fieldKey, order: theField?.sortOrder ?? 'asc' }
  })
}

/**
 * Return the fields objects configuration for each type of view
 * @param {string} modelName
 * @param {'table'|'form'|'list'='table'} target
 * @returns {any}
 */
export function getFieldsFor(modelName: string, target: 'table' | 'form' | 'list' | 'view' = 'table') {
  const model = MODELS?.[modelName]
  const idKey = getModelIdKey(modelName)
  const fieldsToIgnore = model?.[target]?.ignore ?? []
  const targetCols = model?.[target]?.cols ?? []

  let fields = []

  const allFields = model?.fields
  if (!allFields)
    return

  // if have custom cols setup
  if (Array.isArray(targetCols) && targetCols.length > 0) {
    // se especificamos os fields em uma table ou list, queremos exibir este de qualeur maneira???
    const customFieldsTemplate = { hidden: false, ignore: false }
    targetCols.forEach((col) => {
      let colSetup
      if (typeof col == 'string') {
        colSetup = allFields.find(field => field.key === col)
      }
      else {
        colSetup = allFields.find(field => field.key === col.key)
        if (colSetup)
          colSetup = { ...colSetup, ...col }
      }
      if (colSetup)
        fields.push({ ...colSetup, ...customFieldsTemplate })
    })
  }
  else {
    fields = allFields
  }

  const fieldsObj = []
  fields.forEach((field) => {
    const key = field?.key ?? field?.label
    const label = field?.label ?? key
    const ignore = fieldsToIgnore.includes(key) ? true : field?.ignore === true
    const hidden = field?.hidden === true
    const fieldType = field?.type ?? 'text'

    const fieldObj = {
      type: fieldType,
      label,
      key,
      hidden,
      ignore,
    }

    fieldObj.props = {
      ...field,
      name: field.key,
      label,
      renderFn: field?.customValue ? field?.customValue : item => item[field.key],
      component: field?.component,
    }
    // fieldObj.valueRef = ref(null);
    if (!ignore)
      fieldsObj.push(fieldObj)
  })

  return fieldsObj
}

