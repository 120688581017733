export default {
  fake: false,
  endpoint: '{idclientevallen}/usuariopapel',
  endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Usuário Papel',
    plural: 'Usuário Papeis',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
    },
    {
      key: 'co_clientevallen',
      label: 'Empresa',
      type: 'model',
      modelName: 'pessoajuridica',
      displayLabel: true,
    },

    {
      key: 'co_perfil',
      label: 'Perfil',
      type: 'model',
      modelName: 'perfil',
      displayLabel: true,
      props: {
        showEdit: false,
        showAdd: false,
      },
    },

    {
      key: 'co_usuario',
      label: 'Usuário',
      type: 'number',
      hidden: false,
    },

  ],
  table: {
    cols: [
      { key: 'co_perfil', renderFn: e => e?.perfil?.no_perfil },
      { key: 'co_clientevallen', renderFn: e => e?.ClienteVallen?.no_unidade },
    ],
  },
} satisfies ModelT
