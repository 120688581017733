

type ObjIndex = {[index: string | number ]: any}

export default function(originalObj:ObjIndex,sourceObj:ObjIndex | Array<ObjIndex>, onlyPropsInOriginal: boolean = true ): any {
    if(!sourceObj) return originalObj
    let newObject:ObjIndex = {} // Object.assign(sourceObj )

    if( Array.isArray(sourceObj) ){
        let tempObj = {}
        sourceObj.forEach( o => tempObj = {...tempObj,...o})
        sourceObj = tempObj
    }
    if(onlyPropsInOriginal){
        Object.keys(originalObj).forEach( key =>{
            newObject[key] = sourceObj?.[key] ?? originalObj?.[key] 
        })
    }else{
        newObject = {...originalObj,...sourceObj}
    }
    return newObject
}