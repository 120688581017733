export const active = true
export const requestData = {
  no_nome: 'Anderson Souza Vieira Arantes',
  sg_sexo: 'F',
  dt_nascimento: '1998-06-01',
  co_cpf: '77898797897',
  co_rg: '1773686',
  no_orgaoexpedidor: '234',
  sg_ufexpedidor: 'BA',
  ds_email: 'leandroceles@gmail.com',
  nu_telefone1: '61981221844',
  nu_telefone2: '2345234523452',
  no_responsavel: null,
  co_cpfresponsavel: null,
  ds_emailresp: null,
  enderecos: [{ tp_endereco: 'C', co_cep: '70855020', no_logradouro: 'SQN 407 Bloco B', no_bairro: 'Asa Norte', no_numero: '205', ds_complemento: '', no_municipio: 'Brasília', sg_uf: 'DF' }],
}

export const responseData = {
  status: 'sucesso',
  mensagem: 'Usuário cadastrado com sucesso',
  usuario: {
    id: 8,
    nome: 'Anderson Souza Vieira Arantes',
    cpf: '77898797897',
    idSistema: 'anderson_8',
    senha: '6dqbq38zka',

  },
}

