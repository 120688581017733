import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: 'sync/recursos',
  displayName: {
    singular: 'Recursos',
    plural: 'Recurso',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'no_funcionalidade',
      label: 'Nome Funcionalidade',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'no_recurso',
      label: 'Nome Recurso',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_funcionalidade_recurso',
      label: 'Sigla funcionalidade recurso',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'ds_recurso_funcionalidade',
      label: 'Descricao funcionalidade recurso',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },

  ],
} as Model
