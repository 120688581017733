/* eslint-disable prefer-const */
import type { AxiosRequestConfig, AxiosRequestTransformer, Method } from 'axios'
import axios, { Axios } from 'axios'
import { filter } from 'lodash'
import { klona } from 'klona/json'
import DataSourceClass from './DataSourceClass'
import type { DataSourceReturnT, ModelOrURLT } from './DataSourceClass'
import { isArray, isNumber, isObject } from '@/utils/getType'

const axiosApiInstance = axios.create({ baseURL: import.meta.env?.VITE_API_BACKEND_URL ?? 'http://localhost' })

let CURRENT_REQUEST_MODEL: ModelOrURLT = ''

export interface FilterAPIT {
  filtros?: FilterOptionsT
  ordenar?: sortVallenAPI
  limit?: number
  page?: number
}

type sortVallenAPI = Array<{
  atributo: string
  ordenarPor: 'asc' | 'desc'
}>

export default class extends DataSourceClass {
  constructor() {
    super(axiosApiInstance)
  }

  beforeRequest(req: AxiosRequestConfig) {
    const token = localStorage.getItem('userToken')
    const idclientevallen = localStorage.getItem('idclientevallen')

    if (!req.headers)
      req.headers = {}

    if (idclientevallen)
      req.url = req.url?.replaceAll('{idclientevallen}', idclientevallen)
    if (token)
      req.headers.authorization = `Bearer ${token}`

    // check if url need data to be replaced

    if (req.data) {
      const data = req.data
      const url = req.url
      if (url && data && isObject(data)) {
        const { url: newUrl, data: newData } = replacePlaceholders(url, data)
        req.url = newUrl
        req.data = newData
      }
    }

    // now, check if need to be form data
    const data = req.data
    let containsFile = false
    if (data) {
      Object.values(data).forEach((item) => {
        if (item instanceof File)
          containsFile = true
      })
    }

    if (containsFile) {
      const formData = objectToFormData(req.data)
      req.headers['Content-Type'] = 'multipart/form-data'
      req.data = formData
    }

    // Check if we are in fake mode
    const isToFake = localStorage.getItem('fakedata')

    if (isToFake === 'true')
      req.url = req.url = `/fake/${CURRENT_REQUEST_MODEL}`

    // req.data = { ...req.data, __fakedata: true }

    return req
  }

  afterRequest(res: AxiosResponse) {
    if (res?.status === 401)
      console.error('implementar quando sem autorização')
    return res

    // TODO IMPORTANTE - implementar quando nao tem autorizacao
    //     router.push('/login')
    //     notify({
    //       title: 'Sem autorização',
    //       type: 'error',
    //     })
    //   }
    //   return Promise.reject(error)
  }

  async find(modelOrUrl: ModelOrURLT, findOptions: FindOptionsT = {}) {
    let {
      url,
      method,
      model,
    } = this.getRequestInfosFor(
      {
        modelOrUrl,
        action: 'find',
        defaultUrl: '{url}/listar',
        defaultMethod: 'post',
      },
    )

    let dataFinal = {}

    let {
      filters,
      filterWord,
      sort,
      limit,
      page,
      includes,
      data = {},
      rawData,
    } = findOptions

    const filterForAPI: FilterAPIT = {}

    if (rawData) {
      dataFinal = rawData
    }
    else {
      if (isObject(filters)) {
        const res = replacePlaceholdersFromFilters(filters, url)
        url = res.url
        filters = res.filters
      }

      if (filters || filterWord)
        filterForAPI.filtros = convertFilterToAPI(filters, filterWord, model)
      if (sort)
        filterForAPI.ordenar = convertSortToAPI(sort)
      if (page)
        filterForAPI.page = page
      if (limit)
        filterForAPI.limit = limit

      dataFinal = { ...filterForAPI, ...data }
    }

    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
    }

    let responseApi

    try {
      // TODO IMPORTANT - incluir processamento da url para mudar {aaa}, pelas chaves

      responseApi = await this.api.request({ method, url, data: dataFinal })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      const items = getItemsFromServerResponse(responseApi.data)
      if (items)
        response.items = items

      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
    }

    return response
  }

  async get(modelOrUrl: ModelOrURLT, id: string | number | Array<string | number>, findOptions: FindOptionsT) {
    const {
      url,
      method,
      model,
    } = this.getRequestInfosFor(
      {
        modelOrUrl,
        action: 'get',
        defaultUrl: '{url}/{id}',
        defaultMethod: 'get',
        replaceInUrl: { id },
      },
    )

    // if multiple id, get the list of it, filtering
    if (Array.isArray(id))
      return this.find(modelOrUrl, { filters: { [model.getIdKey()]: ['in', id] } })

    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
    }

    let responseApi

    try {
      // TODO IMPORTANT - incluir processamento da url para mudar {aaa}, pelas chaves
      responseApi = await this.api.request({ method, url, data: {} })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      const items = getItemsFromServerResponse(responseApi.data)
      if (items) {
        response.item = items[0]
        response.items = items
      }
      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
    }

    return response
  }

  async create(modelOrUrl: ModelOrURLT, itemToSave: object, filter = {}) {
    let {
      url,
      method,
      model,
    } = this.getRequestInfosFor(
      {
        modelOrUrl,
        action: 'create',
        defaultUrl: '{url}',
        defaultMethod: 'post',
      })

    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
      fieldErrors: null,
    }

    let responseApi

    // const itemToSave = structuredClone(item)
    const key = model.getIdKey()
    if (itemToSave?.[key] !== undefined)
      delete itemToSave[key]

    url = replacePlaceholdersFromFilters(filter, url).url
    try {
      responseApi = await this.api.request({ method, url, data: itemToSave })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      const items = getItemsFromServerResponse(responseApi.data)

      if (items?.[0]) {
        // VERIFICAR - nem todos os modelos retornam o item salvo inteiro.
        const itemFinal = { ...itemToSave, ...items[0] }
        response.item = itemFinal
        response.items = [itemFinal]
      }
      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
      response.fieldErrors = processFieldErros(responseApi.data)
    }

    return response
  }

  async update(modelOrUrl: ModelOrURLT, item: object, id: number | string) {
    const {
      url,
      method,
      model,
    } = this.getRequestInfosFor(
      {
        modelOrUrl,
        action: 'update',
        defaultUrl: '{url}/{id}',
        defaultMethod: 'put',
        replaceInUrl: { id },
      })

    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
      fieldErrors: null,
    }

    let responseApi

    const itemToSave = { ...item }
    const key = model.getIdKey()
    if (item?.[key])
      delete itemToSave[key]

    try {
      // TODO IMPORTANT - incluir processamento da url para mudar {aaa}, pelas chaves
      responseApi = await this.api.request({ method, url, data: itemToSave })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      const items = getItemsFromServerResponse(responseApi.data)
      if (items) {
        response.item = items[0]
        response.items = items
      }
      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
      response.fieldErrors = processFieldErros(responseApi.data)
    }

    return response
  }

  async remove(modelOrUrl: ModelOrURLT, id: number | string, filter) {
    let {
      url,
      method,
    } = this.getRequestInfosFor(
      {
        modelOrUrl,
        action: 'remove',
        defaultUrl: '{url}/{id}',
        defaultMethod: 'delete',
        replaceInUrl: { id },
      })

    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
    }

    let responseApi

    url = replacePlaceholdersFromFilters(filter, url).url
    try {
      responseApi = await this.api.request({ method, url })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
    }

    return response
  }

  async execute({ url, method, data }) {
    const response: DataSourceReturnT = {
      hasError: false,
      items: null,
      item: null,
      message: null,
      originalResponse: null,
      data: null,
    }
    let responseApi
    try {
      responseApi = await this.api.request({ method, url, data })
    }
    catch (e) {
      responseApi = e.response
      response.hasError = true
      console.error(e)
    }

    if (responseApi?.data) {
      response.originalResponse = responseApi
      response.data = responseApi.data
      response.message = responseApi.data?.mensagem ?? responseApi.data?.message
    }

    return response
  }

  process(url, model, data) {

  }

  getUrlForModel(modelObjectOrNameOrURL: string | ModelClassT, action: 'find' | 'get' | 'update' | 'remove') {
    const modelName = modelObjectOrNameOrURL?.name ?? modelObjectOrNameOrURL
    const model = this.models[modelName]
    let url = ''
    let method = 'post' // na vallen o post é utilizado apra passar muitos filtros

    if (model) {
      url = model.endpoint
      const option = model.endpointOptions?.[action]
      if (option) {
        url = option?.url ?? (`${url}/listar`)
        method = option?.method ?? 'post'
      }
    }
    else {
      url = modelName
      method = 'get'
    }

    return { url, method }
  }

  getRequestInfosFor({ modelOrUrl, action, defaultUrl, defaultMethod, replaceInUrl = {} }: getRequestInfosForT) {
    const modelName = modelOrUrl?.name ?? modelOrUrl
    const model = this.models[modelName]
    CURRENT_REQUEST_MODEL = modelName

    let url = model?.endpoint ?? modelName
    let method = defaultMethod

    if (model) {
      url = defaultUrl.replace('{url}', url)
      const endpointOption = model?.endpointOptions?.[action] ?? {}
      if (endpointOption) {
        url = endpointOption?.url ?? url
        method = endpointOption?.method ?? method
      }
    }

    Object.entries(replaceInUrl).forEach(
      (entrie) => {
        const key = entrie[0]
        const value = entrie[1]
        const partReplace = `{${key}}`

        url = url.replace(partReplace, value)
      },
    )

    return { modelName, model, url, method }
  }
}

type actionT = 'find' | 'get' | 'create' | 'update' | 'remove'

interface getRequestInfosForT {
  modelOrUrl: ModelOrURLT
  action: actionT
  /** Can use placeholder {url} to complet url, like {url}/list */
  defaultUrl: string
  defaultMethod: Method
  replaceInUrl?: { [keytoreplace: string]: string | nmumber }
}

interface createRequestOptions {
  modelName: string
  action: 'list' | 'item' | 'save' | 'update' | 'delete'
  id?: string | number
  data?: any
  filters?: FilterT
  page?: number
  limit?: number
  sort?: sortOptions
}

function processFieldErros(responseData) {
  const fieldErros: { [index: string]: string } = {}
  if (responseData?.retorno) {
    responseData.retorno.forEach((retornoErro) => {
      const fieldName = retornoErro.item[0] as string
      const errorMessage = retornoErro.mensagem
      fieldErros[fieldName] = errorMessage
    })
  }
  if (responseData?.descricao) {
    responseData.descricao.forEach((descricaoErro) => {
      const fieldName = descricaoErro.atributo
      const errorMessage = descricaoErro.descricao
      fieldErros[fieldName] = errorMessage
    })
  }
  return fieldErros
}

/**
 * Gera a configuração do Axios para fazer a requisição conforme a açao solicitada e a configuração do modelo e o dado
 * @param {string} modelName
 * @param {'list'|'item'|'save'|'delete'} action
 * @param {any} options:{id?:string|number;data?:any}
 * @returns {any}
 */

/**
 * Pego os items que retornaram. Como no formato da vallen varia conforme o modelo, utilizao isso para extrair todas as keys conhecidas/padrao, e retorno a que sobra
 * @param {ItemAPIReturnT|ListaAPIReturnT} responseData
 * @returns {any}
 */
function getItemsFromServerResponse(responseData: ItemAPIReturnT | ListaAPIReturnT) {
  const keysToIgnore = ['error', 'stack', 'status', 'mensagem', 'message', 'registros', 'retorno']
  const objectKeys = Object.keys(responseData)
  const remainKeys = objectKeys.filter(objectKey => !keysToIgnore.includes(objectKey))

  const items = responseData[remainKeys[0]]
  if (!items)
    return null
  return Array.isArray(items) && klona(items) || klona([items])
}

/**
 * Converte do formato de sort para a assinatura da vallen
 * @param {sortOptions} sortIn
 * @returns {any}
 */
function convertSortToAPI(sortOptions: sortOptions[]): sortVallenAPI {
  const sortConverted: sortVallenAPI = []
  sortOptions.forEach((item: sortOptions) => {
    sortConverted.push({ atributo: item.key, ordenarPor: item.order })
  },
  )
  return sortConverted
}

/** Convert filtro em formato lodash para assinatura da API da vallen
 *
 * {attribute: valor, attribute2: valor}
 * {attribute: ['eq',valor], attribute2: ['like',valor]}
 */
export function convertFilterToAPI(filterIn: FilterOptionsT, filterWord = '', model?): FilterAPI {
  let and: Array<FilterItemAPI> | null = null
  let or: Array<FilterItemAPI> | null = null

  function processFilterItem(item: FilterOptionsT): Array<FilterItemAPI> | null {
    let returnFilter: Array<FilterItemAPI> = []
    if (!item)
      return null
    if (isObject(item)) {
      Object.entries(item).forEach((i) => {
        const k = i[0]
        const v = i[1]
        const value = isArray(v) ? v[1] : v
        const operador = isArray(v) ? v[0] : 'eq'
        const obj: FilterItemAPI = { atributo: k, value, operador }
        returnFilter.push(obj)
      })
    }
    else if (isArray(item)) {
      returnFilter = item as unknown as Array<FilterItemAPI>
    }
    return returnFilter
  }

  // if a search string, i will search in all searcheable fields
  if (typeof filterIn === 'string' && !filterWord)
    filterWord = filterIn

  if (filterWord && model) {
    const searchFields = model.searchFields
    const filterTmp: any = {}
    searchFields.forEach((field) => {
      filterTmp[field.key] = ['like', `%${filterWord}%`]
    })
    or = processFilterItem(filterTmp)
  }

  if (typeof filterIn !== 'string') {
    if (filterIn?.and)
      and = processFilterItem(filterIn.and)
    if (filterIn?.or) {
      const newOr = processFilterItem(filterIn.or)
      if (newOr)
        or = (or) ? or.concat(newOr) : newOr
    }
    if (!filterIn?.hasOwnProperty('and') && !filterIn?.hasOwnProperty('or'))
      and = processFilterItem(filterIn)
  }

  // FIXME - Temporario pq a API nao aceita os numeros nos filtros, so string
  if (and)
    and.forEach(i => i.value = isNumber(i.value) ? i.value.toString() : i.value)
  if (or)
    or.forEach(i => i.value = isNumber(i.value) ? i.value.toString() : i.value)

  const returnObj: FilterAPI = {}
  if (and)
    returnObj.and = and
  if (or)
    returnObj.or = or
  return returnObj
}

function objectToFormData(obj, formData = new FormData(), parentKey = '') {
  for (const key in obj) {
    const value = obj[key]
    const fullKey = parentKey ? `${parentKey}[${key}]` : key

    if (value instanceof File)
      formData.append(fullKey, value)

    else if (value instanceof Blob)
      formData.append(fullKey, value, value.name)

    else if (typeof value === 'object' && value !== null)
      objectToFormData(value, formData, fullKey)

    else
      formData.append(fullKey, value)
  }

  return formData
}

function replacePlaceholders(urlTemplate, inputData) {
  const regex = /{(\w+)}/g
  const data = { ...inputData }

  const url = urlTemplate.replace(regex, (match, propertyName) => {
    const value = data[propertyName]
    if (value !== undefined) {
      delete data[propertyName]
      return value
    }
    return match
  })

  return { url, data }
}

export function replacePlaceholdersFromFilters(inputFilter: FindOptionsT, inputString: string) {
  const pattern = /{(\w+)}/g
  let match
  let options = { ...inputFilter }

  while ((match = pattern.exec(inputString)) !== null) {
    const attribute = match[1]

    // Check if attribute exists in rawData
    if (options && options.hasOwnProperty(attribute)) {
      inputString = inputString.replace(`{${attribute}}`, options[attribute])
      delete options[attribute]
    }

    // Check if attribute exists in data
    else if (options.and && options.and.hasOwnProperty(attribute)) {
      inputString = inputString.replace(`{${attribute}}`, options.and[attribute])
      delete options.and[attribute]
    }
    else if (options.or && options.or.hasOwnProperty(attribute)) {
      inputString = inputString.replace(`{${attribute}}`, options.or[attribute])
      delete options.or[attribute]
    }
  }

  return { url: inputString, filters: options }
}

type statusT = 'Sucesso' | 'Falha' | 'Erro'

interface DataSourceItemAPIReturnT {
  status: statusT
  mensagem: string
  [modelName: number ]: {}
}

interface DataSourceListAPIReturnT {
  status: statusT
  mensagem: string
  registros: number
  [modelName: number ]: {}

}

interface DataSourceLoadItemReturnT {
  data?: DataSourceItemAPIReturnT | DataSourceListAPIReturnT
  hasError: boolean
  message: string | null
  items: object[] | null
  item: object | null
}

interface DataSourceSaveAPIReturnT {
  status: statusT
  mensagem: string
  retorno?: retornoErroT[]
  descricao?: descricaoErroT[]
  [modelName: number]: {}
}

interface retornoErroT {
  item: [ fieldName:string ]
  mensagem: string
  /** tipo pattern */
  name: string | 'pattern'
  argument: string
  stack: string

}
interface descricaoErroT {
  atributo: string
  descricao: string
}

export interface FilterAPI {
  or?: Array<FilterItemAPI>; and?: Array<FilterItemAPI>
}
export interface FilterItemAPI {
  atributo: string
  value: string | number | boolean
  operador: 'eq' | 'gt' | 'gte' | 'lt' | 'lte' | 'like' | 'between' | 'in'
}
