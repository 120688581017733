// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { child, get, getDatabase, push, ref, remove, set, update } from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDqEBbp_IA7qhkkJohw7ZGQPVS5YKPzQCY',
  authDomain: 'vallen-temp.firebaseapp.com',
  projectId: 'vallen-temp',
  storageBucket: 'vallen-temp.appspot.com',
  messagingSenderId: '859302540471',
  appId: '1:859302540471:web:fd2802702a881dac344932',
  databaseURL: 'https://vallen-temp-default-rtdb.firebaseio.com',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const dbRef = getDatabase(app)

async function writeToDatabase(modelName, data, id) {
  if (id) {
    const refId = ref(dbRef, `${modelName}/${id}`)
    await update(refId, { ...data })
    return data
  }
  else {
    let refId = ref(dbRef, modelName)
    refId = push(refId)
    const newId = refId.key
    data.id = newId
    await set(refId, { ...data })
    return data
  }
}

async function readFromDatabase(modeName, id = null) {
  const idStr = id ? `/${id}` : ''
  const refId = ref(dbRef, modeName + idStr)
  const snapshot = await get(refId)
  if (snapshot.exists())
    return snapshot.val()

  return undefined
}

async function removeFromDatabase(modelName, id = null) {
  if (id) {
    const refId = ref(dbRef, `${modelName}/${id}`)
    await remove(refId)
    return true
  }
  return undefined
}

export { app, writeToDatabase, readFromDatabase, removeFromDatabase }
