export const active = true
export const requestData = {
  no_tabela: 'Plano Bronze',
  co_conveniado: 3,
  st_ativo: true,
}

export const responseData = {
  status: 'sucesso',
  tabelaConvenio:
    {
      id: 3,
      no_tabela: 'Plano Bronze',
      co_clientevallen: 2,
      co_conveniado: 3,
      st_ativo: true,
      clientevallen: { no_unidade: 'Curupira', sg_unidade: 'CURU', ds_razao_social: 'Curupira', co_cnpj: '54353453453145', co_ie: '234234234', co_cnes: '21213212', co_registro_ans: '123123123', ds_email: 'leandroceles@gmail.com' },
      conveniado: { no_unidade: 'UniPlan', sg_unidade: 'UNP', ds_razao_social: 'UniPlan Socios LTDA', co_cnpj: '23452345234523', co_ie: '765745674', co_cnes: '45543245', co_registro_ans: '234523452', ds_email: 'leandroceles@gmail.com' },
    },
}

