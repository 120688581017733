import axios from 'axios'
import type { AxiosDefaults, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { includes } from 'lodash'
import { notify } from '@kyvg/vue3-notification'
import router from '@/router'

export type ModelOrURLT = ModelClass | string

export interface DataSourceReturnT {
  /** if a error occour */
  hasError: boolean
  fieldErrors?: { [key: string]: string } | null
  /** error object with infos */
  errorObj?: any
  /** Message, can be error message, successful menage, or message text returned from api */
  message?: string | null
  /** Return list of items */
  items?: object[] | null
  /** Return only one item */
  item?: object | null
  /** Original response object  */
  originalResponse?: any
  /** Original response DATA object  */
  data: any
}

class DataSource {
  axiosInstance
  api
  // router = router
  // notify = notify
  user = {}
  permissions = {}
  models: ModelsT = {}

  constructor(axiosInstOrConfig: AxiosInstance | AxiosRequestConfig | null = null) {
    if (axiosInstOrConfig?.request)
      this.axiosInstance = axiosInstOrConfig as AxiosInstance
    else
      this.axiosInstance = axios.create(axiosInstOrConfig as unknown as AxiosRequestConfig)

    this.axiosInstance.interceptors.request.use(this.beforeRequest)
    this.axiosInstance.interceptors.response.use(this.afterRequest)
    this.api = this.axiosInstance
  }

  // Configure Axios interceptors before the request
  beforeRequest(req: AxiosRequestConfig): AxiosRequestConfig {

  }

  // Configure Axios interceptors after the response
  afterRequest(res: AxiosResponse) {
    return res
  }

  addModel(modelName: string, modelClass: ModelClassT) {
    this.models[modelName] = modelClass
  }

  login() {

  }

  logout() {

  }

  /** FRequest Items */
  async find(ModelObjectOrURL: ModelOrURLT, findOptions: findOptionsT) {

  }

  async get(modelObjectOrNameOrURL: ModelOrURLT, id: string | number, findOptions: findOptionsT) {

  }

  async create(modelOrUrl: ModelOrURLT) {

  }

  async update() {

  }

  async remove() {

  }

  async execute({ url, method, data }: { url: string; method: Method; data?: any }) {
  }

  /**
   * Generate url and method for a model. It will get the endpoint and endpointOptions
 */
  getUrlAndMethodForModel(modelName: string, action: string): { method: Method; url: string } {
    const model = this.models?.[modelName]
    if (!model)
      return { method: 'get', url: modelName }

    const url = model?.config.endpoint ?? modelName
    const endpointOptions = model.config.endpointOptions
    const _endpointOptions = {
      list: {
        url: endpointOptions?.list?.url ?? (`${url}/listar`),
        method: endpointOptions?.list?.method ?? 'post',
      },
      item: {
        url: endpointOptions?.item?.url ?? `${url}/{id}`,
        method: endpointOptions?.item?.method ?? 'get',
      },
      save: {
        url: endpointOptions?.save?.url ?? `${url}`,
        method: endpointOptions?.save?.method ?? 'post',
      },
      delete: {
        url: endpointOptions?.delete?.url ?? `${url}/{id}`,
        method: endpointOptions?.delete?.method ?? 'delete',
      },
      update: {
        url: endpointOptions?.update?.url ?? `${url}/{id}`,
        method: endpointOptions?.update?.method ?? 'put',
      },
    }
    return {
      url: _endpointOptions?.[action]?.url,
      method: _endpointOptions?.[action]?.method,
    }
  }
} // end class

export default DataSource

export type DataSourceT = DataSource

declare global{
  export type DataSourceT = DataSource
  export type DataSourceReturnT = typeof DataSourceReturnT
}
