<script setup lang="ts">
import { type Ref, computed, onMounted, ref } from 'vue'
import Exame_valorForm from '../exame_valor/exame_valor.form.vue'
import { Filter, modelsStore as modelPinia } from '@/models/models.pinia'
import { Model, loadModelItems, useModel, useSaveModelItem } from '@/models/useModelComposable'

interface ProcedimentoT {
  id?: number
  no_exame: string
  sg_exame: string
  co_amb: string
  co_tuss: string
}

interface ExameValoresT {
  id?: number | null
  st_ativo: boolean
  co_exame: number | null | undefined
  vl_exame?: number
  co_tabela: number
  procedimento: ProcedimentoT
}

const props = defineProps({
  tabelaValoresId: {
    type: [Number, String],
    required: true,
  },
})

const showType: Ref<'allExames' | 'tabelaExames'> = ref('allExames')

watch(showType, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    if (newVal === 'allExames' && searchWord.value.trim())
      allExames.filterWord.value = searchWord.value.trim()
  }
})

const searchWord = ref('')

// watch search word, and depends on show tipy change allexames or tabelaexames
watch(searchWord, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    if (showType.value === 'allExames') {
      allExames.filterWord.value = newVal.trim()
    }
    else if (showType.value === 'tabelaExames') {
      // TODO - ver se procura em submodelo, ou implementar ou remover
      // tabelaExames.filterWord.value = newVal
    }
  }
})

const allExames = useModel<ProcedimentoT>('procedimentos', { limit: 50 })
allExames.loadItem()
const tabelaExames = useModel<ExameValoresT>('exame_valor', { limit: 99999, filters: { co_tabela: +props.tabelaValoresId } })
tabelaExames.loadItem()

const tableListItems = ref([])
watchEffect(() => {
  if (allExames.isFetching.value || tabelaExames.isFetching.value)
    return []

  const viewType = showType.value
  const myAllExames = allExames?.items?.value ?? []
  const myExamesValores = tabelaExames?.allItemsFiltered?.value ?? []
  let list: Array<ExameValoresT> = []
  const word = searchWord.value

  if (viewType === 'allExames') {
    myAllExames.forEach((procedimento) => {
      const exameValorItem = myExamesValores.find(exame_valor => exame_valor.co_exame === procedimento.id!) ?? {}
      const obj: ExameValoresT = {
        co_exame: procedimento.id,
        procedimento,
        st_ativo: false,
        vl_exame: 0,
        co_tabela: +props.tabelaValoresId,
        ...exameValorItem,
      }
      list.push(obj)
    })
  }
  else if (viewType === 'tabelaExames') {
    if (word.trim())
      list = myExamesValores.filter(exame_valor => Object.values(exame_valor.procedimento).join(' ').toLowerCase().includes(word.toLowerCase()))
    else
      list = myExamesValores
  }
  tableListItems.value = list
})

//  Function For Ite Tabela  ---------------------------------

const addExame = function (item) {
  if (!item)
    return
  const itemToSave = {
    co_tabela: +props.tabelaValoresId,
    co_exame: Number(item.id),
    st_ativo: true,
    vl_exame: Number(0),
  }

  tabelaExames.saveItem(itemToSave)
}
</script>

<template>
  <div class="container mx-auto mt-2">
    <!-- tabelaExames allItems: <pre>{{ tabelaExames.allItems.value }}</pre> -->
    <!-- tabelaExamesItems: <pre>{{ tabelaExamesItems }}</pre>
    tabelaExames: <pre>{{ tabelaExames.items }}</pre> -->
    <!-- tabelaExames: <pre>{{ tabelaExames.items }}</pre>
    allExames: <pre>{{ allExames.items }}</pre> -->
    <div class="flex my-2">
      <h5 class="grow text-weight-bolder color-secondary">
        Lista Exames Valores
      </h5>
      <div>
        <q-btn-toggle
          v-model="showType"
          class="my-custom-toggle"
          no-caps
          size="0.8em"
          rounded
          unelevated
          toggle-color="primary"
          color="white"
          text-color="primary"
          :options="[
            { label: 'Todos Exames', value: 'allExames' },
            { label: 'Só Cadastrados', value: 'tabelaExames' },
          ]"
        />
      </div>
    </div>

    <div class="mb-2">
      <q-input v-model="searchWord" color="primary" placeholder="Buscar">
        <template #prepend>
          <q-icon name="search" />
        </template>
        <template v-if="searchWord" #append>
          <q-icon
            name="close"
            class="pointer"
            clickable
            @click="searchWord = ''"
          />
        </template>
      </q-input>
    </div>

    <!-- <pre>tableListItems: {{ tableListItems }}</pre>
    <pre>allExames: {{ allExames?.items }}</pre> -->

    <table class="exame-valor w-full mt-1">
      <thead>
        <tr>
          <th class="w-9/12">
            Exame
          </th>
          <th class="w-2/12">
            Valor
          </th>
          <th class="w-1/12"></th>
        </tr>
      </thead>
      <tbody>
        <Exame_valorForm
          v-for="item in tableListItems"
          :key="item.procedimento.id + (item?.id ?? 'new')"
          :model-id="item?.id"
          :model-value="item"
          :exame="item?.procedimento"
          :co_tabela="props?.tabelaValoresId"
          :save-fn="tabelaExames.saveItem"
        />
      </tbody>
    </table>

    <div class="flex gap-5 justify-center mt-4">
      <q-pagination
        v-if="allExames.isFinished && showType === 'allExames'"
        v-model="allExames.page.value"
        class="text-slate-50"
        size="md"
        color="accent"
        :max="allExames.totalItems.value / 50"
        :max-pages="6"
        :boundary-numbers="false"
      />
    </div>
    <field-model
      v-if="showType === 'tabelaExames'"
      label="Adicionar Exame"
      placeholder="Selecione um procedimento para cadastrar a tabela"
      model-name="procedimentos"
      :emit-full-object="true"
      @change="addExame"
    />
  </div>
</template>

<style lang="scss">
    .exame-valor {

    }
</style>
