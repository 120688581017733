<!-- eslint-disable vue/require-v-for-key -->
<script setup lang="ts">
import { computed, h, isProxy, isRef, onMounted, reactive, ref, shallowRef, toRef, watch } from 'vue'
// import { notify } from '@kyvg/vue3-notification'
import type { Ref } from 'vue'

import { useModel } from './useModelComposable'
// All FAKE DATA COMPONENTES
import fakeData from '@/fakedata'
// define model
import ModalComp from '@/elements/modal.vue'

interface FieldValueT {
  [fieldName: string]: Ref<null | string | number | Date>
}

const props = defineProps({
  autoFields: {
    type: Boolean,
    default: true,
  },
  modelName: {
    type: String,
    required: true,
  },
  modelId: [String, Number],
  value: [Object],
  /** open in a modal the form */
  modal: Boolean,
  templateFile: [String, Object],

})

// define emits
const emit = defineEmits<{
  (e: 'saved', values: FieldValueT | any): void
  (e: 'error', value: FieldValueT): void
  (e: 'loaded', value: FieldValueT): void
  (e: 'cancel', value: FieldValueT): void
  (e: 'finish', value: FieldValueT): void
  (e: 'update:modelId', value: string | number): void
  (e: 'closed'): void
}>()

// //fields

const {
  isFetching,
  hasError,
  message,
  item,
  loadItem,
  saveItem,
  model,
  fieldErrors,
} = useModel(toRef(props, 'modelName'))

const modelId = toRef(props, 'modelId')
const _modelId = ref(props.modelId)
const _value = ref(props.value ?? {})
const _defaultValues = ref({})

const modelFields = ref<FieldT[] | null>(null)
watchEffect(() => {
  if (model.value) {
    modelFields.value = model.value.getFieldsForView('form')
    modelFields.value.forEach((field) => {
      if (field.default)
        _defaultValues.value[field.key] = field.default
    })
  }
})

watchEffect(() => {
  if (modelId.value)
    loadItem(modelId)
})

const saveForm = async (newValues: any) => {
  // save current values in state
  _value.value = newValues
  const response = await saveItem(newValues, _modelId)

  if (!response.hasError) {
    let newId = model.value?.getId(response.item)
    if (!newId)
      newId = _modelId.value
    // modelId.value = newId
    _modelId.value = newId
    emit('update:modelId', newId)
    emit('saved', response.item)
  }
}

watch(item, (newValue, oldValue) => {
  _value.value = newValue
})

const isNew = computed(() => {
  return !_modelId.value
})

const loadFakeData = () => {
  const data = fakeData?.[props.modelName]?.requestData

  if (data)
    _value.value = data
}
</script>

<template>
  <div class="model-form-wrapper" :class="{ modal: props.modal }">
    <AutoForm
      :id="_modelId"
      :modal="modal"
      :auto-fields="props.autoFields"
      :fields="modelFields"
      :fields-erros="fieldErrors"
      :value="{ ..._defaultValues, ..._value }"
      :is-fetching="isFetching"
      @submit="saveForm"
      @closed="() => emit('closed')"
    >
      <template #intro>
        <alert
          v-if="hasError"
          error
          close-button
          class="mb-4"
        >
          {{ message || 'Ocorreu algum erro!' }}
          <small v-if="fieldErrors">
            <details>
              <summary>Detalhes</summary>
              <li v-for="(v, k) in fieldErrors">
                <b> {{ v }}</b> {{ k }}
              </li>
            </details>
          </small>
        </alert>
      </template>
      <template #default="{ values, fields, fieldsProps, fieldsComp }">
        <slot
          :values="values"
          :fields-props="fieldsProps"
          :fields="fields"
          :fields-comp="fieldsComp"
          :is-new="isNew"
        />
      </template>
    </AutoForm>
  </div>

  <Teleport to="#debug">
    <button
      v-if="fakeData[props.modelName]"
      to="#debug"
      class="btn"
      @click="loadFakeData"
    >
      LOADE FAKE DATA
    </button>
  </Teleport>
</template>

