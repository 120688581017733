<script setup lang="ts">
import { onMounted, reactive, ref, toRef, watch } from 'vue'
import type { Ref } from 'vue'
import debounce from 'lodash/debounce'

import { useModel } from '@/models/useModelComposable'
import merge from '@/utils/merge'
import icon from '@/elements/icon.vue'

const props = defineProps({
  label: String,
  name: String,
  hint: String,
  required: {
    type: Boolean,
    default: false,
  },
  error: [Object, Array],
  modelValue: [Object, Array],
  modelName: {
    type: String,
  },
  value: [Object],
  multiple: {
    type: Boolean,
    default: true,
  },
  min: {
    default: 1,
  },
  max: {
    default: 10,
  },
  /** open in a modal the form */
  modal: Boolean,
  templateFile: [String, Object],
  // TODO - add the fields prop
})

const emit = defineEmits(['update:modelValue', 'change', 'complete'])

const {
  isFetching,
  hasError,
  message,
  item,
  loadItem,
  saveItem,
  model,
  fieldErrors,
} = useModel(toRef(props, 'modelName'))

const modelFields = ref<FieldT[] | null>(null)
const _defaultValues = ref({})

const _value = ref(props.value ?? [])

const updateEmited = false
const propsModelValueChanged = false

// const updateFromModelValue = function (newValue) {
//   _enderecos.value.splice(0)
//   newValue = Array.isArray(newValue) ? newValue : [newValue]
//   for (let i = 0; i < newValue.length; i++) {
//     let newEndereco = { ..._defaultValues.value }
//     newEndereco = merge(newEndereco, newValue[i])
//     _enderecos.value.push({ ...newEndereco })
//   }
// }

const addNew = function () {
  if (props.multiple && props.max > _value.value.length)
    _value.value.push({ ..._defaultValues.value })
}

const remove = function (index) {
  _value.value.splice(index, 1)
}

// get the model fields and defaults
// TODO - get from fields props too
watch(model.value, () => {
  if (model.value) {
    modelFields.value = model.value.getFieldsForView('form')
    modelFields.value.forEach((field) => {
      if (field.default)
        _defaultValues.value[field.key] = field.default
    })
  }
  addNew()
}, { immediate: true })

watch(_value, (newValue, oldVal) => {
  emit('update:modelValue', newValue)
  emit('change', newValue)
}, { deep: true })

// watch(() => props.modelValue, (newValue: any, oldVal) => {
//   if (updateEmited === true) {
//     updateEmited = false
//   }
//   else {
//     updateFromModelValue(newValue)
//     propsModelValueChanged = true
//   }
// },
// )

// watch(_enderecos.value, debounce((newValue, oldVal) => {
//   if (propsModelValueChanged === true) {
//     // se o endereco mudou por conta do props, faco nada
//     propsModelValueChanged = false
//   }
//   else {
//     // // remove the id if is a new address
//     let returnVal: Endereco | Endereco[] = [...newValue].map((v) => {
//       const val = { ...v }
//       if (!val?.co_seq_endereco)
//         delete val?.co_seq_endereco
//       return val
//     })
//     if (props.multiple === false)
//       returnVal = returnVal[0]

//     emit('update:modelValue', returnVal)
//     emit('change', returnVal)
//     updateEmited = true
//   }
// }, 400))

// updateFromModelValue(props.modelValue)
</script>

<template>
  <field-template :required="required" :error="error" :hint="hint">
    <template #label>
      <slot name="label">
        <span v-if="label" v-html="label"></span>
      </slot>
    </template>
    <template #hint>
      <slot name="hint">
        <span v-if="hint" v-html="hint"></span>
      </slot>
    </template>

    <div class=" pb-1 mb-1 endereco-wrapper">
      <template v-for="(value, index) in _value">
        <div class="model-form-wrapper fields-as-form mb-2" :class="{ modal: props.modal }">
          <AutoForm
            :id="_modelId"
            :modal="modal"
            :auto-fields="props.autoFields"
            :fields="modelFields"
            :fields-erros="fieldErrors"
            :value="_value[index]"
            :is-fetching="isFetching"
            @update:model-value="(v) => _value[index] = v"
            @submit="saveForm"
            @closed="() => emit('closed')"
          >
            <!-- <template #intro>
          <alert
            v-if="hasError"
            error
            close-button
            class="mb-4"
          >
            {{ message || 'Ocorreu algum erro!' }}
            <small v-if="fieldErrors">
              <details>
                <summary>Detalhes</summary>
                <li v-for="(v, k) in fieldErrors">
                  <b> {{ v }}</b> {{ k }}
                </li>
              </details>
            </small>
          </alert>
        </template> -->
            <template #default="{ values, fields, fieldsProps, fieldsComp }">
              <slot
                :values="values"
                :fields-props="fieldsProps"
                :fields="fields"
                :fields-comp="fieldsComp"
                :is-new="isNew"
              />
            </template>
            <template #actions>
              <div class="text-right">
                <button
                  v-if="multiple"
                  class="btn px-0 mx-0 py-0 my-0 remove-endereco"
                  type="button"
                  @click="remove(index)"
                >
                  <icon icon="trash" scale="1.6" />
                </button>
              </div>
            </template>
          </AutoForm>
        </div>
      </template>
      <button
        v-if="multiple"
        class="btn btn-small mt-2 add-endereco bg-gray"
        type="button"
        @click="addNew"
      >
        <b>+ Adicionar</b>
      </button>
    </div>
  </field-template>

  <!-- <pre>{{ _value }}</pre> -->

  <!-- <div>
    <template v-for="(endereco, enderecoIndex) in _enderecos">
      <div v-if="true" class="fields-as-form pb-1 mb-1 endereco-wrapper">
        <div class="cep-wrap">
          <field-text
            v-model="endereco.co_cep"
            label="CEP"
            name="co_cep"
            :required="required"
            mask="00000-000"
            @input="(e) => buscaCEP(endereco)"
          />
          <button id="busca-cep" type="button" @click="e => buscaCEP(endereco, e)">
            Buscar
          </button>
        </div>
        <field-select
          v-model="endereco.tp_endereco"
          label="Tipo"
          name="tp_endereco"
          :required="required"
          :options="tipoOptions"
        />
        <field-text
          v-model="endereco.no_logradouro"
          name="no_logradouro"
          label="Logradouro"
          :required="required"
        />
        <field-text
          v-model="endereco.no_bairro"
          name="no_bairro"
          label="Bairro"
          :required="required"
        />
        <div class="flex-cols">
          <field-text
            v-model="endereco.no_numero"
            name="no_numero"
            label="Número"
            :required="required"
          />
          <field-text v-model="endereco.ds_complemento" name="ds_complemento" label="Complemento" />
        </div>
        <div class="flex-cols">
          <field-text
            v-model="endereco.no_municipio"
            name="no_municipio"
            label="Município"
            :required="required"
          />
          <field-select
            v-model="endereco.sg_uf"
            name="sg_uf"
            :options="listaUfs"
            class="w-10"
            label="UF"
            :required="required"
          />
        </div>
        <div class="text-right">
          <button
            v-if="multiple"
            class="btn px-0 mx-0 py-0 my-0 remove-endereco"
            type="button"
            @click="removeEndereco(enderecoIndex)"
          >
            <icon icon="trash" scale="1.6" />
          </button>
        </div>
      </div>
    </template>
    <div class="text-right">
      <button
        v-if="multiple"
        class="btn btn-small mt-2 add-endereco bg-gray"
        type="button"
        @click="addNewEndereco"
      >
        <b>+ Adicionar Endereço</b>
      </button>
    </div>
  </div> -->
</template>

<style lang="scss" scoped>
    button {
      background-color: #33333330;
      color: black;
    }.add-endereco {
      background: var(--color-primary);
    }.remove-endereco {
      background: rgba(255, 0, 0, 0) !important;
      color: rgba(134, 15, 15, 0.7) !important;
      margin: 0.5em 0 0 0;
      padding: 0;&:hover {
        color: rgba(255, 0, 0, 1) !important;
      }
    }.cep-wrap {
      position: relative;#busca-cep {
        position: absolute;
        right: 10px;
        top: 3px;
        color: black;
        background-color: #33333330;
        padding: 0.4em 0.7em;
        border-radius: 0.5em;
        font-size: 10px;
        opacity: 0.6;
      }
    }
</style>
