export default {
  fake: false,
  endpoint: '/{idclientevallen}/conta',
  //   endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Conta',
    plural: 'Contas',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: false,
    },
    {
      key: 'co_fornecedor',
      label: 'Fornecedor',
      type: 'model',
      modelName: 'pessoajuridica',
      default: true,
      required: true,
      displayLabel: true,
    },
    {
      key: 'co_cliente',
      label: 'Usuário',
      type: 'model',
      hidden: false,
      modelName: 'usuario',
      displayLabel: true,
      required: true,
      search: true,
    },
    {
      key: 'ds_descricao',
      label: 'Descrição',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_categoria',
      label: 'Categoria',
      type: 'model',
      modelName: 'categoria-conta',
      required: true,
    },
    {
      key: 'no_portador',
      label: 'Nome Portador',
      type: 'text',
      required: false,
      search: true,
      displayLabel: true,
    },
    {
      key: 'nu_documento',
      label: 'Nº Documento',
      type: 'text',
      required: false,
      search: true,
      displayLabel: true,
    },

  ],
} satisfies ModelT

