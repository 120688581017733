export default {
  fake: false,
  endpoint: 'async/{idclientevallen}/formaspagamento',
  //   endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Forma de Pagamento',
    plural: 'Formas de Pagamento',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: false,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'ds_descricao',
      label: 'Nome',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },

  ],
} satisfies ModelT

