import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: 'sync/{idclientevallen}/itemtabela/{co_tabela}',
  endpointOptions: {
    find: { method: 'get', url: 'sync/{idclientevallen}/itemtabela/{co_tabela}' },
  },
  displayName: {
    singular: 'Valor Exame',
    plural: 'Valores dos Exames',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'co_exame',
      label: 'Exame',
      type: 'model',
      model: 'procedimentos',
      required: true,
      search: true,
      displayLabel: true,
      multiple: false,
    },
    {
      key: 'vl_exame',
      type: 'number',
      mask: { mask: Number, scale: 2 },
    },
    {
      key: 'co_tabela',
      label: 'Tabela de Preços',
      type: 'model',
      model: 'tabelaprecos',
      multiple: false,
      displayLabel: false,
      hidden: true,
    },
  ],
} as Model
