<script setup>
import { onMounted, reactive, ref, toRef, toRefs, watch } from 'vue'
import AutoForm from '@comp/forms/AutoForm.vue'
import ModelForm from '../modelForm.vue'
import RecursosAssociados from './recursos-associados.vue'

const props = defineProps(['modelValue', 'modelId', 'value'])
const emit = defineEmits(['saved', 'submit', 'close', 'cancel'])
const initialData = toRef(props, 'modelValue')

const _examesTabela = ref([{}, {}])

const data = reactive({
  error: null,
  withResponsavel: false,
})

if (props.modelValue?.no_responsavel)
  data.withResponsavel = true

watch(() => props.modelValue, (v) => {
  if (v.modelValue?.no_responsavel)
    data.withResponsavel = true
})
</script>

<template>
  <div class="container mx-auto">
    <div class="flex flex-col gap-5">
      <div class="w-12/12">
        <ModelForm
          v-slot="{ values, fields, fieldsProps, fieldsComp }"
          v-model="initialData"
          :model-id="modelId"
          :auto-fields="false"
          model-name="perfil"
          :value="value"
          @saved="res => emit('saved', res)"
        >
          <component :is="fieldsComp.no_perfil" />
          <component :is="fieldsComp.sg_perfil" />

          <field-template label="Recursos" class="mt-4">
            <RecursosAssociados v-model="values.recursos" />
          </field-template>
          <!-- <ExamesValoresTable :tabela-valores-id="modelId" /> -->
        </ModelForm>
      </div>

      <!-- <div class="grow w-12/12 mt-3">
        <h6 class="mb-2">
          <b>Exames</b>
        </h6>

        <table class="table">
          <thead>
            <tr>
              <th class="w-9/12">
                Exame
              </th>
              <th>Valor</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <field-model model-name="procedimentos"></field-model>
              </td>
              <td><field-text :mask="{ mask: Number, scale: 2 }" /></td>
              <td></td>
            </tr>

            <tr>
              <td colspan="3">
                <div class="py-0 px-0 ">
                  <q-btn
                    unelevated
                    class="add-exame-btn font-bold"
                    rounded
                    label="Adicionar"
                    icon="add"
                    @click="AddExame"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       -->
    </div>
  </div>
</template>

<style lang="scss" scoped>    .table {
      width: 100%;
      table-layout: fixed;
      font-size: 0.85em;
      vertical-align: middle;

      th {
        padding: 0.8em;
        vertical-align: middle;
      }
      td {
        padding: 0;
        vertical-align: middle;
      }

      tr {
        &:nth-child(odd) {
          .col-number {
            background: var(--color-primary-10);
          }
        }&:nth-child(even) {
          .col-number {
            background: var(--color-primary-20);
          }
        }
      }

      td {
        background-color: white;& > div {
          display: flex;
          align-items: center;
          flex-direction: row;
          min-height: 100%;
        }.form-field {
          min-height: 100%;
          flex-grow: 1;
        }
      }.add-exame-btn {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 0;
        width: 100%;
        padding: 1em;
        border: 2px dashed rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.5);
        font-weight: 700;
        transition: all 400ms;
        * {
          font-weight: 700;
        }&:hover {
          background: #939CDD88;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
</style>
