<script setup>
import { useAttrs, mergeProps } from "vue";
import { useFieldInput, propsDef, emitDef  } from "./useFieldInput";
import fieldTemplate from "./field-template.vue";
const emit = defineEmits([...emitDef]);

const props = defineProps({
  ...propsDef,
  scale: {
    default: 5,
  },
  radix: {
    default: ",",
  },
  thousandsSeparator: {
    default: ",",
  },
  min: {
    default:undefined,
  },
  max: {
    default: undefined,
  },
});

// const maskTemp = {
//     mask: Number,
//     scale: props.scale,
//     radix: props.radix,
//     mapToRadix: ['.',','], 
//     thousandsSeparator: ' ',
//     min: props.min,
//     max: props.max,
// }

// const { fieldComponent } = useFieldInput({
//   props: mergeProps(props,{type:'number',mask:null}),
//   emit,
//   attrs: useAttrs(),
// });


const onInput = event => {
        if (!props.mask) {
            emit('update:modelValue', event.target.value);
            emit('update:value', event.target.value);
        }
    }

</script>

<template>
  <field-template :required="required" :error="error" :hint="hint">
    <template #label>
      <slot name="label"><span v-if="label" v-html="label"></span></slot>
    </template>
    <template #hint>
      <slot name="hint"><span v-if="hint" v-html="hint"></span></slot>
    </template>
    <input
      type="date"
      :required="required"
      :name="name"
      :value="props.modelValue"
      @input="onInput"
      v-bind="{...$attrs}"
      :min="props.min"
      :max="props.max"
      />
    </field-template>
  </template>
