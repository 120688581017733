<script setup lang="ts">
import type { PropType } from 'vue'
import { mergeProps, ref, useAttrs } from 'vue'
import { type Delta, QuillEditor, type Sources } from '@vueup/vue-quill'
import { emitDef, propsDef, useFieldInput } from './useFieldInput'
import fieldTemplate from './field-template.vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

const props = defineProps({
  ...propsDef,
  modelValue: {
    type: String,
  },
  /** html | text */
  contentType: {
    type: String as PropType<'html' | 'text'>,
    default: 'html',
  },
  /** TODO */
  minRows: {
    type: Number,
    default: 5,
  },
})

const emit = defineEmits([...emitDef])
const _value = ref(props.modelValue)

watch(() => props.modelValue,
  (newValue, oldValue) => {
    if (newValue !== quill?.value?.getHTML())
      _value.value = newValue
  },
)

watch(_value, (newValue, oldValue) => {
  if (newValue !== oldValue)
    emit('update:modelValue', newValue)
})

const quill = ref(null)

const onQuillInput = (opt: { delta: Delta; oldContents: Delta; source: Sources }) => {
  const html = quill?.value?.getHTML()
  emit('update:modelValue', html)
  emit('update:value', html)
}
</script>

<template>
  <field-template :required="required" :error="error" :hint="hint">
    <template #label>
      <slot name="label">
        <span v-if="label" v-html="label"></span>
      </slot>
    </template>
    <template #hint>
      <slot name="hint">
        <span v-if="hint" v-html="hint"></span>
      </slot>
    </template>

    <QuillEditor
      v-if="props.contentType === 'html'"
      ref="quill"
      theme="snow"
      :content="_value"
      :content-type="props.contentType"
      style="min-height:15em;background:white"
      @text-change="onQuillInput"
    />

    <textarea
      v-else
      v-model="_value"
      :required="required"
      :name="name"
      :rows="props.minRows"
      style="width: 100%;"
    ></textarea>

    <!-- <pre>{{ props }}</pre> -->
  </field-template>
</template>

<style lang="scss">
  .ql-editor{
    background-color:white;
    font-size: 14px;
    border:none;
  }

  // .ql-toolbar.ql-snow{
  //   border:none;
  //   margin:0;
  //   padding:0;
  //   opacity: 0.5;
  //   transition: all 0.2s ease;
  //   &:hover{
  //     opacity: 1;
  //   }
  // }
  // .ql-container.ql-snow{
  //   border:none;
  // }
</style>
