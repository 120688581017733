import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const uiStore = defineStore({
  id: 'ui',
  state: () => (useStorage('ui', {
    drawer: false,
  })),
  actions: {
  },
})
