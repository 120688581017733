<script setup lang="ts">
import { computed, isProxy, nextTick, onBeforeMount, onMounted, ref, toRef, useAttrs, useSlots, watch } from 'vue'
import { computedWithControl, refDebounced, resolveUnref, watchTriggerable } from '@vueuse/core'
import equal from 'fast-deep-equal'
import FieldDropdown from './field-dropdown.vue'
import AutoModelForm from '@/models/autoModelForm.vue'
// define model
import { useModel } from '@/models/useModelComposable'

const props = defineProps({
  modelName: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object],
  },
  displayField: [String, Number],
  label: {
    type: String,
  },
  multiple: {
    default: false,
  },
  max: {
    default: 50,
  },
  min: {
    default: 1,
  },
  name: {
    type: String,
  },
  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  value: String,
  showCloseButton: {
    default: true,
  },
  showEdit: {
    default: true,
  },
  showAdd: {
    default: true,
  },
  cellStyle: {
    default: false,
  },
  /** Define the vmodel/change emit. auto will emit as the modelValue format */
  emitFullObject: {
    type: [String, Boolean],
    default: 'auto',
  },
  /** An default filter to be applyed */
  defaultFilter: {
    type: Object,
  },
})

const emit = defineEmits(['update:modelValue', 'update:value', 'change', 'changeRawValue'])

const attrs = useAttrs()

const slots = useSlots()

/** Contain All the items that was loaded, so we can get anothe pages and not lose the alreat loades */
const _itemAllLoadedMap = ref<Map<string | number, object>>(new Map())
/** All the selected Filtered Items items */
const _itemsAllFilteredMap = ref<Map<string | number, object>>(new Map())
/** Contains the current selected Items */
const _itemsCurrentSelected = ref([])
/** ref of props.modelName' */
const p_modelName = toRef(props, 'modelName')
/** ref of props.modelValue' */
const p_modelValue = toRef(props, 'modelValue')

const searchWord = ref('')

const {
  isFetching,
  hasError,
  message,
  item,
  items,
  loadItem,
  saveItem,
  model,
  page,
  totalItems,
  itemsStore,
} = useModel(toRef(props, 'modelName'), { limit: props.max, filters: props.defaultFilter })

const {
  isFetching: isFetchingForFiltered,
  page: pageForFiltered,
  itemsStore: itemsForFiltered,
  // filters,
  filterWord,
} = useModel(toRef(props, 'modelName'), { limit: 20, filters: props.defaultFilter })

//  Get the Model Fields to Display  ---------------------------------

const fieldsToDisplay = ref<FieldT[] | null>([null])
const labelField = ref<string>('id') // id is just placeholder

watchEffect(() => {
  if (model.value) {
    fieldsToDisplay.value = model.value.getFieldsForView('list')// ?.getFieldsToDisplay() // talvez colocar no getFieldsForView
    labelField.value = fieldsToDisplay.value?.[0]?.key ?? 'id'
  }
})

// V-Model Funcs
// ----------------------

/** have a RAW value..... just the id, even if it receives an object. Removes any reactivet too */
const _modelValueRaw = ref(null)
/** define raw value */
watch(p_modelValue, (val, oldVal) => {
  if (equal(val, oldVal))
    return

  const v = val ? JSON.parse(JSON.stringify(resolveUnref(val))) : null

  addToAllItems(v, _itemAllLoadedMap) // if we receive a object, we collect it
  let value
  if (Array.isArray(v))
    value = v.map(vv => model.value?.getId(vv) ?? vv)
  else
    value = model.value?.getId(v) ?? v
  const fvalue = JSON.parse(JSON.stringify(value))
  _modelValueRaw.value = fvalue
}, { immediate: true })

// /** if we received the full object or just the id */
const isToEmmitFullObject = computed(() => {
  if (props.emitFullObject !== 'auto')
    return props.emitFullObject
  else
    return !!p_modelValue?.value?.[model.value?.getIdKey()]
})

/** EMMIT changes when value change */
watchTriggerable(_modelValueRaw, (value, oldVal) => {
  if (!isToEmmitFullObject.value) {
    emit('update:modelValue', value)
    emit('update:value', value)
    emit('change', value)
  }
  else {
    if (Array.isArray(value)) {
      const items = value.map(v => _itemAllLoadedMap.value.get(v))
      emit('update:modelValue', items)
      emit('update:value', items)
      emit('change', items)
    }
    else {
      const item = _itemAllLoadedMap.value.get(value)
      emit('update:modelValue', item)
      emit('update:value', item)
      emit('change', item)
    }
  }
  if (value !== oldVal)
    emit('changeRawValue', value)
})// .trigger()

// Model Functions
// -----------------
// TODO - acho que isso não funciona, estao funcionando no de cima
watch(_itemsCurrentSelected, (value, oldVal) => {
  if (isToEmmitFullObject.value) {
    emit('update:modelValue', value)
    emit('update:value', value)
    emit('change', value)
  }
})

/** every time a raw value change, we get the item(s) */
const getSelectedItemsWatcher = watchTriggerable(_modelValueRaw, async (valueIDs, oldValsId) => {
  if (equal(valueIDs, oldValsId))
    return
  // load the item of any model value, if it not already loaded
  if (valueIDs) {
    const idsToLoad: Array<string | number> = []
    const idsToLoop = Array.isArray(valueIDs) ? valueIDs : [valueIDs]
    idsToLoop.forEach((id) => {
      if (_itemAllLoadedMap.value.has(id) === false)
        idsToLoad.push(id)
    })

    if (idsToLoad.length > 0) {
      const id = idsToLoad.length === 1 ? idsToLoad[0] : idsToLoad
      await loadItem(id)
    }
  }
})
getSelectedItemsWatcher.trigger()

// // FIXME - quando procuro e aperto esc, se perde o filtro, verificar no qselect
const isFiltered = ref(false)
let loadedFirsTime = false

const onDropdownFilter = async (val, update, abort) => {
  // load items when first opened
  if (!loadedFirsTime) {
    await loadItem()
    loadedFirsTime = true
  }
  const needle = val?.trim() ?? ''

  // if (needle !== filters.value)
  //   _itemsAllFilteredMap.value.clear()

  // if (needle)
  //   filters.value = needle
  // else
  //   filters.value = null

  update(() => {
    searchWord.value = needle
    // if (_itemAllLo/** Only the filtered items */aded.value)
    // _itemsFiltered.value = [..._itemAllLoadedMap.value]
  }, (ref) => {
  })
}
/** when the filter value change, we mark it */
let filterDirty = false
watch(searchWord, (val, oldVal) => {
  if (val) // quando nao tem valor, eu nao mudo para não carregar uma lista com os filtros em branco
    filterWord.value = val
})
watch(filterWord, (val, oldVal) => {
  // if words are complete different, we clear the cache, if not we mantian it the menu result wont be blank when searching
  // eslint-disable-next-line unicorn/prefer-includes
  if ((val > oldVal && val.indexOf(oldVal) === -1) || (val < oldVal && oldVal.indexOf(val) === -1)) {
    _itemsAllFilteredMap.value.clear()
    pageForFiltered.value = 1
  }
  filterDirty = true
})

const onDropdownScroll = async (details) => {
  if (details.index == details.to && details.direction === 'increase' && details.to > 0) {
    if (filterWord.value) {
      pageForFiltered.value++
      console.log('change page for filtered', pageForFiltered.value)
    }
    else {
      page.value++
      console.log('change page for all', page.value)
    }
  }
}

/**
 * Add any item to the all items collection. It cam be from received modelValue, loaded object etc
 * @param {any} theItems
 * @returns {any}
 */
function addToAllItems(theItems, mapCollection, clean) {
  const finalItems = Array.isArray(theItems) ? theItems : [theItems]
  if (clean) {
    mapCollection.value.clear()
    filterDirty = false
  }
  finalItems?.forEach((item) => {
    const id = model.value?.getId(item)
    if (!id)
      return
    // if (mapCollection.value.has(id)) // update too
    mapCollection.value.set(id, item)
  })
}
// Everytime load items, we put where it needs to be
watch(items, (val, oldval) => {
  const theItems: Array<unknown> | null = val
  addToAllItems(theItems, _itemAllLoadedMap, false)
  // alway clone the _itemsAllFilteredMap too, so when we search, dont be blank
  addToAllItems(theItems, _itemsAllFilteredMap, false)
})

watch(itemsForFiltered, (val, oldval) => {
  const theItems: Array<unknown> | null = JSON.parse(JSON.stringify(val))

  // console.log('****currentFilter', filters.value)
  // console.log('filter items received')
  // console.table(theItems)

  // console.log('OLD filter items received')
  // console.table(oldval)

  if (equal(val, oldval)) {
    console.log('****ITEMS ARE EQUAL, VERIFY WHAT TRIGGERS IT')
    return
  }

  addToAllItems(theItems, _itemsAllFilteredMap, filterDirty)
})

// // Adding New Functions
// // --------------------------------------

const newModelValues = ref({})

const showModelPopupForm = ref(false)
const popupModelId = ref(null)
const addNew = function (val) {
  if (val)
    newModelValues.value[labelField.value] = val

  showModelPopupForm.value = true
  popupModelId.value = null
}

const editCurrentItem = function (itemId) {
  showModelPopupForm.value = true
  popupModelId.value = itemId
}

const closed = () => {
  showModelPopupForm.value = false
}

const saved = async (response) => {
  const newModelId = model.value?.getId(response)
  showModelPopupForm.value = false
  if (newModelId) {
    if (props.multiple && !_modelValueRaw.value.includes(newModelId))
      _modelValueRaw.value.push(newModelId)
    else if (!props.multiple)
      _modelValueRaw.value = newModelId
  }
}

const currentItemMap = computed(() => {
  let itemsToshow
  if (searchWord.value)
    itemsToshow = _itemsAllFilteredMap.value

  else
    itemsToshow = _itemAllLoadedMap.value

  return itemsToshow
})

const optionsForDropdown = computed(() => {
  const optionsAsArray = Array.from(currentItemMap.value, ([key, objValue]) => ({ value: key, label: objValue?.[labelField.value] }))
  return optionsAsArray
})
</script>

<template>
  <div>
    <FieldDropdown
      v-bind="props"
      v-model="_modelValueRaw"
      :class="[attrs.class]"
      :options="optionsForDropdown"
      label-key="label"
      value-key="value"
      :search-fn="onDropdownFilter"
      :scroll-fn="onDropdownScroll"
      :add-new-fn="props.showAdd ? addNew : null"
      :show-loading="isFetching || isFetchingForFiltered"
      :multiple="props.multiple"
      :emit-full-object="false"
      :error="hasError ? message : null"
      hint-type="text"
    >
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            No results
          </q-item-section>
        </q-item>
      </template>
      <!-- <FieldDropdown
    v-bind="props"
    v-model="_modelValueRaw"
    :options="optionsForDropdown"
    :label-key="fieldToDisplay"
    :value-key="model.value.getModelIdKey()"
    :search-fn="onDropdownFilter"
    :add-new-fn="props.showAdd ? addNew : null"
    :show-loading="isFetchingItems"
    :multiple="props.multiple"
    :emit-full-object="false"
  > -->
      <template #selected-item="{ item }">
        <slot name="selected-item" :item="Array.isArray(item) ? item.map(i => currentItemMap.get(i?.value)) : currentItemMap.get(item?.value)">
        </slot>
      </template>

      <!-- custom option for model -->
      <template #option="{ option }">
        <q-item-section class="py-2">
          <q-item-label
            v-for="(field, i) in fieldsToDisplay"
            :key="field.value"
            :lines="1"
            class="my-0 py-0"
            style="margin-top: 0 !important; line-height: 0.9;"
          >
            <span v-if="i === 0"> {{ currentItemMap.get(option.value)?.[field.key] }}</span>
            <span v-else class="text-xs uppercase line-height-1 pl-1"><small> {{ field?.label }} - {{ currentItemMap.get(option.value)?.[field?.key] }}</small></span>
          </q-item-label>
        </q-item-section>
      </template>
      <!-- <template #option="scope">
      <pre>{{ scope }}</pre>
      <q-item v-bind="scope.itemProps">
        <slot
          name="option"
          v-bind="scope"
          :option="scope.opt"
        >
          <q-item-section>
            <q-item-label>{{ scope.opt?.[fieldToDisplay] }}</q-item-label>

            <q-item-label
              v-for="subField in subFieldsToDisplay"
              :key="subField.value"
              :lines="1"
              caption
              class="text-xs uppercase line-height-1"
            >
              <small>{{ subField?.label }} <span>{{ scope.opt?.[subField.value] }}</span></small>
            </q-item-label>
          </q-item-section>
        </slot>
      </q-item>
    </template> -->

      <template #edit="{ modelValueRaw }">
        <div v-if="modelValueRaw && showEdit && !props.multiple" class="pointer" @click.stop.prevent="() => editCurrentItem(modelValueRaw)">
          <q-icon name="edit" size="0.6em" />
        </div>
      </template>
    </FieldDropdown>

    <AutoModelForm
      v-if="showModelPopupForm"
      :model-name="p_modelName"
      :model-id="popupModelId"
      :modal="true"
      :value="newModelValues"
      @saved="saved"
      @closed="closed"
    />

    <!-- <div class="grid grid-cols-2">
      <div>
        itemsNormal
        <pre>{{ _itemAllLoadedMap }}</pre>
      </div>
      <div>
        itemsFiltered
        <pre>{{ _itemsAllFilteredMap }}</pre>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss">

</style>
