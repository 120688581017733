export default {
  fake: false,
  endpoint: '/sync/{idclientevallen}/itemorcamento',
  displayName: {
    singular: 'Item Orcamento',
    plural: 'Itens Orcamento',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
    },
    {
      key: 'co_requisitante',
      label: 'Medico Requisitante',
      type: 'model',
      modelName: 'profissional',
      displayLabel: true,
    },
    {
      key: 'co_tabela',
      label: 'Tabela de Valores',
      type: 'model',
      modelName: 'tabelaprecos',
      displayLabel: true,
    },
    {
      key: 'co_exame_convenio',
      label: 'Exame valor',
      type: 'model',
      modelName: 'exame_valor',
      displayLabel: true,
    },

    {
      key: 'qt_procedimento_executar',
      label: 'Usuário',
      type: 'number',
    },
    {
      key: 'st_coleta',
      label: 'Coletado',
      type: 'boolean',
      default: false,
    },

  ],

} satisfies ModelT

