import UF from '../constants/uf'

export default {
  fake: false,
  displayName: {
    singular: 'Teste Modelo',
    plural: 'testes Modelos',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      hidden: true,
    },
    {
      key: 'nome',
      label: 'Nome',
      type: 'text',
      required: true,
    },
    {
      key: 'imagem',
      label: 'Imagem',
      type: 'file',
      required: true,
    },
    {
      key: 'telefone',
      label: 'Telefone',
      type: 'text',
      mask: '(00) 0000-0000',

    },
  ],
} satisfies ModelT
