export const getType = (obj) => {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
export const isObject = (obj) => {
  return (getType(obj) === 'object')
}
export const isArray = (obj) => {
  return (getType(obj) === 'array')
}
export const isString = (obj) => {
  return (getType(obj) === 'string')
}
export const isNumber = (obj) => {
  return (getType(obj) === 'number')
}
export const isFunction = (obj) => {
  return (getType(obj) === 'function')
}

export default getType

