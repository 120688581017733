export default {
  fake: false,
  endpoint: '{idclientevallen}/categoriaconta',
  //   endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Categoria da Conta',
    plural: 'Categorias de Contas ',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: false,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'st_desmembra',
      label: 'Desmembra',
      type: 'toggle',
      hint: 'Indica se a conta categorizada pode ser desmembrada',
      default: true,
      required: true,
    },
    {
      key: 'ds_descricao',
      label: 'Descrição',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'tp_categoria',
      label: 'Tipo de Categoria',
      type: 'select',
      options: [
        'RECEITA',
        'DESPESA',
      ],
      required: true,
      search: true,
      displayLabel: true,
    },

  ],
} satisfies ModelT

