<script setup>
import { useAttrs } from 'vue'
import { emitDef, propsDef, useFieldInput } from './useFieldInput'

const props = defineProps({
  ...propsDef,
})

const emit = defineEmits(
  [...emitDef],
)

const { fieldComponent } = useFieldInput({ props, emit, attrs: useAttrs() })
</script>

<template>
  <fieldComponent v-bind="props" />
</template>

