<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'

const props = defineProps({
  label: String,
  labelInside: {
    default: false,
  },
  hint: String,
  hintType: {
    type: String as PropType<'info' | 'text'>,
    default: 'info',
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  cellStyle: {
    default: false,
  },
})

const errorDiv = ref(null)
const { width, height } = useElementSize(errorDiv)

const errorIsTruncated = ref(false)
// TODO - remover o watch no umount

const wa = watch(width, (e) => {
  errorIsTruncated.value = errorDiv?.value?.offsetWidth < errorDiv?.value?.scrollWidth
})
onBeforeUnmount(() => {
  wa()
})
</script>

<template>
  <div class="form-field" :class="{ 'field-error': error, 'form-field-cell': props.cellStyle }">
    <div v-if="!props.cellStyle && !props.labelInside" class="form-label">
      <slot name="label">
        <span v-if="label" v-html="label" />
      </slot>
      <span v-if="required" class="req">*</span>

      <q-icon
        v-if="props.hint && props.hintType === 'info'"
        v-tooltip="props.hint"
        name="info_outline"
        class="opacity-40 ml-2"
      ></q-icon>
      <slot name="label-append" />
    </div>
    <div class="form-input" :class="{ 'label-inside': props.labelInside }">
      <label v-if="props.labelInside" class="form-label">
        <span v-if="label" v-html="label" />
      </label>
      <div>
        <slot>
        <!-- <input type="text"> -->
        </slot>
      </div>
    </div>
    <div v-if="!props.cellStyle" class="form-bottom-wrapper">
      <div class="form-hint">
        <slot v-if="props.hintType !== 'info'" name="hint">
          <span v-if="hint">{{ hint }}</span>
        </slot>
      </div>

      <div v-if="error" ref="errorDiv" class="form-error">
        <!-- <div v-if="errorIsTruncated" v-tooltip="error" :style="{ position: 'absolute', right: '10px' }">
          <icon icon="more" scale="1.2" />
        </div> -->
        {{ error }}
      </div>
    </div>
  </div>
</template>
