<script setup>
  import { useAttrs } from "vue";
  import { useFieldInput, propsDef, emitDef } from './useFieldInput'
  
  
  const emit = defineEmits(
    [...emitDef]
  );
  
  const props = defineProps({
    ...propsDef
  });
  
  const { fieldComponent } = useFieldInput({ props, emit, attrs:useAttrs(), customProps:{}})
  
  </script>
  
  <template>
    <fieldComponent />
  </template>
  