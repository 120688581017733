import type { Model } from '@/models/modelsDef'

const opcoesTipoDocumento = 'AVISO, BOLETIM, CARTA, CERTIDÃO, CIRCULAR, COMPROVANTE, CONTRATO, CONVÊNIO, DECRETO, DESPACHO, EDITAL, FAX, GUIA, GUIA DE REMESSA, INSTRUÇÃO NORMATIVA, MEMORANDO, MENSAGEM, NOTA FISCAL, NOTA FISCAL ELETRÔNICA, OFÍCIO, ORDEM DE SERVIÇO, PARECER, PORTARIA, REQUERIMENTO, REQUISIÇÃO, RESOLUÇÃO'.split(', ')

export default {
  fake: false,
  endpoint: '/async/{idclientevallen}/movimentacoes',
  displayName: {
    singular: 'Movimentação',
    plural: 'Movimentações',
  },
  idKey: 'id',
  icon: 'fork_right',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'co_tp_movimentacao',
      label: 'Tipo Movimentação',
      type: 'model',
      modelName: 'tipo-movimentacao',
      required: true,
    },
    {
      key: 'dt_documento',
      label: 'Data do Documento',
      type: 'date',
      required: true,
    },
    {
      key: 'tp_documento',
      label: 'Tipo do Documento',
      type: 'select',
      options: opcoesTipoDocumento,
      required: true,
    },
    {
      key: 'nu_documento',
      label: 'Número do Documento',
      type: 'text',
      default: null,
      hint: 'Identificador do documento que originou a movimentação (pode ser o número da NF, de uma requisição, etc..)',
      required: true,
    },
    {
      key: 'produtos_movimentacao',
      label: 'Produtos',
      type: 'collection',
      modelName: 'produto-movimentacao',
      required: true,
      props: {
        multiple: true,
      },
    },

    // {
    //   key: 'st_movimentacao',
    //   label: 'Status da Movimentação',
    //   type: 'select',
    //   options: ['INICIAL', 'FINALIZADA', 'ESTORNADA'],
    // },
    {
      key: 'ds_observacao',
      label: 'Observação',
      type: 'textarea',
    },

  ],
  table: {
    cols: [{ key: 'tipo', label: 'Tipo', renderFn: item => item.tipomovimentacao?.tp_operacao }, 'nu_documento', 'dt_documento', 'ds_observacao'],
  },
} satisfies ModelT
