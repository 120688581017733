<script setup>
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue'
import fieldTemplate from './field-template.vue'
import { useOptions } from './useOptions.js'

const props = defineProps({
  label: {
    type: String,
  },
  name: {
    type: String,
  },
  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  modelValue: {
    type: String,
  },
  value: String,
  options: {
    type: Array,
    default: () => [
      { label: 'label1', value: 'value1' },
      { name: 'label2', value: 'value2' },
      'label3',
    ],
  },
})

const emit = defineEmits(['update:modelValue', 'update:value'])

const _options = useOptions(props.options)
// computed(() => {
//   let options = [];
//   if (Symbol.iterator in Object(props.options)) {
//   for (const option of props.options) {
//     const optionType = option?.constructor?.name?.toLocaleLowerCase();
//     let label,value;
//     if (optionType === "object") {
//       label = (option?.label ?? option?.name) ?? ''
//       value = option?.value ?? label
//     } else if (optionType === "string" || optionType === "number") {
//       label = option
//       value = option
//     }
//     options.push({label,value})
//   }

//   }
//   return options;
// });

const internalModelValue = ref()
const refModelValue = toRef(props, 'modelValue')

watch(internalModelValue, (v) => {
  if (typeof v === 'string')
    internalModelValue.value = v.trim()
})

onMounted(() => {
  internalModelValue.value = props.modelValue
})
watch(internalModelValue, (v) => {
  emit('update:modelValue', v)
  emit('update:value', v)
})
watch(refModelValue, (v) => {
  internalModelValue.value = v
})
</script>

<template>
  <field-template :required="required" :error="error" :hint="hint">
    <template #label>
      <slot name="label">
        <span v-if="label" v-html="label" />
      </slot>
    </template>
    <template #hint>
      <slot name="hint">
        <span v-if="hint" v-html="hint" />
      </slot>
    </template>

    <div class="gap-4 flex items-center">
      <div v-for="option in _options" :key="option?.value">
        <label>
          <input
            v-model="internalModelValue"
            type="radio"
            :value="option.value"
            :name="name"
            :required="required"
          >
          {{ option.label }}</label>
      </div>
    </div>
  </field-template>
</template>
