<!-- eslint-disable import/first -->
<script lang="ts">
/* eslint-disable */
// use normal <script> to declare options
export default {
  inheritAttrs: false,
}
</script>


<script setup lang="ts">
import { computed, onBeforeMount, onUnmounted,onBeforeUnmount, onMounted, ref, useAttrs, watch, watchEffect } from 'vue'

interface Props {
  /** css width format */
  width?: string
  /** css height format */
  height?: string
  modelValue?: boolean
  open?: boolean
  closeIcon?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  width: 'auto',
  height: 'auto',
  open: true,
  closeIcon: true,
})
const emit = defineEmits(['opened', 'closed', 'update:modelValue', 'update:open'])

const attrs = useAttrs()

const _isOpen = ref(false)
if ((props.open && props.modelValue !== false) || props.modelValue === true)
  _isOpen.value = true

watchEffect(() => {
  const val = props.open
  if (val)
    _isOpen.value = true
  else
    _isOpen.value = false
})
watch(() => props.modelValue, (val) => {
  if (val)
    _isOpen.value = true
  else
    _isOpen.value = false
})

watch(_isOpen, (v) => {
  if (v)
    emit('opened')
  else
    emit('closed')

  emit('update:modelValue', v)
  emit('update:open', v)
})

const open = function () {
  _isOpen.value = true
}
const close = function () {
  _isOpen.value = false
}

const detectKey = (e) => {
  if (e.key === 'Escape')
    close()
}

onMounted(() => {
  window.addEventListener('keydown', detectKey)
})
onBeforeUnmount(() => {
  _isOpen.value = false
})
onUnmounted(() => {
  window.removeEventListener('keydown', detectKey)
})
</script>


<template>
  <Teleport to="body" v-if="_isOpen" >
    <div class="modal-wrapper" :class="[attrs.class]">

      <div class="modal" :style="{ width: props.width, height: props.height }">
        <q-icon
          size="1.5em"
          name="cancel"
          class="pointer close-icon"
          @click="close"
        />
        <div class="modal-header">
          <slot name="header" />
        </div>
        <div class="modal-content">
          <slot :open="open" :close="close" :is-open="_isOpen" />
        </div>
        <div class="modal-footer" >
          <slot name="footer" :open="open" :close="close" :is-open="_isOpen" ></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.modal-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#000, 0.3);
      z-index: 2000;
    }.close-icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      z-index: 10;
      background: white;
      border-radius: 50%;
      box-shadow: 2px 2px 6px #00000080;
    }.modal  {
      position: relative;

      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    }
    .modal-content {
      overflow-y: auto;
      max-height: 90vh;
      max-width: 80vw;
      // background-color: rgba(#000, 0.8);
      background-color:  var(--color-background);

      -webkit-overflow-scrolling: touch;

      overscroll-behavior: contain;
    }
    .modal-footer{
      background-color:  var(--color-background);
    }
</style>
