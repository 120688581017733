import { getType } from './getType'

/**
 * Create a object with [{value, label}]
 * @date 10/13/2022 - 7:00:38 PM
 *
 * @export
 * @param {*} inputOptions
 * @returns {{}}
 */

// options = ['a','b', 'c' ]
type optionFormatA = Array<string | number | boolean>

//   options = {value:'aaaa',value2:'bbb'}
interface optionFormatB {
  [value: number | string]: string
}
// options = [{value:'aaa'},{value:'bbbbb'}]
type optionFormatC = Array<optionFormatB>

// options = [{value:'aaa',label:'aaa'},{value:'aaa',label:'aaa'}]
type optionFormatD = Array<{ value: string | number; label: string }>

export type optionsT = optionFormatA | optionFormatB | optionFormatC | optionFormatD

export default function (inputOptions: optionsT, labelKey = 'label', valueKey = 'value'): Array<{ value: string | number; label: string }> {
  const options = []
  let tempArray = []
  const inputOptionsType = getType(inputOptions)

  if (inputOptionsType === 'object') {
    Object.entries(inputOptions).forEach((option) => {
      let value: string | number = option[0]
      if (!isNaN(value)) // probably if it is a number, use number version, becouse object keys are alwys string
        value = parseInt(value)
      tempArray.push({ value, label: option[1] })
    })
  }
  else if (inputOptionsType === 'array') {
    tempArray = inputOptions as Array<any>
  }

  for (const option of tempArray) {
    let label, value
    const optionType = getType(option)
    if (optionType === 'object') {
      label = (option?.[labelKey] ?? option?.name) ?? Object.values(option)?.[0]
      value = (option?.[valueKey] ?? Object.keys(option)?.[0]) ?? label
    }
    else if (optionType === 'string' || optionType === 'number') {
      label = option
      value = option
    }
    options.push({ label, value })
  }

  return options
}
