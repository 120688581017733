<script setup>
/* eslint no-multiple-empty-lines: ["error", { "max": 20, "maxEOF": 0 }] */
import { computed, isProxy, nextTick, onBeforeMount, onMounted, ref, toRef, useSlots, watch } from 'vue'
import { computedWithControl, resolveUnref, watchTriggerable } from '@vueuse/core'
import FieldDropdown from './field-dropdown.vue'
import AutoModelForm from '@/models/autoModelForm.vue'

// define model
import { getModelId, getModelIdKey } from '@/models/models'
import { modelsStore as ModelStorePinia } from '@/models/models.pinia'
import { Model, getDisplayFields, getModelFields, useLoadModel } from '@/models/useModelComposable'

const props = defineProps({
  modelName: String,
  modelValue: {
    type: [String, Number, Array, Object],
  },
  displayField: [String, Number],
  label: {
    type: String,
  },
  multiple: {
    default: false,
  },
  max: {
    default: 10,
  },
  min: {
    default: 1,
  },
  name: {
    type: String,
  },
  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  value: String,
  showCloseButton: {
    default: true,
  },
  showEdit: {
    default: true,
  },
  showAdd: {
    default: true,
  },
  cellStyle: {
    default: false,
  },
  /** Define the vmodel/change emit. auto will emit as the modelValue format */
  emitFullObject: {
    type: [String, Boolean],
    default: 'auto',
  },
})

const emit = defineEmits(['update:modelValue', 'update:value', 'change', 'changeRawValue'])
const slots = useSlots()
const modelStore = ModelStorePinia()

const modelId = toRef(props, 'modelId')
const searchWord = ref('')
const allItems = ref([])
const initValues = ref({})
const selectComponent = ref(null)


const p_modelName = toRef(props, 'modelName')
const p_modelValue = toRef(props, 'modelValue')

// Model Class Instance init/sync
// ----------------------
let model = new Model(p_modelName.value)
watch(p_modelName, (v) => {
  model = new Model(p_modelName.value)
  // TODO - provavelmente precisa reinicia alguma coisa aqui
})

// V-Model Funcs
// ----------------------

const _modelValueRaw = ref(null)
/** define raw value */
watchTriggerable(p_modelValue, (val, oldVal) => {
  if (JSON.stringify(val) == JSON.stringify(oldVal))
    return
  const v = JSON.parse(JSON.stringify(resolveUnref(val)))
  let value
  if (Array.isArray(v))
    value = v.map(vv => vv?.[model.idKey] ?? vv)

  else
    value = v?.[model.idKey] ?? v

  const fvalue = JSON.parse(JSON.stringify(value))
  _modelValueRaw.value = fvalue
}).trigger()

/** if we received the full object or just the id */
const _emitFullObject = computed(() => {
  if (props.emitFullObject !== 'auto')
    return props.emitFullObject
  else
    return !!p_modelValue?.value?.[model.idKey]
})

/** emit changes when value change */
watchTriggerable(_modelValueRaw, (value, oldVal) => {
  if (!_emitFullObject.value) {
    emit('update:modelValue', value)
    emit('update:value', value)
    emit('change', value)
  }
  if (value !== oldVal)
    emit('changeRawValue', value)
})// .trigger()




// Model Functions
// -----------------
// TODO - mudal para a classe do modelo o isfechting
const isFetchingItems = ref(false)
const _allItems = ref(null)
const _selectedItems = ref([])
const _filteredItems = ref([])

const {
  isFetching,
  isFinished,
  hasError,
  message,
  items,
  item,
  loadItem,
} = useLoadModel()

watch(_selectedItems, (value, oldVal) => {
  if (_emitFullObject.value) {
    emit('update:modelValue', value)
    emit('update:value', value)
    emit('change', value)
  }
})


/** every time a raw value change, we get the item(s) */
const getSelectedItemsWatcher = watchTriggerable(_modelValueRaw, async (valueIDs, oldValsId) => {
  isFetchingItems.value = true
  const returnedItems = await model.getItem(valueIDs)
  _selectedItems.value.splice(0)
  if (Array.isArray(returnedItems))
    returnedItems.forEach(ritem => _selectedItems.value.push(ritem))
  else
    _selectedItems.value.push(returnedItems)

  if (_emitFullObject.value) {
    // TODO - incluir multiple
    const valToEmit = _selectedItems.value[0]
    emit('update:modelValue', valToEmit)
    emit('update:value', valToEmit)
    emit('change', valToEmit)
  }

  isFetchingItems.value = false
})
getSelectedItemsWatcher.trigger()



// EveryTimeItemIsOpen
// TODO !important - solucao temporario considerando que estamos carregando todos os modelos
// FIXME - quando procuro e aperto esc, se perde o filtro, verificar no qselect
const isFiltered = ref(false)
let loadedFirsTime = false
const filterItems = async (val, update, abort) => {
  if (!loadedFirsTime) {
    const res = await model.loadAllItem()
    if (res.items)
      _allItems.value = res.items

    loadedFirsTime = true
  }
  const needle = val?.trim() ?? ''
  isFiltered.value = (needle != '')
  const itemsFiltered = await model.getAllItems(null, needle)
  update(() => {
    _filteredItems.value = [...itemsFiltered]
  }, (ref) => {
  })
}

// Adding New Functions
// --------------------------------------

const showModelPopupForm = ref(false)
const popupModelId = ref(null)
const addNew = function (val) {
  if (val)
    initValues.value[fieldToDisplay.value] = val

  showModelPopupForm.value = true
  popupModelId.value = null
}

const editCurrentItem = function (itemId) {
  showModelPopupForm.value = true
  popupModelId.value = itemId
}

const closed = () => {
  showModelPopupForm.value = false
}

const saved = async (response) => {
  const newModelId = getModelId(p_modelName.value, response)
  showModelPopupForm.value = false
  if (newModelId) {
    // TODO - lidar com multiplo, e se edicao
    _modelValueRaw.value = newModelId
    // talvez colocar numa acao computada
    _allItems.value = await model.getAllItems(null, '')
    getSelectedItemsWatcher.trigger()
  }
}


// Display Items Helpers
// -----------------
const fieldToDisplay = computed(() => {
  const firstItem = allItems.value?.[0]
  const fieldsFromModelDefinition = getDisplayFields(p_modelName.value)?.[0]
  const fieldsFromProps = props.displayField
  if (firstItem?.[fieldsFromProps])
    return fieldsFromProps
  else if (firstItem?.[fieldsFromModelDefinition])
    return fieldsFromModelDefinition

  return fieldsFromProps || fieldsFromModelDefinition
})



const fieldsToDisplay = computed(() => {
  const fieldsToDisplay = getDisplayFields(p_modelName.value)
  // fieldsToDisplay.splice(0, 1)
  const returnArray = []
  const allFields = getModelFields(p_modelName.value)
  fieldsToDisplay.forEach((fieldName) => {
    const fieldInfo = allFields.find(field => field.key === fieldName)
    returnArray.push({ label: fieldInfo?.label ?? fieldInfo?.key, value: fieldName })
  })
  return returnArray
})



const optionsForDropdown = computed(() => {
  return isFiltered.value ? [..._filteredItems.value] : (_allItems.value ? [..._allItems.value] : [..._selectedItems.value])
})
</script>

<template>
  <FieldDropdown
    v-bind="props"
    v-model="_modelValueRaw"
    :options="optionsForDropdown"
    :label-key="fieldToDisplay"
    :value-key="getModelIdKey(p_modelName)"
    :search-fn="filterItems"
    :add-new-fn="props.showAdd ? addNew : null"
    :show-loading="isFetchingItems"
    :multiple="props.multiple"
    :emit-full-object="false"
  >
    <template #selected-item="{ item }">
      <slot name="selected-item" :item="item">
      </slot>
    </template>

    <!-- custom option for model -->
    <template #option="{ option }">
      <q-item-section class="py-2">
        <q-item-label
          v-for="(field, i) in fieldsToDisplay"
          :key="field.value"
          :lines="1"
          class="my-0 py-0"
          style="margin-top: 0 !important; line-height: 0.9;"
        >
          <span v-if="i === 0"> {{ option?.[field.value] }}</span>
          <span v-else class="text-xs uppercase line-height-1 pl-1"><small> {{ field?.label }} - {{ option?.[field?.value] }}</small></span>
        </q-item-label>
      </q-item-section>
    </template>
    <!-- <template #option="scope">
      <pre>{{ scope }}</pre>
      <q-item v-bind="scope.itemProps">
        <slot
          name="option"
          v-bind="scope"
          :option="scope.opt"
        >
          <q-item-section>
            <q-item-label>{{ scope.opt?.[fieldToDisplay] }}</q-item-label>

            <q-item-label
              v-for="subField in subFieldsToDisplay"
              :key="subField.value"
              :lines="1"
              caption
              class="text-xs uppercase line-height-1"
            >
              <small>{{ subField?.label }} <span>{{ scope.opt?.[subField.value] }}</span></small>
            </q-item-label>
          </q-item-section>
        </slot>
      </q-item>
    </template> -->

    <template #hint="{ modelValueRaw }">
      <slot name="hint">
        <Transition>
          <div v-if="modelValueRaw && showEdit" class="pointer" @click="() => editCurrentItem(modelValueRaw)">
            <QIcon name="edit" /> Editar
          </div>
        </Transition>
        <span v-if="hint" v-html="hint" />
      </slot>
    </template>
  </FieldDropdown>


  <AutoModelForm
    v-if="showModelPopupForm"
    :open="true"
    :model-name="p_modelName"
    :model-id="popupModelId"
    :modal="true"
    :value="initValues"
    @saved="saved"
    @closed="closed"
  />
</template>

<style lang="scss">

</style>
