import type { Model } from '@/models/modelsDef'

export default {
  fake: false,

  endpoint: 'sync/{idclientevallen}/tabelaprecos',
  displayName: {
    singular: 'Tabela de Preço',
    plural: 'Tabelas de Preço',

  },
  icon: 'request_quote',
  idKey: 'id',
  fields: [
    {
      key: 'id',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'no_tabela',
      label: 'Nome',
      hint: 'Nome univoco da tabela de convênio',
      type: 'text',
      mask: null,
      required: true,
      displayLabel: true,
      search: true,
    },
    {
      key: 'co_conveniado',
      label: 'Conveniado ',
      displayValue: 'conveniado.no_unidade',
      hint: '',
      type: 'model',
      modelName: 'pessoajuridica',
      mask: null,
      required: true,
      displayLabel: true,
      search: true,
      props: {
        defaultFilter: { tp_estabelecimento: 'CONVENIO' },
      },
    },
  ],
} as Model
