export default {
  fake: false,
  endpoint: '{idclientevallen}/pagamentoatendimento/{idatendimento}',
  //   endpointOptions: { find: { method: 'get', url: '{idclientevallen}/usuariopapel/{co_usuario}' } },
  displayName: {
    singular: 'Forma de Pagamento',
    plural: 'Formas de Pagamento',
  },
  idKey: 'id',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: true,
    },
    {
      key: 'co_forma_pagamento',
      label: 'Forma de Pagamento',
      type: 'model',
      modelName: 'forma-pagamento',
      default: true,
      required: true,
    },
    {
      key: 'vl_pagamento',
      label: 'Valor',
      type: 'number',
      required: true,
      displayLabel: true,
    },

  ],
} satisfies ModelT

