import type { ComputedRef } from 'vue'
import { type Ref, computed, isRef, ref, watch } from 'vue'
import type { MaybeComputedRef } from '@vueuse/core'
import { resolveRef, resolveUnref } from '@vueuse/core'
import { Dialog, Notify, Quasar, useQuasar } from 'quasar'
import filter from 'lodash/filter'
import { modelsStore as modelPinia } from './models.pinia'
import { DATASOURCES, MODELS, getModelId, getModelIdKey, getModelOrderOptions } from './models'
import { isObject } from '@/utils/getType'

const $q = Quasar

// TODO - verificar pq da erro no vite normal
// const modelStore = modelsStore();
export { MODELS, getModelFields, getModelId, getModelIdKey, getFieldsFor, getModelOrderOptions, DATASOURCES } from './models'
export class Model {
  modelName
  _isFetching
  _hasError
  _message
  _total = 0
  _currentPage = 0
  _limite = 15
  constructor(modelName) {
    this.modelName = resolveUnref(modelName)
    this._isFetching = ref(false)
    this._hasError = ref(false)
    this._message = ref('')
  }

  get idKey() {
    return getModelIdKey(this.modelName)
  }

  get name() {
    return this.modelName
  }

  get isFetching() {
    return this._isFetching
  }

  get hasError() {
    return this._hasError
  }

  getItemId(modelItem) {
    return getModelId(this.modelName, modelItem)
  }

  async getItem(modelId) {
    const modelStore = modelPinia()
    const modelIds = Array.isArray(modelId) ? modelId : [modelId]
    const items = []
    for (const id of modelIds) {
      const item = modelStore.getItem(this.modelName, id)
      if (item) {
        items.push(item)
      }
      else {
        const loadItem = await this.loadItem(id)
        items.push(loadItem.item)
      }
    }
    return Array.isArray(modelId) ? items : items[0]
  }

  // FIXME - solucao temp, filter nao funciona aqui
  async getAllItems(filter, searchWord) {
    const modelStore = modelPinia()
    // first check if item exist, if not, load and return it
    const items = modelStore.getAllItems(this.modelName, searchWord)
    return JSON.parse(JSON.stringify(items))
  }

  async loadAllItem(filter) {
    const modelStore = modelPinia()
    this._isFetching.value = true
    const res = await modelStore.loadModel(this.modelName, null, filter)
    this._isFetching.value = false
    this._hasError.value = res.hasError
    this._message.value = res.message
    return res
  }

  async loadItem(modelId) {
    const modelStore = modelPinia()
    this._isFetching.value = true
    const res = await modelStore.loadModel(this.modelName, modelId)
    this._isFetching.value = false
    this._hasError.value = res.hasError
    this._message.value = res.message
    return res
  }
}

export function useLoadModel(modelName) {
  // always watchs when modelName or Id change
  const modelStore = modelPinia()
  const _modelName = isRef(modelName) ? modelName.value : modelName
  const hasError = ref(false)
  const isFinished = ref(null)
  const isFetching = ref(false)
  const _filters = ref(null)
  const items = computed(() => {
    return modelStore.getItems(_modelName, _filters.value)
  })
  const item = ref(null)
  const message = ref('')
  const filtersModel = ref(null)
  const loadItem = async (modelName, modelID, filters) => {
    const modelStore = modelPinia()
    isFetching.value = true
    const _modelName = isRef(modelName) ? modelName.value : modelName
    const _modelID = isRef(modelID) ? modelID.value : modelID
    const response = await modelStore.loadModel(_modelName, _modelID, filters)
    isFetching.value = false
    if (response.hasError) {
      hasError.value = true
    }
    else {
      hasError.value = false
      if (modelID) {
        item.value = modelStore.getItem(_modelName, _modelID) // response.item
      }
      else {
        if (filters)
          _filters.value = filters
        // items.value = modelStore.getItems(_modelName, filters) // response.items
      }
    }
    message.value = response.message
  }
  return {
    isFetching,
    isFinished,
    hasError,
    message,
    items,
    item,
    loadItem,
  }
}
export function loadModelItem(modelName, id, options = { refresh: true }) {
  const { isFetching, isFinished, hasError, message, item, loadItem } = useLoadModel(modelName)
  //  add watch
  if (isRef(modelName) && options.refresh) {
    watch(() => modelName.value, (v) => {
      if (id)
        loadItem(v, id)
      else
        item.value = null
    })
  }
  if (isRef(id) && options.refresh) {
    watch(() => id.value, (v) => {
      if (v)
        loadItem(modelName, v)
      else
        item.value = null
    })
  }
  if (resolveUnref(id))
    loadItem(modelName, id)
  else
    item.value = null
  return {
    isFetching,
    isFinished,
    hasError,
    message,
    item,
  }
}
export function loadModelItems(modelName, filter, options = { refresh: true }) {
  const { isFetching, isFinished, hasError, message, items, loadItem } = useLoadModel(modelName)
  //  add watch
  if (isRef(modelName) && options.refresh) {
    watch(() => modelName.value, (v) => {
      loadItem(v, null)
    })
  }
  loadItem(modelName, null, filter)
  return {
    isFetching,
    isFinished,
    hasError,
    message,
    items,
    loadItem,
  }
}
export function useSaveModelItem() {
  // always watchs when modelName or Id change
  // const modelStore = modelsStore()
  const modelStore = modelPinia()
  const hasErrorSaving = ref(false)
  const isFinishedSaving = ref(false)
  const isSaving = ref(false)
  const fieldErrors = ref([])
  const itemSaved = ref(null)
  const messageSaved = ref('')
  const saveItem = async (modelName, modelData, modelID) => {
    isSaving.value = true
    isFinishedSaving.value = false
    hasErrorSaving.value = false
    const _modelName = isRef(modelName) ? modelName.value : modelName
    const _modelID = isRef(modelID) ? modelID.value : modelID
    const _modelData = isRef(modelData) ? modelData.value : modelData
    const _modeDataFinal = JSON.parse(JSON.stringify(modelData))
    const response = await modelStore.saveModel(_modelName, _modeDataFinal, _modelID)
    isSaving.value = false
    isFinishedSaving.value = true
    if (response.hasError) {
      hasErrorSaving.value = true
      fieldErrors.value = response.fieldErros
      itemSaved.value = _modelData

      if (response.fieldErrors)
        fieldErrors.value = response.fieldErrors
    }
    else {
      hasErrorSaving.value = false
      fieldErrors.value = []
      itemSaved.value = response.item
    }
    messageSaved.value = response.message
    return response
  }
  return {
    isSaving,
    isFinishedSaving,
    hasErrorSaving,
    messageSaved,
    itemSaved,
    saveItem,
    fieldErrors,
  }
}
export function useDeleteItem() {
  // always watchs when modelName or Id change
  // const modelStore = modelsStore()
  const modelStore = modelPinia()
  // const $q = useQuasar()
  const hasErrorDeleting = ref(false)
  const isFinishedDeleting = ref(false)
  const isDeleting = ref(false)
  const messageDeleted = ref('')
  const confirmDelete = function (modelName, id) {
    return new Promise((resolve, reject) => {
      $q.dialog({
        title: 'Deseja remover?',
        message: `${modelName} ${id}?`,
        cancel: true,
        persistent: false,
      }).onOk(async () => {
        resolve(true)
      }).onCancel(() => {
        resolve(false)
      }).onDismiss(() => {
        resolve(false)
      })
    })
  }
  const deleteItem = async (modelName, modelID, filters, confirm = true) => {
    isDeleting.value = true
    isFinishedDeleting.value = false
    hasErrorDeleting.value = false
    const _modelName = isRef(modelName) ? modelName.value : modelName
    const _modelID = isRef(modelID) ? modelID.value : modelID
    if (confirm) {
      const res = await confirmDelete(modelName, modelID)
      if (res === false)
        return
    }
    const response = await modelStore.deleteModel(_modelName, _modelID, filters)
    isDeleting.value = false
    isFinishedDeleting.value = true
    if (response.hasError)
      hasErrorDeleting.value = true
    else
      hasErrorDeleting.value = false
    messageDeleted.value = response.message
    return response
  }
  return {
    isDeleting,
    isFinishedDeleting,
    hasErrorDeleting,
    messageDeleted,
    deleteItem,
  }
}
export function getDisplayFields(modelName) {
  return MODELS?.[modelName]?._displayFields ?? MODELS?.[modelName]?._allFieldsName
}
export function getOrderField(modelName) {
  return MODELS?.[modelName]._orderFields
}
export function getModelIdType(modelName) {
  const idKey = getModelIdKey(modelName)
  const field = MODELS?.[modelName].fields.find(field => field.key === idKey)
  return field?.type
}

interface useOptionsT {
  filters?: MaybeComputedRef<FilterOptionsT>
  filterWord?: MaybeComputedRef<string>
  page?: MaybeComputedRef<number>
  limit?: MaybeComputedRef<number>
  refetch?: MaybeComputedRef<boolean>
  sort?: MaybeComputedRef<SortOptionsT>
  metaInfo?: MaybeComputedRef<{ urlData: string }>
  loadOnStart?: boolean
}

//  THE NEW USE COMPOSE  ---------------------------------
export function useModel<T>(modelNameInput: MaybeComputedRef<string> | Ref<string> | string, useOptions?: useOptionsT) {
  // const $q = useQuasar()

  const modelStore = modelPinia()
  const modelName = resolveRef(modelNameInput)

  const hasError = ref(false)
  const fieldErrors = ref([])
  const serverData = ref(null)
  const isFinished = ref(null)
  const isFetching = ref(false)
  const totalItems = ref(0)
  const item: Ref<T> = ref(null)
  const items: Ref<Array<T> | null> = ref(null)

  const message = ref('')

  /** Model Class */
  const model = ref<ModelClassT | null>(MODELS?.[modelName.value] ?? null)
  watchEffect(() => {
    const name = modelName.value
    if (MODELS?.[name]) {
      model.value = MODELS?.[name]
    }
    else {
      hasError.value = true
      message.value = 'Modelo inexistente'
    }
  })

  /** Items get from Store, based on the id returnd from api. Mak a singlw source of truth for item thant change be reflected */
  const itemsStore: Ref<Array<T>> = ref([])
  watch(items, (newItems, oldItems) => {
    console.log('useModel: watch items... newItems', newItems, 'oldItems:', oldItems)
    const values = items?.value?.map(theitem => model.value?.getId(theitem))
    if (values) {
      const itemsFromStore = modelStore.getItemsById(modelName.value, values)
      itemsStore.value = itemsFromStore
    }
    else {
      itemsStore.value = []
    }
  })
  const itemStore: Ref<T | null> = ref(null)
  watch(item, (newItems, oldItems) => {
    const values = model.value?.getId(item?.value)
    if (values)
      itemStore.value = modelStore.getItemsById(modelName.value, [values])?.[0]
    else
      itemStore.value = null
  })

  let {
    filterWord = '',
    filters = null,
    page = 1,
    limit = 10,
    // eslint-disable-next-line prefer-const
    refetch = true,
    sort = null,
    loadOnStart = false,
  } = useOptions || {}

  if (model.value)

    sort = model.value?.getOrderOptions()

  // make all reactive, if the already are, it uses it
  // @eslint-disable-line
  if (!isRef(limit))

    limit = ref(limit)
  if (!isRef(page))

    page = ref(page)
  if (!isRef(sort))
    sort = ref(sort)

  if (!isRef(filters))
    filters = ref(filters)

  if (!isRef(filterWord))
    filterWord = ref(filterWord)

  const filterWordDebounced = refDebounced(filterWord, 400)

  const allItemsFiltered = computed<Array<T>>(() => {
    const currentFilter = resolveUnref(filters)
    // const itemsAll = modelStore.getItems(modelName.value)
    const itemscomp = modelStore.getItems(modelName.value, currentFilter)
    return itemscomp
  })

  const allItems = computed<T[]>(() => {
    return modelStore.getItems(modelName.value)
  })
  /** already set all the items */
  // const items = computed(() => {
  //   return modelStore.getItems(modelName.value, resolveUnref(filters))
  // })

  const loadItem = async (modelID = null, options = {}) => {
    const { modelName: _modelName, filters: _filters, filterWord: _filterWord, page: _page, limit: _limit, sort: _sort, data = {} } = options || {}
    // get from local, or global
    const __modelName = resolveUnref(modelName || _modelName)
    const __modelID = resolveUnref(modelID)
    const __filters = resolveUnref(_filters || filters)
    const __filterWord = resolveUnref(_filterWord || filterWord)
    const __page = resolveUnref(_page || page)
    const __limit = resolveUnref(_limit || limit)
    const __sort = resolveUnref(_sort || sort)
    isFetching.value = true
    console.log('Loading Model:', __modelName, 'filters', __filters, 'page', __page)
    const response = await modelStore.loadModel(__modelName, __modelID, { filters: __filters, filterWord: __filterWord, page: __page, limit: __limit, sort: __sort, data })
    isFetching.value = false
    hasError.value = response.hasError
    message.value = response.message
    serverData.value = response?.data ?? null
    if (__modelID && !Array.isArray(__modelID)) {
      item.value = response.item
      items.value = [response.item]
    }
    else {
      totalItems.value = response?.data?.registros ?? 0
      items.value = response?.items
      item.value = response?.items?.[0]
    }
    message.value = response.message

    return response
  }

  interface saveOptions {
    modelName?: string
    /** defaults true. If show notification on save or error */
    notify?: boolean
  }

  const saveItem = async (newItem, id?, options?: saveOptions) => {
    const { modelName: _modelName, notify = true, data = {} } = options || {}
    // get from local, or global
    // const $q = useQuasar()
    const __modelName = resolveUnref(modelName || _modelName)
    const __item = resolveUnref(newItem)
    const __id = resolveUnref(id)

    isFetching.value = true
    const response = await modelStore.saveModel(__modelName, { ...__item }, __id, options)
    isFetching.value = false
    hasError.value = response.hasError
    message.value = response.message
    serverData.value = response?.data ?? null
    //
    if (!response.hasError) {
      // if (__id) // coomentado pq se for um novo nao tem id
      item.value = response.item
      if (model.value)
        model.value?.afterSave(item.value, { $q })
    }

    message.value = response.message
    fieldErrors.value = response?.fieldErrors ?? null

    if (notify) {
      // get the display name
      const displayKey = model.value?.displayFields?.[0]?.key ?? model.value?.getIdKey()
      const ItemDisplayName = item.value?.[displayKey] ?? model.value?.getId(item.value)
      if (hasError.value)
        Notify.create({ type: 'negative', message: 'Erro ao salvar', caption: ItemDisplayName, timeout: 5000 })

      else
        Notify.create({ type: 'positive', message: 'Salvo com sucesso', caption: ItemDisplayName, timeout: 2000 })
    }

    return response
  }

  const deleteItem = async (itemOrID: Object | string | number, options?) => {
    const { modelName: _modelName, needConfirm = true, data = {} } = options || {}
    // get from local, or global
    const __modelName = resolveUnref(modelName || _modelName)
    let __id = resolveUnref(itemOrID)
    if (isObject(__id))
      __id = model.value.getId(__id)
    if (needConfirm) {
      const confirm = await confirmDialog('Deseja remover o item?')
      if (!confirm)
        return
    }

    isFetching.value = true
    const response = await modelStore.deleteModel(__modelName, __id, options)
    isFetching.value = false
    hasError.value = response.hasError
    message.value = response.message
    serverData.value = response?.data ?? null
    message.value = response.message
    return response
  }

  watch([page, limit, sort], () => {
    if (refetch)
      loadItem()
  })

  watch(model, (val, oldVal) => {
    if (refetch) {
      page.value = 1 // if elements that change the size of return, we reset the pages
      loadItem()
    }
  })

  watch([filterWordDebounced, filters], () => {
    // console.log('watch filterWordDebounced from:', oldVal, 'to:', val)
    if (refetch) {
      page.value = 1 // if elements that change the size of return, we reset the pages
      loadItem()
    }
  })

  if (loadOnStart)
    loadItem()

  return {
    isFetching,
    isFinished,
    hasError,
    message,
    /** all items loaded of that model in any place */
    allItems,
    /** all items loaded of that model in any place, using the filters if have one */
    allItemsFiltered,
    /** items loaded of that model using loadModel of this composable instance */
    items: itemsStore,
    item: itemStore,
    loadItem,
    saveItem,
    deleteItem,
    sort,
    page,
    limit,
    filters,
    filterWord,
    totalItems,
    serverData,
    model,
    fieldErrors,
    itemsStore,
  }
}

function confirmDialog(msg) {
  return new Promise((resolve, reject) => {
    Dialog.create({
      title: 'Confirme',
      message: msg,
      cancel: true,
      persistent: true,
    }).onOk(() => {
      resolve(true)
    }).onCancel(() => {
      resolve(false)
    }).onDismiss(() => {
      resolve(false)
    })
  })
}
