<!-- eslint-disable vue/define-macros-order -->
<script lang="ts">
import { computed, defineComponent, h, isRef, onBeforeMount, onMounted, onRenderTracked, onRenderTriggered, reactive, ref, toRef, watch, withDefaults } from 'vue'
import type { PropType, Ref, VNode } from 'vue'
import { QBtn, QIcon, QInnerLoading, QSpinner } from 'quasar'
import ModalComp from '@/elements/modal.vue'

// LOAD FIELDS COMPONENTES (forms/forms-fields/*.vue)
const allFields = import.meta.glob('@/components/forms/forms-fields/*.vue', { eager: true })
const allFieldsComponents: { [index: string]: any } = {}
Object.entries(allFields).forEach(([path, definition]) => {
  const componentName = path.split('/').pop().replace(/\.\w+$/, '').replace(/^field-/, '')
  allFieldsComponents[componentName] = definition.default
})

// V-model nao esta funcionando direito, mas o value e @update:modelVAlue funciona
export default defineComponent({
  props: {
    autoFields: {
      type: Boolean,
      default: true,
    },
    /** open in a modal */
    modal: Boolean,
    isFetching: Boolean,
    modelValue: String,
    value: Object,
    fields: Object as PropType<FieldT>,
    fieldsErros: Object as PropType<{ [key: string]: string }>,
    errorMessage: String,
    postToUrl: String,
    queryUrl: String,
    id: [String, Number],
  },

  emits: ['submit', 'closed', 'change', 'update:modelValue'],

  setup(props, { slots, emit, expose }) {
    const myForm = ref(null)
    const myModal = ref(null)
    // FIELDS
    const isNew = computed(() => (props.id === 'new' || !props.id))
    const fields: Ref<FieldT[]> = ref([])
    const fieldsValues: Ref<{ [key: string]: unknown }> = ref({})

    const fieldsProps = computed(() => {
      const fieldPropsObj = {}
      fields.value.forEach(field => fieldPropsObj[field.name] = field.props.props)
      return fieldPropsObj
    })

    /**
    * Gera os Objetos the fields
    */
    // TODO - pegar do model?? Mas lidamos com o fieldsValue tb
    function configureFields(theFields) {
      if (!Array.isArray(theFields))
        return
      // clean fields reactive
      fields.value.splice(0)
      theFields.forEach((field: FieldT) => {
        const name: string = field?.key ?? field?.name ?? field?.label
        const type = field?.type ?? 'text'
        const label = field?.label ?? field?.name ?? field?.key
        const ignore = field?.ignore ?? false
        const hidden = field?.hidden ?? false
        const value = props?.value?.[name] ?? field?.value ?? props?.modelValue?.[name] ?? null
        const fieldObj: FieldT = {
          ...field,
          type,
          name,
          key: name,
          hidden,
          ignore,
          value,
          props: {
            ...field,
            ...field.props,
            ...field.props?.props, // TODO - melhorar isso, props dentro do props etc
            name,
            label,
            type,
          },
        }
        if (!ignore) {
          fields.value.push(fieldObj)
          fieldsValues.value[name] = value
        }
      })
    }

    watch(() => fieldsValues.value,
      (val) => {
        console.log('Auto Form - update:modelValue', val)
        emit('update:modelValue', val)
      },
      { deep: true },
    )

    function renderFieldsComponents(): { [key: string]: VNode } {
      const fieldsComp: { [key: string]: VNode } = {} // usar map ou array

      fields.value.forEach((field: FieldT) => {
        const fkey = field.key
        const compProps: any = {
          ...field.props,
          'style': '',
          'class': '',
          'name': fkey,
          'type': field.type,
          'modelValue': fieldsValues.value[fkey] ?? null,
          'onUpdate:modelValue': (val) => {
            fieldsValues.value[fkey] = val
          },
          'error': props.fieldsErros?.[fkey] ?? null,
        }
        let fieldCompForType = allFieldsComponents?.[field.type]
        if (!fieldCompForType) {
          fieldCompForType = allFieldsComponents.text
          compProps.type = field.type
        }

        // lets put some styles/layouts in it
        if (field?.props?.style)
          compProps.style += field.props.style

        if (field?.props?.classes)
          compProps.class += field.props.classes

        if (field?.props?.size)
          compProps.style += `max-width: ${field.props.size}em;`

        fieldsComp[fkey] = h(fieldCompForType, compProps)
      })

      return fieldsComp
    }

    function organizeFields(listOfUsedField = []): Map<string, { groups: Map<string, FieldT[]> }> {
      const fieldsets = new Map()

      fields.value.forEach((field, i) => {
        if (listOfUsedField.includes(field.key))
          return

        const fieldsetKey = field.fieldset || 'default'
        const groupKey = field.group || fieldsetKey + i

        if (fieldsets.has(fieldsetKey) === false)
          fieldsets.set(fieldsetKey, { groups: new Map() })

        const fieldsetData = fieldsets.get(fieldsetKey)

        if (fieldsetData.groups.has(groupKey) === false)
          fieldsetData.groups.set(groupKey, [])
        fieldsetData.groups.get(groupKey).push(field)
      })
      return fieldsets
    }

    // generate final fields
    watchEffect(() => {
      const fields = props.fields
      configureFields(fields)
    })

    // generate value for fields, get from value or modelvalue
    watchEffect(() => {
      fields.value.forEach((field: FieldT) => {
        const fkey = field.key
        fieldsValues.value[fkey] = props?.modelValue?.[fkey] ?? props?.value?.[fkey] ?? null
      })
    })

    // FORM ACTION
    function handleSubmit(e) {
      if (myForm.value.checkValidity()) {
        submitForm(e)
      }
      else {
        console.error('Validation failed.')
        const inputs = myForm.value.querySelectorAll('input')
        inputs.forEach((input) => {
          if (!input.checkValidity()) {
            input.focus()
            input.reportValidity()
          }
        })
      }
    }

    function submitForm(e: Event) {
      e.preventDefault()
      const valueRaw = { ...fieldsValues.value } // because can have file object JSON.parse(JSON.stringify(fieldsValues.value))
      emit('change', valueRaw)
      emit('submit', valueRaw)
    }

    function onClosed(e: Event) {
      e.preventDefault()
      emit('closed')
    }

    onRenderTracked((e) => {
      console.log('AutoForm - onRenderTracked', e)
    })
    onRenderTriggered((e) => {
      console.log('AutoForm - onRenderTriggered', e)
    })

    /** return a array of all already used fields Nodes */
    function getUsedFieldsVNode(vNode: VNode | VNode[] | undefined, keyslist = []): Array<string> {
      if (!vNode)
        return keyslist
      if (Array.isArray(vNode))
        vNode.forEach(node => getUsedFieldsVNode(node, keyslist))
      if (vNode.props && vNode.props.hasOwnProperty('name'))
        keyslist.push(vNode.props.name)
      // recursively check children of current v-node
      if (vNode.children && vNode.children?.length > 0) {
        for (const child of vNode.children)
          getUsedFieldsVNode(child, keyslist)
      }
      return keyslist
    }

    expose({ fields, fieldsValues })
    //  RENDER  ---------------------------------
    // ---------------------------------------------

    return () => {
      // console.debug(fieldsValues.value)

      // always i will generate all fields render to make sure pops slots can use it
      const fieldsComp = renderFieldsComponents()

      // render the default slot and get any field already used, so it will be ignore if auto-fields is on
      const defaultChildVNode = slots.default
            && slots.default({
              values: fieldsValues.value,
              fieldsProps: fieldsProps.value,
              fields,
              fieldsComp,
            })
      const listOfUsedField = getUsedFieldsVNode(defaultChildVNode)

      const formVNode = h('form',
        {
          class: 'autoform',
          onSubmit: submitForm,
          ref: myForm,
        })

      const fieldsets = organizeFields(listOfUsedField)
      const renderField = field => fieldsComp[field.key]
      const renderGroup = fields => h('div', { class: 'autoform_group flex gap-5' }, fields.map(renderField))
      const renderFieldset = ([fieldsetKey, fieldsetData]) => {
        const groups = Array.from(fieldsetData.groups.entries()).map(([groupKey, fields]) =>
          renderGroup(fields),
        )
        return h('fieldset', { class: 'fieldset', key: fieldsetKey }, [
          fieldsetKey !== 'default' ? h('legend', { class: 'legend' }, fieldsetKey) : null,
          ...groups,
        ])
      }
      const fieldsetElementsVNode = Array.from(fieldsets.entries()).map(renderFieldset)

      const actionsButton = [
        h(
          'button',
          { class: 'btn w-6/12 bg-gray py-8', onClick: onClosed },
          'Cancelar',
        ),
        h('button',
          {
            class: 'btn bg-action w-6/12 py-8 flex items-center justify-center gap-2',
            onClick: handleSubmit,
          },
          [props.isFetching
            ? h(QSpinner, { color: 'white', size: '1em', thickness: 2 })
            : h(QIcon, { name: 'check' }),
          (isNew.value ? 'Salvar' : 'Atualizar'),
          ],
        ),
      ]

      const action = slots?.actions
        ? slots?.actions()
        : [
            h(
              'div',
              { class: 'form-action flex gap-5 no-wrap mt-24 justify-start' },
              actionsButton,
            ),
          ]

      const tree
        = [
          slots.intro && slots.intro(),
          slots.default && defaultChildVNode,
          props.autoFields && fieldsetElementsVNode,
          h(QInnerLoading,
            {
              color: 'white',
              showing: props.isFetching, // TODO  propaby change is fetching to a internal value
              label: props.isFetching ? 'Carregando...' : 'Salvando...',
              labelClass: 'text-white',
              labelStyle: 'font-size: 1.1em',
              dark: true,
            }),

          // h('pre', {}, 'fieldsValues'),
          // h('pre', {}, JSON.stringify(fieldsValues.value)),
          // h('pre', {}, 'props'),
          // h('pre', {}, JSON.stringify(props)),
          // h('pre', {}, 'listOfUsedField'),
          // h('pre', {}, JSON.stringify(listOfUsedField)),
          // h('pre', {}, 'autoFields'),
          // h('pre', {}, JSON.stringify(props.autoFields)),
        ]

      if (props.modal) {
        return h(ModalComp,
          {
            class: 'modal-form',
            ref: myModal,
            onClosed: () => emit('closed'),
          },
          {
            default: () => h(formVNode, null, tree),
            footer: ({ close, isOpen }) => h(
              'div',
              { class: 'form-action flex no-wrap modal-form-actions' },
              actionsButton,
            ),
          })
      }
      else {
        return h(formVNode, null, [tree, action])
      }
    }
  },

})
</script>

<style lang="scss">
    .autoform_group{
      width: 100%;
      flex-wrap: wrap;
      & > *{
        flex: 1 1 auto;
      }
    }

    .modal-form {
      .autoform {
        width: 60vw;
        padding: 3em;
        background: #f3f3f3;
      }.q-inner-loading {
        .q-spinner {
          position: fixed;
          top: 20%;
        }.q-inner-loading__label {
          position: fixed;
          top: calc(20% + 40px);
        }
      }.disable {
        pointer-events: none;
        opacity: 0.6;
        filter: grayscale(100%);
      }

    }

    .modal-form-actions {
      padding: 2px;
     button{
      border-radius: 0 !important;
     }
    }
</style>
