<script setup>
import { mergeProps, ref, useAttrs, watch } from 'vue'
import { computed } from '@vue/reactivity'
import { emitDef, propsDef, useFieldInput } from './useFieldInput'
import fieldTemplate from './field-template.vue'
const props = defineProps({
  ...propsDef,
  modelValue: {
    type: [Boolean, String],
    default: true,
  },
  size: {
    default: 'md',
  },
  showLabelInline: {
    default: false,
  },
  inLabel: {
    default: false,
  },
  trueValue: {
    default: true,
  },
  falseValue: {
    default: false,
  },

})

const emit = defineEmits([...emitDef])
const _value = ref(props.modelValue ?? props.trueValue)

watch(() => _value, (newValue, oldValue) => {

})

const onInput = (val, event) => {
  emit('update:modelValue', val)
  emit('update:value', val)
  _value.value = val
}

const slotName = computed(() => {
  return props.inLabel ? 'label-append' : 'default'
})
</script>

<template>
  <field-template
    :required="required"
    :error="error"
    :label="label"
    :hint="hint"
  >
    <template #[slotName]>
      <q-toggle
        :model-value="_value"
        :size="size"
        :true-value="trueValue"
        :false-value="falseValue"
        @update:model-value="onInput"
      />
    </template>
  </field-template>
</template>
