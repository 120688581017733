<script setup>
// import '@/assets/app.styl'
import { RouterLink, RouterView } from 'vue-router'
// define router
import { useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import router from '@/router'
import { modelsStore as modelPinia } from '@/models/models.pinia'
import { uiStore } from '@/stores'
const route = useRoute()
// const $q = useQuasar()

// $q.notify('Message')
// $q.dialog({
//   title: 'Alert',
//   message: 'Some message',
// })

const ui = uiStore()
const drawer = ui.drawer

onMounted(() => {
  const modelStore = modelPinia()
  window.$modelStore = modelStore
})

const goBack = () => {
  router.back()
}

const fakeapi = useLocalStorage('fakedata', false)
</script>

<template>
  <!-- <div>
    <RouterView class="view header" name="top" />

    <div class="main">
      <div id="barra" class="" />
      <RouterView class="view" />
    </div>

    <notifications class="notification" position="top center" width="80%" />
  </div> -->

  <q-layout
    view="hHh Lpr lff"
    class="shadow-2 rounded-borders"
  >
    <RouterView class="view header" name="top" />
    <RouterView class="view sidebar" name="sidebar" />

    <q-page-container>
      <q-btn
        v-if="route?.name !== 'home'"
        flat
        round
        color="gray"
        icon="navigate_before"
        style="position: -webkit-sticky;
               position: sticky;
               top: 70px;
               z-index:1000"
        @click="goBack"
      />
      <q-page padding>
        <RouterView class="view" />
      </q-page>
    </q-page-container>
    <notifications class="notification" position="top center" width="80%" />

    <div
      id="debug"
    >
      <button class="btn">
        <q-toggle
          v-model="fakeapi"
          color="secondary"

          label="Fake API"
        />
      </button>
    </div>
  </q-layout>

  <div id="modals">
  </div>
</template>

<style lang="scss">
    .main {
      min-height: calc(100vh - 65px);
    }#barra {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 100%;
      background: linear-gradient(0deg, rgba(98, 92, 170, 0.84) 10.84%, rgba(28, 166, 162, 0.84) 100%), #16A98D;
    }.notification {
      padding-top: 80px;
      font-size: 1em;& *  {
        font-size: 16px;
        pointer-events: initial;
      }

      pointer-events: none;
    }
</style>
