<script setup>
import { resolveUnref, watchTriggerable } from '@vueuse/core'
import Fuse from 'fuse.js'
import {
  computed,
  nextTick, ref,
  toRef, watch,
} from 'vue'
import fieldTemplate from './field-template.vue'
import generateOptionFormat from '@/utils/generateOptionFormat'

const props = defineProps({
  displayField: [String, Number],
  label: {
    type: String,
  },
  placeholder: {
    default: 'Buscar...',
  },
  name: {
    type: String,
  },
  /** Object propertie to use as label */
  labelKey: {
    default: 'label',
  },
  /** Object propertie to use as value */
  valueKey: {
    default: 'value',
  },
  /** What keys to find. Leave blank for null */
  searchKeys: {
    default: [],
  },
  searchOptions: {
    default: () => ({
      includeScore: true,
      includeMatches: true,
      findAllMatches: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      location: 0,
      distance: 100,
      useExtendedSearch: false,
      ignoreLocation: false,
      ignoreFieldNorm: false,
      keys: [],
    }),
  },

  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  modelValue: {
    type: [String, Number, Array],
  },
  value: String,
  showCloseButton: {
    default: true,
  },
  showEdit: {
    default: true,
  },
  cellStyle: {
    default: false,
  },
  showLoading: {
    default: false,
  },
  /** the items that will be show */
  options: {
    type: Array,
    default: () => [
      { label: 'label1', value: 'value1' },
      { name: 'label2', value: 'value2' },
      'label3',
    ],
  },
  /** Define the vmodel/change emit. auto will emit as the modelValue format */
  emitFullObject: {
    type: [String, Boolean],
    default: 'auto',
  },
  addNewFn: {
    type: Function,
  },
  searchable: {
    default: true,
  },
  /** Search function that will be called. If set, the default search will not filter, and you have to return the options filtered */
  searchFn: {
    type: Function,
  },
  /** Search function that will be called. If set, the default search will not filter, and you have to return the options filtered */
  scrollFn: {
    type: Function,
  },
  hideDropdownIcon: {
    default: false,
  },
  multiple: {
    default: false,
  },
  multipleType: {
    default: 'inline',
  },
})

const emit = defineEmits(['update:modelValue', 'update:value', 'change', 'changeRawValue'])

// V-Model Funcs
// ----------------------

const p_modelValue = toRef(props, 'modelValue')
const _modelValueRaw = ref(props.modelValue)

/** define raw value */
watchTriggerable(p_modelValue, (val, oldVal) => {
  if (JSON.stringify(val) == JSON.stringify(oldVal))
    return
  let value
  if (val === undefined || val === null) {
    _modelValueRaw.value = null
    return
  }
  const v = JSON.parse(JSON.stringify(resolveUnref(val)))
  if (Array.isArray(v))
    value = v.map(vv => vv?.[props.valueKey] ?? vv)

  else
    value = v?.[props.valueKey] ?? v // check object input or raw value

  _modelValueRaw.value = value
}).trigger()

/** if we received the full object or just the id */
const _emitFullObject = computed(() => {
  if (props.emitFullObject !== 'auto')
    return props.emitFullObject
  else
    return !!p_modelValue?.value?.[props.valueKey]
})

/** emit changes when value change */
watchTriggerable(_modelValueRaw, (value, oldVal) => {
  if (!_emitFullObject.value) {
    emit('update:modelValue', value)
    emit('update:value', value)
    emit('change', value)
  }
  if (value !== oldVal)
    emit('changeRawValue', value)
}).trigger()

const removeItem = (index) => {
  _modelValueRaw.value.splice(index, 1)
}

// Options Funcs
// ----------------------
const p_options = toRef(props, 'options')
const _optionsRef = ref(null)

// sempre que os items mudam
watchTriggerable(p_options, (val, oldVal) => {
  if (JSON.stringify(val) == JSON.stringify(oldVal))
    return
  const v = JSON.parse(JSON.stringify(resolveUnref(val)))
  const unrefValue = v
  const firstItem = unrefValue?.[0] ?? {}

  // se o objeto que recebo tem o k e value, utilizo ele, senao converto com o generate options
  if (firstItem?.[props.labelKey] && firstItem?.[props.valueKey])
    _optionsRef.value = v
  else // senao tento retornar utilizando utilitario de extrair value e options
    _optionsRef.value = generateOptionFormat(unrefValue)
}).trigger()

// Selected Item Funcs
// ----------------------
const currentSelectedItem = computed(() => {
  const options = _optionsRef.value
  const value = _modelValueRaw.value
  if (Array.isArray(value))
    return options.filter(opt => value.includes(opt?.[props.valueKey]))
  else
    return options.find(opt => opt?.[props.valueKey] === value)
})

watch(currentSelectedItem, (v) => {
  if (_emitFullObject.value) {
    const valueUnRef = resolveUnref(v)
    emit('update:modelValue', valueUnRef)
    emit('update:value', valueUnRef)
    emit('change', valueUnRef)
  }
})

// SEARCH Funcs
// ----------------------
let fuse
const searchWordRef = ref('')

const filterFn = (val, update, abort) => {
  if (props.searchFn) {
    props.searchFn(val, update, abort)
  }
  else {
    update(() => {
      searchWordRef.value = val.trim()
    })
  }
}

watchTriggerable(_optionsRef, (val) => {
  // fuse = new Fuse(_optionsRef.value, {
  //   keys: [props.labelKey],
  //   threshold: 0.2,
  //   includeScore: true,
  //   includeMatches: true,
  // })
  const keys = Object.keys(val?.[0] ?? props.labelKey)
  const list1 = JSON.parse(JSON.stringify(val))
  const opt = {
    threshold: 0.2,
    includeScore: true,
    includeMatches: true,
    keys,
  }
  const fuse1 = new Fuse(list1, opt)
  fuse = fuse1
}).trigger()

const _optionsFiltered = computed(() => {
  const word = searchWordRef.value.trim()
  if (!word)
    return _optionsRef.value
  const searchResult = fuse.search(word)
  if (!searchResult)
    return []
  return searchResult.filter(searchItem => searchItem?.score < 0.3).map(searchItem => searchItem.item)
},
)

// UI Interactions Funcs
// ----------------------
const qSelectInstanceRef = ref(null)

function focusSearchField(e) {
  const compElm = qSelectInstanceRef.value.$el
  let input = compElm.getElementsByTagName('input')
  input = input?.[0] ?? input // if collection
  input.value = ''
  if (typeof input?.select == 'function')
    input.select()
  setTimeout(() => {
    if (typeof input?.select == 'function')
      input.select()
  }, 20,
  )
}

function blurSearchField(e) {
  const compElm = qSelectInstanceRef.value.$el
  let input = compElm.getElementsByTagName('input')
  input = input?.[0] ?? input // if collection
  input.value = ''
  setTimeout(() => {
    if (typeof input?.select == 'function')
      input.blur()
  }, 20,
  )
}

function addNew(val) {
  qSelectInstanceRef.value.updateInputValue('', false)
  // qSelectInstanceRef.value.blur()
  // qSelectInstanceRef.value.hidePopup()
  setTimeout(() => qSelectInstanceRef.value.hidePopup(), 10)
  if (props.addNewFn)
    props.addNewFn(val)
}
</script>

<template>
  <field-template
    :required="required"
    :error="error"
    :hint="hint"
    :cell-style="props.cellStyle"
  >
    <template #label>
      <slot name="label">
        <span v-if="label" v-html="label" />
      </slot>
    </template>
    <template #hint>
      <slot name="hint" :model-value-raw="_modelValueRaw">
        <span v-if="hint" v-html="hint" />
      </slot>
    </template>

    <q-select
      ref="qSelectInstanceRef"
      v-model="_modelValueRaw"
      class="field-model-select"
      :class="{ searchable }"
      :loading="props.showLoading"
      standout="bg-gray-8 text-primary no-shadow field-model-select-ativo"
      :options="_optionsFiltered"
      :option-label="props.labelKey"
      :option-value="props.valueKey"
      :dense="true"
      :options-dense="true"
      bg-color="white"
      color="gray-9"
      :emit-value="true"
      map-options
      :use-input="props.searchable"
      hide-selected
      hide-bottom-space
      :placeholder="props.placeholder"
      :hide-dropdown-icon="props.hideDropdownIcon"
      :multiple="props.multiple"
      @filter="filterFn"
      @virtual-scroll="scrollFn"
      @popup-show="focusSearchField"
      @popup-hide="blurSearchField"
    >
      <!-- fill-input - NAO PODE USAR QUE DA ERRO -->

      <template
        v-if="_modelValueRaw && props.showCloseButton"
        #append
      >
        <q-icon
          size="xs"
          name="clear"
          class="cursor-pointer"
          @click.stop.prevent="_modelValueRaw = null"
        />
      </template>

      <!-- ADD NEW - SLOTS -->
      <!-- Add New Slot -->
      <template
        v-if="addNewFn"
        #no-option="{ inputValue }"
      >
        <q-item clickable class="bg-gray " @click.capture.stop="e => addNew(inputValue)">
          <q-item-section avatar class="white">
            <q-icon color="white" name="add" />
          </q-item-section>
          <q-item-section class="white color-white">
            <span class="color-white"> ADICIONAR -> '{{ inputValue }}'</span>
          </q-item-section>
        </q-item>
      </template>

      <!-- Add New With Value Slot -->
      <template
        v-if="addNewFn"
        #after-options
      >
        <q-item clickable class="bg-gray " @click.capture.stop="(e) => addNew(null)">
          <q-item-section side class="w-4 color-white">
            <q-icon name="add" />
          </q-item-section>
          <q-item-section class="color-white">
            ADICIONAR
          </q-item-section>
        </q-item>
      </template>

      <template #prepend>
        <slot name="edit" :model-value-raw="_modelValueRaw" />
      </template>

      <div class="custom-item-render">
        <!-- SELECTED-ITEM - SLOT -->
        <slot name="selected-item" :item="currentSelectedItem">
          <template v-if="Array.isArray(currentSelectedItem)">
            <div v-for="(item, i) in currentSelectedItem" class="truncate-chip-labels" style="max-width: 300px;">
              <q-chip
                :label="item?.[props.labelKey]"
                removable
                clickable=""
                @click.stop.prevent
                @remove="removeItem(i)"
              />
            </div>
          </template>
          {{ currentSelectedItem?.[props.labelKey] }}
        </slot>
      </div>

      <!-- OPTIONS - SLOT -->
      <template #option="scope">
        <q-item v-bind="{ ...scope.itemProps }">
          <slot
            name="option"
            :option="{ ...scope.opt }"
          >
            <q-item-section>
              <q-item-label>{{ scope.opt?.[props.labelKey] }}</q-item-label>
            </q-item-section>
          </slot>
        </q-item>
      </template>
      <!-- SEM OPÇOES -->
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            Sem Resultados
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </field-template>
</template>

<style lang="scss">
.field-model-select {
  .q-field__native {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  input.q-field__input {
    color: transparent;
  }

  .custom-item-render {
    display: flex;
    align-items: center;
    color: black;
    z-index: 10;
    opacity: 1;
    transition: all 200ms;

    &>* {
      opacity: 1;
      transition: all 200ms;
    }
  }
}

.field-model-select.searchable {
  .custom-item-render {}
}

.field-model-select-ativo {
  input.q-field__input {
    color: gray !important;
  }

  .custom-item-render {
    opacity: 0;

    &>* {
      opacity: 0;
      color: transparent !important;
    }
  }
}

//icones quando focus (ficava branco antes)
.q-field--standout.q-field--highlighted .q-field__native,
.q-field--standout.q-field--highlighted .q-field__prefix,
.q-field--standout.q-field--highlighted .q-field__suffix,
.q-field--standout.q-field--highlighted .q-field__prepend,
.q-field--standout.q-field--highlighted .q-field__append,
.q-field--standout.q-field--highlighted .q-field__input {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>
