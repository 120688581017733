import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  endpoint: 'unidadesmedida',
  displayName: {
    singular: 'Unidade de Medida',
    plural: 'Unidades de Medida',
  },
  idKey: 'co_seq_undmedida',
  fields: [
    {
      key: 'co_seq_undmedida',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
    },
    {
      key: 'ds_unidade_medida',
      label: 'Nome',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
    {
      key: 'sg_unidade_medida',
      label: 'Sigla',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
    },
  ],
} as Model
