<script setup>
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue'
import { useOptions } from './useOptions.js'
import fieldTemplate from './field-template.vue'

const props = defineProps({
  label: {
    type: String,
  },
  name: {
    type: String,
  },
  hint: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
  },
  modelValue: {
    type: String,
  },
  value: String,
  options: {
    type: Array,
    default: () => [
      { label: 'label1', value: 'value1' },
      { name: 'label2', value: 'value2' },
      'label3',
    ],
  },
})

const emit = defineEmits(['update:modelValue', 'update:value'])

const _options = useOptions(props.options)

const internalModelValue = ref()
const refModelValue = toRef(props, 'modelValue')

watch(internalModelValue, (v) => {
  if (typeof v === 'string')
    internalModelValue.value = v.trim()
})
onMounted(() => {
  internalModelValue.value = props.modelValue
})
watch(internalModelValue, (v) => {
  emit('update:modelValue', v)
  emit('update:value', v)
})
watch(refModelValue, (v) => {
  internalModelValue.value = v
})
</script>

<template>
  <field-template :required="required" :error="error" :hint="hint">
    <template #label>
      <slot name="label">
        <span v-if="label" v-html="label" />
      </slot>
    </template>
    <template #hint>
      <slot name="hint">
        <span v-if="hint" v-html="hint" />
      </slot>
    </template>

    <select v-model="internalModelValue" :required="required" :name="name">
      <option
        v-for="option in _options" :key="option?.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </field-template>
</template>
