<script setup>
import { computed, isProxy, isRef, onMounted, reactive, ref, shallowRef, toRef, watch } from 'vue'

import { notify } from '@kyvg/vue3-notification'
// define model
import { modelsStore } from '@model/models.pinia.ts'
// import { useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import AutoModelForm from '@/models/autoModelForm.vue'
import MODELS from '@/models/models.ts'
import { useModel } from '@/models/useModelComposable.ts'
// define router
// import router from '@/router'

const props = defineProps({
  modelName: String,
  /** the values used to load and save list */
  data: Object,
  /** the name of any file as filiname.list.vue */
  template: String,
  itemsPerPage: {
    type: Number,
    default: 10,
  },
  autoFields: {
    type: Boolean,
    default: true,
  },
})
// define emits
const emit = defineEmits(['error', 'loaded'])
// const route = useRoute()
const $q = useQuasar()

const showModelPopupForm = ref(false)
const currentEditId = ref(null)

const {
  isFetching,
  hasError,
  message,
  items,
  loadItem,
  limit,
  totalItems,
  page,
  model,
  deleteItem,
} = useModel(
  toRef(props, 'modelName'),
  { limit: props.itemsPerPage })

const fields = computed(() => {
  const fields = model.value.getFieldsForView('table')
  return fields.filter(field => field.key !== model.value.getIdKey())
})

const afterSaved = () => {
  showModelPopupForm.value = false
  // sempre que salvo recarrego, pois alguns modelos não retornam o elemento inteiro
  // TODO - leva isso para dentro do save model, e ele ter consicencia dos modelos que retornao ou não
  loadItem(null, { data: props.data })
}

const editItem = (id) => {
  currentEditId.value = id
  showModelPopupForm.value = true
}

// loadItem(modelNameRef, null, props.data)
// setTimeout(() => loadItem(modelNameRef, null, props.data), 300)

// const { deleteItem } = useDeleteItem()

// const remove = async (id) => {
//   await deleteItem(props.modelName, id)
// }

loadItem(null, { data: props.data })
</script>

<template>
  <div>
    <alert v-if="hasError" error close-button>
      {{ message }}
    </alert>
    <slot v-if="items" :items="items" :fields="fields">
      <q-list separator class="bg-white">
        <template v-for="item in items" :key="item.id">
          <q-item clickable>
            <q-item-section>
              <template v-for="(field, i) in fields" :key="field.key">
                <q-item-label :caption="i > 0">
                  <router-link :to="`/${modelName}/${model.getId(item)}`">
                    {{ model.getFieldValueForItem(item, field) }}
                  </router-link>
                </q-item-label>
              </template>
            </q-item-section>
            <q-item-section v-if="model.canEdit()" avatar>
              <q-icon name="more_vert">
                <q-menu>
                  <q-list style="min-width: 130px">
                    <q-item
                      v-close-popup
                      clickable
                      @click="editItem(model.getId(item))"
                    >
                      <q-item-section>
                        <q-item-label class="flex flex-row gap-3">
                          <q-icon name="edit"></q-icon> Editar
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      v-close-popup
                      clickable
                      @click="deleteItem(item)"
                    >
                      <q-item-section>
                        <q-item-label class="flex flex-row gap-3">
                          <q-icon name="delete"></q-icon> Remover
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-icon>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </slot>

    <div class="flex gap-5 justify-between">
      <button class="btn bg-gray btn-small mt-2 add-endereco" type="button" @click="e => showModelPopupForm = !showModelPopupForm">
        <b>+ Adicionar</b>
      </button>

      <q-pagination
        v-model="page"
        class="text-slate-50"
        size="sm"
        color="accent"
        :max="totalItems / props.itemsPerPage"
        :max-pages="6"
        :boundary-numbers="false"
      />
    </div>

    <AutoModelForm
      v-if="showModelPopupForm"
      :open="true"
      :model-name="props.modelName"
      :modal="true"
      :model-id="currentEditId"
      :value="props.data"
      @saved="afterSaved"
      @closed="showModelPopupForm = false"
    />
  </div>
</template>

<!--
<alert v-if="error_msg" error close-button>
  {{ error_msg }}
</alert>

<template #default="{ items, fields }">
  <q-list bordered separator>
    <template v-for="item in items" :key="item.id">
      <q-item>
        <q-item-section>
          <template v-for="field in fields" :key="field.key">
            <q-item-label>
              <router-link :to="`/${modelName}/${item?.[_modelKey]}`">
                {{ field.props.customValue(item) }}
              </router-link>
            </q-item-label>
          </template>
        </q-item-section>
      </q-item>

    </template>
  </q-list>
  <button class="btn btn-small mt-2 add-endereco" type="button" @click="e => showForm = true">
    <b>+ Adicionar Perfil</b>
  </button> -->

<style lang="scss" scoped>

</style>
