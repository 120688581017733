<script setup lang="ts">
import type { Ref } from 'vue'
import { onMounted, reactive, ref, toRef, toRefs, watch } from 'vue'
import { debounce } from 'lodash'
import { refDebounced, resolveUnref, watchDebounced, watchTriggerable } from '@vueuse/core'
import exame_valorModel from './exame_valor.model'
import { Filter, modelsStore as modelPinia } from '@/models/models.pinia'
import { useDeleteItem, useModel, useSaveModelItem } from '@/models/useModelComposable'
const props = defineProps(
  {
    /** the model id */
    modelId: [Number, String],
    /** the model object */
    modelValue: Object,
    /** exame info */
    exame: Object,
    // eslint-disable-next-line vue/prop-name-casing
    co_tabela: {
      type: [Number, String],
      required: true,
    },
  },
)

const valorExame = ref(0)
// const valorExameDobo

if (props?.modelValue?.vl_exame)
  valorExame.value = props.modelValue.vl_exame

const {
  isFetching,
  hasError,
  message,
  deleteItem,
  saveItem,
} = useModel('exame_valor')

const _id: Ref<string | number | null> = ref(null)

watchEffect(() => {
  if (props.modelId)
    _id.value = props.modelId
})

async function removeExameValor() {
  if (_id.value) {
    const res = await deleteItem(_id.value, { co_tabela: +props.co_tabela })
    // if (!res.hasError)
    //   _id.value = null
  }
}

async function saveExameValor() {
  const itemToSave = {
    co_tabela: +props.co_tabela,
    co_exame: +props?.exame?.id,
    st_ativo: true,
    vl_exame: +valorExame.value,
  }
  await saveItem(itemToSave, _id.value) // , { co_tabela: +props.co_tabela })
}

onMounted(() => {
  watchDebounced(valorExame, (newVal) => {
    saveExameValor()
  }, { debounce: 500, maxWait: 5000 },
  )
})

function focusValor(e) {
  // select all text
  e.target.select()
}
</script>

<template>
  <tr class="exame-valor-row" :class="{ template: !_id }">
    <td class="exame">
      <!-- {{ props.modelId }} -->
      <b class="text-xs "> {{ props.exame.sg_exame }}</b>
      <span class="text-xs opacity-30">
        / AMB {{ props.exame.co_amb }}
      </span>
      <div class="text-ellipsis text-no-wrap overflow-hidden">
        {{ props.exame.no_exame }}
      </div>
    </td>
    <td class="valor">
      <field-text
        v-model="valorExame"
        name="vl_exame"
        :cell-style="true"
        :mask="{
          mask: Number,
          scale: 2,
          max: 90000,
          thousandsSeparator: '.',
        }"
        @focus="focusValor"
      >
      </field-text>
    </td>
    <td class="acao">
      <q-spinner-tail v-if="isFetching" color="blue-grey" />
      <div v-else-if="hasError" ref="errorDiv">
        <div v-tooltip="message">
          <icon icon="more" scale="1.2" style="color:red" />
        </div>
      </div>

      <q-icon
        v-else-if="_id"
        name="delete"
        size="1.1em"
        class="pointer opacity-50"
        @click="removeExameValor"
      >
      </q-icon>
    </td>
  </tr>
</template>

<style lang="scss">
    .exame-valor-row {
      tbody tr {
        background: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      td {
        background: white;
      }
      td.exame {
        padding: 0.7em;
        max-width: 1px;

        line-height: 1;
        vertical-align: middle;
      }
      td.valor {

        vertical-align: middle;

        input {
          text-align: right !important;
          font-size: 1.1em;
        }
      }
      td.acao {

        text-align: center;
        vertical-align: middle;
      }
    }.exame-valor-row.template {
      tbody tr, td {
        background: #ededed;
      }
      td.exame {
        color: rgba(0, 0, 0, 0.2);
      }
      td.valor {
        .form-input, input {
          color: rgba(0, 0, 0, 0.4) !important;
          background: none !important;
        }
      }
    }
</style>
