<script setup>
import { onMounted, reactive, ref, toRef, toRefs, watch } from 'vue'
import AutoForm from '@comp/forms/AutoForm.vue'

import ModelForm from '../modelForm.vue'
import usuarioPapeisList from './usuario-papeis-list.vue'

const props = defineProps(['modelValue', 'modelId', 'value'])
const emit = defineEmits(['saved', 'submit', 'close', 'cancel'])
const initialData = toRef(props, 'modelValue')

const data = reactive({
  error: null,
  withResponsavel: false,
})

if (props.modelValue?.no_responsavel)
  data.withResponsavel = true

watch(() => props.modelValue, (v) => {
  if (v.modelValue?.no_responsavel)
    data.withResponsavel = true
})

const onSaved = (res) => {
  // Notificacao esta configurada no model
  // TODO - important se é novo, eu não retorno, pois posso colocar o perfil  exibo a senha
  // se é, eu retorno
  emit('saved')
}
</script>

<template>
  <ModelForm
    v-slot="{ values, fields, fieldsProps, fieldsComp, isNew }"
    v-model="initialData"
    :model-id="modelId"
    :auto-fields="false"
    model-name="usuario"
    :value="value"
    @saved="onSaved"
  >
    <div class="container mx-auto">
      <div class="flex gap-4">
        <div class="grow">
          <fieldset>
            <legend>Dados Pessoais</legend>

            <component :is="fieldsComp.no_nome" />
            <component :is="fieldsComp.dt_nascimento" />
            <component :is="fieldsComp.sg_sexo" />
          </fieldset>
          <fieldset>
            <legend>Registro</legend>
            <component :is="fieldsComp.co_cpf" />
            <div class="flex-cols">
              <component :is="fieldsComp.co_rg" class="grow-5" />
              <component :is="fieldsComp.no_orgaoexpedidor" />
              <component :is="fieldsComp.sg_ufexpedidor" />
            </div>
          </fieldset>
          <fieldset>
            <legend>Contato</legend>
            <component :is="fieldsComp.ds_email" />
            <div class="flex-cols">
              <component :is="fieldsComp.nu_telefone1" />
              <component :is="fieldsComp.nu_telefone2" />
            </div>
          </fieldset>
          <fieldset>
            <legend><label><input v-model="data.withResponsavel" type="checkbox"> <b>Possui Responsável?</b></label></legend>

            <div v-if="data.withResponsavel">
              <fieldset>
                <component :is="fieldsComp.no_responsavel" />
                <component :is="fieldsComp.ds_emailresp" />
                <component :is="fieldsComp.co_cpfresponsavel" />
              </fieldset>
            </div>
          </fieldset>
          <fieldset class="no-border">
            <legend>Endereços</legend>
            <component :is="fieldsComp.enderecos" />
          </fieldset>
        </div>

        <div v-if="!isNew" class="">
          <fieldset
            class="mb-4 normal"
          >
            <legend>Perfil</legend>
            <AutoList model-name="papel" :data="{ co_usuario: Number(modelId) }" />
          </fieldset>
        </div>
      </div>
    </div>
  </ModelForm>
</template>

<style lang="scss">
</style>
