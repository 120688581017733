import UF from '../constants/uf'
import type { Model } from '@/models/modelsDef'

export default {
  fake: false,
  displayName: {
    singular: 'Exame',
    plural: 'Exames',
  },
  idKey: 'id',
  icon: 'bloodtype',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
    },
    {
      key: 'st_ativo',
      label: 'Ativo',
      type: 'toggle',
      default: true,
      required: true,
      inLabel: true,
    },
    {
      key: 'no_exame',
      label: 'Nome do Exame',
      type: 'text',
      required: true,
      search: true,
      displayLabel: true,
      sortable: true,
      sortOrder: 'asc',
    },
    {
      key: 'sg_exame',
      label: 'Sigla',
      type: 'text',
      hint: 'Pequeno conjunto de caracteres que identificam de forma univoca um exame',
      required: false,
      mask: '********',
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_amb',
      label: 'AMB',
      type: 'text',
      hint: 'Código do exame cadastrado na tabela da Associação Médica Brasileira',
      required: false,
      search: true,
      displayLabel: true,
    },
    {
      key: 'co_tuss',
      label: 'TUSS/ANS',
      type: 'text',
      hint: 'Código do exame cadastrado na tabela TUSS/ANS',
    },
    {
      key: 'ds_orientacoes',
      label: 'Orientações',
      type: 'text',
    },
    {
      key: 'dt_prazohoras',
      label: 'Prazo em Horas',
      type: 'number',
      hint: 'Prazo em horas para entrega de um exame',
      min: 0,
      max: 24,
    },
    {
      key: 'dt_prazodias',
      label: 'Prazo em Dias',
      type: 'number',
      hint: 'Prazo em dias para entrega de um exame',
      min: 0,
      max: 90,
    },
  ],
  table: {
    cols: ['id',
      {
        key: 'no_exame',
        classes: 'display',
      },
      'sg_exame', 'co_amb'],
  },
} satisfies ModelT
