const PessoaJuruidica: ModelT = {
  fake: false,
  displayName: {
    singular: 'Pessoa Juridica',
    plural: 'Pessoas Juridicas',
  },
  idKey: 'id',
  icon: 'business',
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'number',
      ignore: true,
      displayLabel: false,
    },
    // {
    //   key: 'st_status',
    //   label: 'Ativo',
    //   type: 'toggle',
    //   required: true,
    //   default: true,
    // },

    {
      key: 'co_cnpj',
      label: 'CNPJ',
      type: 'text',
      mask: '00.000.000/0000-00',
      required: true,
      displayLabel: true,
      search: true,
      fieldset: 'Identificação',
      group: 1,
      size: 25,
    },
    {
      key: 'no_unidade',
      label: 'Nome Fantasia',
      type: 'text',
      required: true,
      displayLabel: true,
      search: true,
      fieldset: 'Identificação',
      group: 2,
    },
    {
      key: 'ds_razao_social',
      label: 'Razão Social',
      type: 'text',
      hint: 'É o nome legal da empresa, registrado em seus documentos oficiais',
      required: true,
      fieldset: 'Identificação',
      displayLabel: true,
      search: true,
    },
    {
      key: 'sg_unidade',
      label: 'Sigla da Unidade',
      type: 'text',
      required: true,
      hint: 'Sigla de identificação para utilização interna',
      fieldset: 'Identificação',
      size: 10,
      displayLabel: true,
      search: true,
    },
    {
      key: 'tp_estabelecimento',
      label: 'Tipo',
      type: 'select',
      required: true,
      options: { FORNECEDOR: 'Fornecedor', CLIENTE: 'Cliente', CONVENIO: 'Convênio' },
      default: 'CLIENTE',
      fieldset: 'Categoria e Registros',
      displayLabel: true,
      size: 15,
    },
    {
      key: 'tp_natureza_juridica',
      label: 'Tipo de Natureza Jurídica',
      type: 'select',
      required: true,
      // eslint-disable-next-line quote-props
      options: { 'pública': 'Pública', 'privada': 'Privada', 'mista': 'Mista' },
      fieldset: 'Categoria e Registros',
      size: 18,
    },
    {
      key: 'co_ie',
      label: 'Inscrição Estadual',
      type: 'text',
      mask: '000000000',
      required: false,
      fieldset: 'Categoria e Registros',
      group: 1,
      size: 15,
    },
    {
      key: 'co_cnes',
      label: 'Nº CNES',
      type: 'text',
      required: true,
      hint: 'Código de inscrição do convênio na Agência Nacional de Saúde',
      fieldset: 'Categoria e Registros',
      mask: '00000000',
      size: 11,

    },
    {
      key: 'co_registro_ans',
      label: 'ANS',
      type: 'text',
      required: true,
      hint: 'Código do Cliente/Fornecedor no Cadastro Nacional de Estabelecimentos de Saúde',
      fieldset: 'Categoria e Registros',
      mask: '000.000.000',
      size: 11,
    },

    {
      key: 'no_contato',
      label: 'Nome de contato',
      type: 'text',
      required: true,
      fieldset: 'Contato',
    },
    {
      key: 'ds_email',
      label: 'Email',
      type: 'email',
      required: true,
      fieldset: 'Contato',
      size: 50,
    },
    {
      key: 'nu_telefone',
      label: 'Telefone',
      type: 'text',
      mask: '(00) 00000-000000',
      required: true,
      fieldset: 'Contato',
      size: 20,
    },
    {
      key: 'endereco',
      label: 'Endereço',
      type: 'endereco',
      required: true,
      multiple: false,
      fieldset: 'Contato',
    },
    {
      key: 'img_logo',
      label: 'Logomarca',
      type: 'file',
      required: false,
      hint: 'JPG ou PNG',
      fieldset: 'Papel Timbrado',
      props: {
        emitThumbnailOrFile: 'thumbnail',
      },
    },
    {
      key: 'ds_cabecalho',
      label: 'Informações do cabeçalho',
      type: 'textarea',
      required: false,
      hint: 'Texto com dados que serão incluídos em cabeçalhos de relatórios e/ou laudos',
      hintType: 'text',
      fieldset: 'Papel Timbrado',
    },
    {
      key: 'ds_rodape',
      label: 'Informações do rodape',
      type: 'textarea',
      required: false,
      hint: 'Texto com dados que serão incluídos em cabeçalhos de relatórios e/ou laudos',
      hintType: 'text',
      fieldset: 'Papel Timbrado',
    },
    {
      key: 'ds_observacao',
      label: 'Observação',
      type: 'textarea',
      required: false,
      default: '  ',
      props: {
        contentType: 'text',
      },
      fieldset: 'Observação',
    },
  ],

  table: {
    cols: [{ key: 'st_status', label: 'Status', renderFn: item => item.st_status ? 'Ativo' : 'Inativo' }, 'tp_estabelecimento', 'no_unidade', 'co_cnpj', 'no_contato', 'ds_email', 'nu_telefone'],
  },
  list: {
    cols: ['no_unidade', 'co_cnpj', 'ds_razao_social', 'sg_unidade'],
  },

}

export default PessoaJuruidica

